import "reflect-metadata";
import { TDMSBase } from "../base";

/**
 * A class purely to relate speaking data to a specific role for a session
 */
export class Role extends TDMSBase {
  /**
   * A unique identifier for this role data
   */
  id: number;

  /**
   * A unique name of this role
   */
  name: string;

  /**
   * A FK to the session that this role was part of
   */
  sessionId: number;

  /**
   * The FK to the user that is in this role.
   */
  userId: number;

  /**
   * The order at which this role should be in its role array
   */
  sortOrder: number;

  /**
   * If this role is currently being displayed or not
   */
  displayed: boolean = true;

  /**
   * Bool to tell us if this Role was created manually by a user or from uploaded data.
   */
  createdManually: boolean = false;

  constructor(id: number | undefined, name: string, sessionId: number, userId: number, sortOrder: number = 0) {
    super();
    this.id = id!;
    this.name = name;
    this.sessionId = sessionId;
    this.userId = userId;
    this.sortOrder = sortOrder;
  }
}
