import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { BookmarkService } from "@tdms/frontend/modules/bookmark/services/bookmark.service";
import { AngularMaterialModule } from "@tdms/frontend/modules/material/material.module";
import { SharedModule } from "@tdms/frontend/modules/shared/shared.module";
import { BookmarkTypeInfoComponent } from "./components/bookmark-type-info/bookmark-type-info.component";
import { BookmarkDialogComponent } from "./components/dialog/bookmark-dialog.component";
import { BookmarkTypeService } from "./services/bookmark.type.service";
import { bookmarkTypeReducer } from "./store/bookmark.type.reducer";

const COMPONENTS = [BookmarkDialogComponent, BookmarkTypeInfoComponent];

@NgModule({
  declarations: COMPONENTS,
  exports: COMPONENTS,
  imports: [
    CommonModule,
    SharedModule,
    AngularMaterialModule,
    StoreModule.forFeature("bookmarkType", bookmarkTypeReducer),
  ],
  providers: [BookmarkService, BookmarkTypeService],
})
export class BookmarkModule {}
