<div class="construct-table-container" *ngIf="groups">
  <!-- Outer table that hosts all the groups -->
  <table class="group-table">
    <!-- Each row is a group -->
    <ng-container *ngFor="let group of groups">
      <ng-container *ngVar="isFirstVisibleGroup(group) as first">
        <ng-container *ngVar="isLastVisibleGroup(group) as last">
          <tr
            class="group"
            [ngClass]="group.groupName.toLowerCase().trim().replaceAll(' ', '-')"
            *ngIf="shouldRenderGroup(group)"
          >
            <!-- Group name -->
            <td class="group-name">{{ group.groupName }}</td>
            <!-- Nested table -->
            <td class="data-table-cell">
              <table class="data-table">
                <!-- Render the display headers-->
                <tr
                  class="headers-row"
                  *ngIf="headerOverride"
                  [ngStyle]="{ visibility: first ? 'inherit' : 'collapse' }"
                >
                  <!-- Blank cell to realign -->
                  <td class="cell" [ngClass]="{ 'collapsed-cell': !first }"></td>
                  <!-- Standard headers -->
                  <td
                    class="header cell"
                    [ngClass]="{ 'collapsed-cell': !first, 'no-end': index === headerOverride.length - 1 }"
                    *ngFor="let header of headerOverride; index as index"
                  >
                    <span class="inner">{{ header }}</span>
                  </td>
                </tr>

                <!-- Each row is a metric -->
                <tr
                  class="metric-row row"
                  *ngFor="let metric of getMetricsFromGroup(group); last as metricLast; first as metricFirst"
                >
                  <!-- Metric name -->
                  <td class="metric-name cell" [ngClass]="{ 'no-bottom': metricLast, 'no-top': metricFirst }">
                    {{ metric.displayName }}
                  </td>
                  <!-- Cell content. Chooses headers vs time separation dynamically -->
                  <ng-container *ngVar="$any(headerOverride || group.timeSeparation) as array">
                    <ng-container *ngFor="let separation of array; index as index">
                      <ng-container *ngVar="metric.breakdown[index]?.value as val">
                        <!-- We allow the cells to be customize via a template if the user would like to. Else we display a simple set -->
                        <ng-container
                          select="[replace=cellDisplay]"
                          *ngTemplateOutlet="
                            cellDisplayRef;
                            context: {
                              metric: metric,
                              config: val == null ? undefined : getGaugeConfigByValue(val),
                              group: group,
                              val: val,
                              addClasses: {
                                'no-bottom': metricLast,
                                'no-top': metricFirst,
                                'no-end': index === array.length - 1
                              },
                              header: headerOverride == null ? undefined : headerOverride[index]
                            }
                          "
                        ></ng-container>
                        <!-- Render normal cell if no template was provided -->
                        <td *ngIf="!cellDisplayRef" [ngClass]="{ 'no-bottom': metricLast, 'no-top': metricFirst }"></td>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </tr>

                <!-- Render the time separation, if chosen -->
                <tr class="time-row row" *ngIf="last && displayTimeRow">
                  <!-- Blank cell for the "header" -->
                  <td class="cell"><span class="inner">Time (Min)</span></td>
                  <td class="interval cell" *ngFor="let separation of group.timeSeparation">
                    <span class="inner">{{ separation }}</span>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </ng-container>
      </ng-container>
    </ng-container>
  </table>
</div>
