import { Component, Input } from "@angular/core";

/** A component intended to simplify the rendering of pros/cons capabilities across session summary displays */
@Component({
  selector: "session-summary-pros-cons[pros][cons]",
  templateUrl: "./pros-cons.component.html",
  styleUrls: ["./pros-cons.component.scss"],
})
export class ProsConsComponent {
  @Input() pros?: string[] = [];
  @Input() cons?: string[] = [];
}
