import { Directive, Input } from "@angular/core";
import { TimeSeriesChartBase } from "@tdms/common";
import { BookmarkChartBaseComponent } from "@tdms/frontend/modules/charts/shared/bookmark-base/bookmark.base.component";
import { ExtendedChartOptions } from "@tdms/frontend/modules/charts/shared/plugins/plugin.typing";
import { ZoomDomainContent, ZoomDomainUpdateEmitter } from "@tdms/frontend/modules/charts/timeline/timeline.component";
import { ChartOptions } from "chart.js";
import { merge } from "lodash-es";

/**
 * This base component provides the ability for timeline selection zones to be enabled in chart types that extend this. We inject some custom
 *  domain handling and adjust the minimum and maximum displays to always retain the max of the data values.
 */
@Directive()
export abstract class TimelineSelectionBaseComponent<
  ChartDataType extends TimeSeriesChartBase<any>,
  InternalChartType extends "line"
> extends BookmarkChartBaseComponent<ChartDataType, InternalChartType> {
  /**
   * The current **min** selection zone for the selection plugin
   */
  minSelection: Date | undefined = undefined;

  /**
   * The current **max** selection zone for the selection plugin
   */
  maxSelection: Date | undefined = undefined;

  /**
   * The emitter for us to call when the zoom domain is updated. Allows external components to control
   *  what the timeline should display as "selected"
   */
  @Input() domainUpdate: ZoomDomainUpdateEmitter | undefined;

  /** The **min** available data time */
  availableMin: Date | undefined = undefined;
  /** The **max** available data time */
  availableMax: Date | undefined = undefined;

  /** Given the chart data set, determines the min/max values and sets them accordingly */
  setMinMax(dataSet: ChartDataType[]) {
    if (this.configuration.isSelectedTimeline) {
      /// Grab the min and max dates for the time
      /// Just some basic checks to make sure we have data.
      if (dataSet.length > 0) {
        const initial = dataSet[0];
        if (initial.series.length > 0) {
          this.availableMin = initial.series[0].name;
          this.availableMax = initial.series[initial.series.length - 1].name;
        }
      }
    }
    return dataSet;
  }

  /** Updates our domain based on given zoom domain content */
  setDomainData(x: ZoomDomainContent) {
    if (x.source === "external") {
      if (x.domain == null) {
        this.minSelection = undefined;
        this.maxSelection = undefined;
      } else {
        this.maxSelection = x.domain[0];
        this.minSelection = x.domain[1];
      }
      // Update the chart to re render the plugin options
      this.currentChart?.update();
    }
  }

  override ngOnInit(): void {
    super.ngOnInit();
    // Subscribe to the changes if the domain is updated externally
    if (this.domainUpdate) {
      // Grab current domain so we are up to date
      this.setDomainData({ ...this.domainUpdate.getValue(), source: "external" });
      // Listen for domain updates
      this.addSubscription(this.domainUpdate.subscribe(this.setDomainData.bind(this)));
    }
  }

  override chartOptionOverrides(coreOptions: ChartOptions<InternalChartType>): ExtendedChartOptions<InternalChartType> {
    // Grab super options
    const superOptions = super.chartOptionOverrides(coreOptions);
    // Grab new options related to selection zone
    if (this.configuration.isSelectedTimeline) {
      const newOptions: ExtendedChartOptions<"line"> = {
        scales: {
          x: {
            // Override the domain range usage as we are instead going to use it as the max/min for the selection area
            min: this.availableMin as any,
            max: this.availableMax as any,
          },
        },
        plugins: {
          bookmark: {
            // Setting the domain to null ensures that the bookmark plugin won't filter out any bookmarks based on the timeline selection.
            domain: null,
          },
          select: {
            // Utilize centralized functionality but override some defaults
            minCurrentSelect: this.minSelection,
            maxCurrentSelect: this.maxSelection,
            enabled: true,
            strokeStartStopOnly: false,
            dragCompleteCallback: (leftVal, rightVal) => {
              this.minSelection = leftVal;
              this.maxSelection = rightVal;
              if (leftVal && rightVal)
                this.domainUpdate?.next({ domain: [this.minSelection!, this.maxSelection!], source: "timeline" });
              else this.domainUpdate?.next({ domain: undefined, source: "timeline" });
            },
          },
        },
      };
      return merge(superOptions, newOptions);
    } else return superOptions;
  }
}
