<div class="pros-cons-container">
  <!-- Pros -->
  <div class="pros">
    <div class="sub-header">Pros</div>
    <div class="item-list">
      <ul>
        <li *ngFor="let item of pros" class="item">{{ item }}</li>
      </ul>
    </div>
  </div>
  <!-- Cons -->
  <div class="cons">
    <div class="sub-header">Cons</div>
    <div class="item-list">
      <ul>
        <li *ngFor="let item of cons" class="item">{{ item }}</li>
      </ul>
    </div>
  </div>
</div>
