<!-- Dynamically render remaining charts -->
<mat-card class="chart-card card" *ngIf="dataStore != null">
  <mat-card-content [ngSwitch]="chartType">
    <!-- Line chart handling -->
    <ng-container *ngSwitchCase="'line'">
      <charts-timeline
        *ngIf="isTimeline && (isSessionComparison || !hasAudioData)"
        #chart
        [configuration]="configuration!"
        [settings]="settings"
        [colorLookup]="colorMap!"
        [data]="data"
        [isCalculating]="chartDataIsCalculating!"
        [graphTitleStart]="graphTitleStart"
        [graphTitleEnd]="graphTitleEnd"
        [domainUpdate]="zoomDomainUpdater"
        [applicationTheme]="applicationTheme"
        [bookmarkDrawingStatus]="bookmarkDrawingStatus"
        [bookmarks]="bookmarks"
        [bookmarkTypes]="bookmarkTypes"
        [bookmarkAddDisabled]="(bookmarkDrawingStatus | async) || false"
        [bookmarkAdd]="this.onAddBookmarkClicked"
        [bookmarkUpdate]="this.onUpdateBookmarkClicked"
        [bookmarksDisplayed]="bookmarksEnabled"
        [bookmarkTooltip]="bookmarksTooltip"
        [isSessionComparison]="isSessionComparison"
        [isLoading]="isLoading"
        [customLegendValues]="legend"
        [customLegendClickCallback]="onLegendClicked"
      ></charts-timeline>

      <!-- Timeline -->
      <timeline
        *ngIf="isTimeline && !isSessionComparison && hasAudioData"
        #chart
        [zoomDomainUpdater]="zoomDomainUpdater"
        [applicationTheme]="applicationTheme"
        [customLegendClickCallback]="onLegendClicked"
        [legend]="legend"
      ></timeline>
      <!-- Line chart -->
      <charts-line
        *ngIf="!isTimeline"
        #chart
        [configuration]="configuration!"
        [settings]="settings"
        [customLegendValues]="this.legend"
        [customLegendClickCallback]="onLegendClicked"
        [colorLookup]="colorMap!"
        [data]="data"
        [isCalculating]="chartDataIsCalculating!"
        [exportPrefix]="exportPrefixText!"
        [xDomainRange]="lineChartDomain"
        [applicationTheme]="applicationTheme"
        [bookmarkDrawingStatus]="bookmarkDrawingStatus"
        [bookmarks]="bookmarks"
        [bookmarkTypes]="bookmarkTypes"
        [bookmarkAddDisabled]="(bookmarkDrawingStatus | async) || false"
        [bookmarkAdd]="this.onAddBookmarkClicked"
        [bookmarkUpdate]="this.onUpdateBookmarkClicked"
        [bookmarksDisplayed]="bookmarksEnabled"
        [bookmarkTooltip]="bookmarksTooltip"
        [isSessionComparison]="isSessionComparison"
        [totalChartTimeFrame]="totalChartTimeFrame"
        [isLoading]="isLoading"
      ></charts-line>
    </ng-container>
    <ng-container *ngSwitchCase="'waveform'">
      <!-- Waveform chart -->
      <charts-waveform
        #chart
        [configuration]="configuration!"
        [settings]="settings"
        [customLegendValues]="this.legend"
        [customLegendClickCallback]="onLegendClicked"
        [colorLookup]="colorMap!"
        [data]="data"
        [domainUpdate]="zoomDomainUpdater"
        [isCalculating]="chartDataIsCalculating!"
        [exportPrefix]="exportPrefixText!"
        [xDomainRange]="lineChartDomain"
        [applicationTheme]="applicationTheme"
        [bookmarkDrawingStatus]="bookmarkDrawingStatus"
        [bookmarks]="bookmarks"
        [bookmarkTypes]="bookmarkTypes"
        [bookmarkAddDisabled]="(bookmarkDrawingStatus | async) || false"
        [bookmarkAdd]="this.onAddBookmarkClicked"
        [bookmarkUpdate]="this.onUpdateBookmarkClicked"
        [bookmarksDisplayed]="bookmarksEnabled"
        [bookmarkTooltip]="bookmarksTooltip"
        [isSessionComparison]="isSessionComparison"
        [totalChartTimeFrame]="totalChartTimeFrame"
        [isLoading]="isLoading"
        [graphTitleStart]="graphTitleStart"
        [graphTitleEnd]="graphTitleEnd"
      ></charts-waveform>
    </ng-container>
    <!-- Vertical Bar chart -->
    <charts-bar
      *ngSwitchCase="'vertical-bar'"
      #chart
      [configuration]="configuration!"
      [settings]="settings"
      [customLegendValues]="this.legend"
      [customLegendClickCallback]="onLegendClicked"
      [colorLookup]="colorMap!"
      [data]="data"
      [isCalculating]="chartDataIsCalculating!"
      [exportPrefix]="exportPrefixText!"
      [applicationTheme]="applicationTheme"
      [isSessionComparison]="isSessionComparison"
      [isLoading]="isLoading"
    ></charts-bar>
    <!-- Horizontal time series bar chart -->
    <charts-time-series-bar
      *ngSwitchCase="'time-series-horizontal-bar'"
      #chart
      [configuration]="configuration!"
      [settings]="settings"
      [customLegendValues]="this.legend"
      [customLegendClickCallback]="onLegendClicked"
      [colorLookup]="colorMap!"
      [data]="data"
      [isCalculating]="chartDataIsCalculating!"
      [exportPrefix]="exportPrefixText!"
      [xDomainRange]="lineChartDomain"
      [applicationTheme]="applicationTheme"
      [bookmarkDrawingStatus]="bookmarkDrawingStatus"
      [bookmarks]="bookmarks"
      [bookmarkTypes]="bookmarkTypes"
      [bookmarkAddDisabled]="(bookmarksEnabled && (bookmarkDrawingStatus | async)) || false"
      [bookmarkAdd]="onAddBookmarkClicked"
      [bookmarkUpdate]="onUpdateBookmarkClicked"
      [bookmarksDisplayed]="bookmarksEnabled"
      [bookmarkTooltip]="bookmarksTooltip"
      [isSessionComparison]="isSessionComparison"
      [totalChartTimeFrame]="totalChartTimeFrame"
      [isLoading]="isLoading"
    ></charts-time-series-bar>
    <!-- Pie Chart -->
    <charts-pie
      [data]="data"
      [colorLookup]="colorMap!"
      [configuration]="configuration!"
      [settings]="settings"
      *ngSwitchCase="'pie'"
      [customLegendValues]="this.legend"
      [customLegendClickCallback]="onLegendClicked"
      [colorLookup]="colorMap!"
      [data]="data"
      [isCalculating]="chartDataIsCalculating!"
      [exportPrefix]="exportPrefixText!"
      [applicationTheme]="applicationTheme"
      [isSessionComparison]="isSessionComparison"
      [isLoading]="isLoading"
    ></charts-pie>
    <!-- Gauge Chart -->
    <charts-gauge
      [data]="data"
      [colorLookup]="colorMap!"
      [configuration]="configuration!"
      [settings]="settings"
      *ngSwitchCase="'gauge'"
      [customLegendValues]="this.legend"
      [customLegendClickCallback]="onLegendClicked"
      [colorLookup]="colorMap!"
      [data]="data"
      [isCalculating]="chartDataIsCalculating!"
      [exportPrefix]="exportPrefixText!"
      [applicationTheme]="applicationTheme"
      [isSessionComparison]="isSessionComparison"
      [isLoading]="isLoading"
    ></charts-gauge>
    <!-- Unknown chart -->
    <div *ngSwitchDefault class="unknown-chart">Unknown Chart Type</div>
  </mat-card-content>
</mat-card>
