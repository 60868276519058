import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import {
  arrayToMap,
  CommonSetting,
  filterNulls,
  GetSettingDescriptionsReply,
  GetSettingDescriptionsRequest,
  GetUserSettingsReply,
  GetUserSettingsRequest,
  SettingCollectionDescription,
  TDMSWebSocketMessage,
  User,
  UserChangeSettingReply,
  UserChangeSettingRequest,
  UserTopics,
  WebSocketCommunication,
} from "@tdms/common";
import { WebSocketService } from "@tdms/frontend/modules/communication/services/websocket.service";
import { SettingCollectionActions } from "@tdms/frontend/modules/settings/store/collection/collection.action";
import { SettingCollection } from "@tdms/frontend/modules/settings/store/collection/collection.model";
import { SettingValuesActions } from "@tdms/frontend/modules/settings/store/value/setting.value.action";
import { SettingValue } from "@tdms/frontend/modules/settings/store/value/setting.value.model";
import { Service } from "@tdms/frontend/modules/shared/services/base.service";
import { UserService } from "@tdms/frontend/modules/user/services/user.service";
import { firstValueFrom, map, Observable } from "rxjs";
import { SettingState } from "../store/setting.state";
import { selectPluginSetting, selectPluginSettings, selectSettingCollections } from "../store/settings.selector";

/**
 * This service is used to request user configurable settings for plugins defined by the backend.
 * This is primarily used for configuration of the chart metrics, but can be used for any plugin that may want to grant user configuration options.
 */
@Injectable({
  providedIn: "root",
})
export class PluginSettingsService extends Service {
  constructor(
    private wsService: WebSocketService,
    private store: Store<SettingState>,
    private userService: UserService
  ) {
    super();
  }

  /**
   * When the active user changes, reload our settings from the backend.
   * @param user The new user.
   */
  override async onUserChanged(user?: User) {
    if (user) await this.loadUserSettings();
    else this.wipeUserSettings();
  }

  override async onUserLoggedOut() {
    this.wipeUserSettings();
  }

  override async onBackendDisconnected() {
    this.wipeUserSettings();
  }

  /** Returns if the setting exists for the given plugin and setting */
  hasSetting(plugin: string, setting: string) {
    return firstValueFrom(this.store.select(selectPluginSetting(plugin, setting)).pipe(map((x) => x != null)));
  }

  observeSetting(plugin: string, setting: string): Observable<SettingValue> {
    return this.store.select(selectPluginSetting(plugin, setting)).pipe(filterNulls);
  }

  observeSettingValue<T>(plugin: string, setting: string): Observable<T> {
    return this.observeSetting(plugin, setting).pipe(map((setting) => setting.value));
  }

  observeSettingCollections(): Observable<readonly SettingCollection[]> {
    return this.store.select(selectSettingCollections);
  }

  observeCollectionSettings(plugin: string): Observable<readonly SettingValue[]> {
    return this.store.select(selectPluginSettings(plugin)).pipe(filterNulls);
  }

  /** Wipes the current users settings from the store */
  private wipeUserSettings() {
    this.store.dispatch(SettingCollectionActions.clear({}));
    this.store.dispatch(SettingValuesActions.clear({}));
  }

  /**
   * Load user settings from the backend and populate the store appropriately.
   */
  private async loadUserSettings() {
    // Load the settings and descriptions from the backend endpoints.
    const response = await this.wsService.sendAndReceive<GetUserSettingsReply>(
      new TDMSWebSocketMessage(UserTopics.getSettings, undefined, new GetUserSettingsRequest())
    );
    const descriptions = await this.loadSettingDescriptions();

    let collections = response.payload.collections;
    if (collections == null) throw new Error("FATAL: Backend gave no settings for user!");

    // Convert the descriptions to a map to make lookups speedier as we create our linked data structures.
    const settingDescriptions = arrayToMap(descriptions, "name");
    // Convert the backend setting data structures to our frontend setup.
    // This mainly entails filtering out collections with no settings,
    // and linking the descriptions to the settings and collections.
    const frontendCollections = collections
      .filter((collection) => collection.settings.length > 0)
      .map((collection) => {
        const description = settingDescriptions.get(collection.plugin);

        if (description == null)
          throw new Error(`Could not load helpful descriptions for settings collection ${collection.plugin}!`);

        return {
          ...collection,
          name: collection.plugin,
          helpfulTitle: description?.helpfulTitle,
          helpfulDescription: description?.helpfulDescription,
          // We don't want to keep the actual setting objects here,
          // so that changes to the setting values don't cause collection events to fire.
          // But we do need to know every setting that exists on the collection,
          // So we keep a simple list of all the setting names that can be iterated through.
          // Setting names should be expected to never change.
          settingNames: collection.settings.map((setting) => setting.name),
        } as SettingCollection;
      });

    // Grab all the actual settings from every collection, and flatten them
    // into a singular array structure of SettingValues which will be thrown into
    // the store. We link the SettingValues to their plugin using the collectionId field.
    // By keeping the SettingValue objects separated from SettingCollections,
    // we prevent overfiring of store events by teaching it that these data points
    // change independent of each other.
    const flatSettingValues = collections.flatMap((collection) => {
      const descriptions = settingDescriptions.get(collection.plugin);
      if (descriptions == null)
        throw new Error(`FATAL: Couldn't load helpful descriptions for settings collection ${collection.plugin}!`);
      const settingDescriptionMap = arrayToMap(descriptions?.settingDescriptions, "name");
      return collection.settings.map((setting) => {
        const description = settingDescriptionMap.get(setting.name);
        if (description == null)
          throw new Error(
            `FATAL: Couldn't find helpful descriptions for setting ${setting.name} in collection ${collection.plugin}!`
          );
        return {
          ...setting,
          settingType: setting.settingType,
          collectionId: collection.plugin,
          ...description,
        } as SettingValue;
      });
    });

    // Update the store collection objects (which don't contain any setting data,
    // other than a list of setting internal names)
    this.store.dispatch(SettingCollectionActions.addCollections({ collections: frontendCollections }));
    // Add our list of flattened setting value objects to the store.
    // This contains every individual setting object, linked to it's plugin via collectionId.
    this.store.dispatch(SettingValuesActions.addSettings({ settings: flatSettingValues }));
  }

  /**
   * Requests all setting descriptions from the backend
   */
  private async loadSettingDescriptions(): Promise<SettingCollectionDescription[]> {
    if (this.userService.currentAuthenticatedUser == null)
      throw new Error("FATAL: Could not lookup setting descriptions!");

    const collectionResponse = await this.wsService.sendAndReceive<GetSettingDescriptionsReply>(
      new TDMSWebSocketMessage(UserTopics.getSettingDescriptions, undefined, new GetSettingDescriptionsRequest())
    );
    if (!collectionResponse.payload.success || !collectionResponse.payload.descriptions) {
      throw new Error(`FATAL: Could not load setting descriptions!`);
    }

    return collectionResponse.payload.descriptions;
  }

  /**
   * Initiate a single setting update on the backend.
   * The backend will emit a response to all clients logged in with the same user id.
   * @param setting The changed setting
   * @param plugin The plugin the setting belongs to.
   */
  async updateSpecificSetting(setting: SettingValue, plugin: string) {
    await this.wsService.send(
      new TDMSWebSocketMessage(
        UserTopics.changeSetting,
        undefined,
        new UserChangeSettingRequest(plugin, { ...setting, type: setting.settingType } as CommonSetting)
      )
    );
  }

  /**
   * Listen for the backend's setting update response.
   * @param data The setting that updated.
   */
  @WebSocketCommunication.listen<void, TDMSWebSocketMessage<UserChangeSettingReply>>(UserTopics.changeSetting)
  async backendSettingUpdated(data: TDMSWebSocketMessage<UserChangeSettingReply>) {
    if (!data.payload.success) {
      return;
    }
    const currentUser = this.userService.currentAuthenticatedUser;

    if (currentUser == null) {
      return;
    }

    if (currentUser.id != data.payload.user?.id) {
      return;
    }

    const setting = data.payload.setting;
    // Initiate a singular setting value update to our store.
    // This will key off a pair of (collectionId, setting.name) to determine what setting should be updated.
    this.store.dispatch(SettingValuesActions.updateSetting({ ...setting, collectionId: data.payload.plugin }));
  }
}
