import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { GaugeRange, MetricWeight, METRIC_GROUP_NAME, SessionSummaryAdvancedGroupSeparation } from "@tdms/common";
import { ConfigService } from "@tdms/frontend/modules/settings/services/config.service";
import { cloneDeep } from "lodash-es";

/** Properties to pass in for usage for display of the help content */
export interface SessionSummaryAdvancedHelpProperties {
  breakdown: SessionSummaryAdvancedGroupSeparation[];
}

/**
 * A component to display help messages for explanation how the complex math works behind the session summary
 */
@Component({
  selector: "session-summary-help",
  templateUrl: "./help-dialog.component.html",
  styleUrls: ["./help-dialog.component.scss"],
})
export class SessionSummaryHelpDialogComponent implements OnInit {
  /** The metric group associated to this help content */
  baselineMetricGroup: SessionSummaryAdvancedGroupSeparation | undefined;

  /** All other metrics except the baseline metric set */
  metrics: SessionSummaryAdvancedGroupSeparation[] | undefined;

  /** Gauge values to display */
  gaugeRange: GaugeRange[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public properties: SessionSummaryAdvancedHelpProperties,
    private configService: ConfigService
  ) {
    // Modify it's tooltips
    this.gaugeRange = this.getModifiedGaugeRanges();
  }

  /** Returns modified gauge ranges for display */
  getModifiedGaugeRanges() {
    const gaugeRange = cloneDeep(this.configService.configData!.sessionSummary.gaugeRange);
    gaugeRange.map((x) => {
      x.tooltip = `${x.name} weight on construct`;
    });
    return gaugeRange;
  }

  ngOnInit(): void {
    this.baselineMetricGroup = this.properties.breakdown.find((x) => x.groupName === METRIC_GROUP_NAME)!;
    this.metrics = this.properties.breakdown.filter((x) => x.groupName !== METRIC_GROUP_NAME);
  }

  /** Returns if the given group contains a metric with help text */
  groupHasHelpText(group: SessionSummaryAdvancedGroupSeparation) {
    return group.metrics?.find((x) => x.helpContent && x.helpContent.text);
  }

  /** Given a group and a metric name, returns it's help weight content */
  getWeightConfigByAndName(
    group: SessionSummaryAdvancedGroupSeparation,
    metricName: string,
    baselineMetricName: string
  ) {
    const matchingMetric = group.combinationMetrics.find((x) => x.displayName === metricName);
    const matchingWeight = matchingMetric?.weightConfig?.find((z) => z.displayName === baselineMetricName);
    return matchingWeight;
  }

  get baselineMetricsAsHeaders() {
    return this.baselineMetricGroup?.metrics?.map((x) => x.displayName);
  }

  /** Given a weight returns the color to use */
  getColorConfigFromWeight(weight: MetricWeight) {
    const gauges = this.configService.configData!.sessionSummary.gaugeRange;
    return gauges.find((x) => x.name.toLowerCase() === weight);
  }
}
