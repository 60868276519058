import { Component, Input } from "@angular/core";

/** A generic card used in the session summary display to wrap content */
@Component({
  selector: "session-summary-card[header]",
  templateUrl: "./card.component.html",
  styleUrls: ["./card.component.scss"],
})
export class SessionSummaryCardComponent {
  /** If the progress spinner should be shown as if we are loading the current card */
  @Input() shouldShowSpinner = false;
  /** If data is available for this rendered card. If set to false, will display a "no data" message. */
  @Input() dataAvailable = false;
  /** The header to display at the top of the card */
  @Input() header!: string;

  constructor() {}
}
