<shared-dialog-wrapper header="Edit Data Store File">
  <div class="dialog-container">
    <p>
      You are editing the metadata associated with {{ properties.fileData.filename }}. Make sure you understand what you
      are doing, as modifications can critically alter how the file is used by the TDMS system.
    </p>

    <form [formGroup]="formGroup" class="file-edit-container" (submit)="submit()">
      <mat-form-field appearance="outline" color="accent">
        <mat-label>Filename</mat-label>
        <input matInput placeholder="Filename" formControlName="filename" />
        <mat-icon matSuffix>description</mat-icon>
      </mat-form-field>

      <shared-improved-select
        #select
        class="session-select"
        [control]="$any(formGroup.controls['associatedSession'])"
        [dropdownOptions]="properties.availableSessionNames"
        dropdownName="Session Name"
        [filterEnabled]="true"
        [paginatorEnabled]="true"
        [clearable]="false"
        [shouldCleanName]="false"
        [allowMultipleSelections]="false"
      >
      </shared-improved-select>

      <shared-improved-select
        #select
        class="plugin-select"
        [control]="$any(formGroup.controls['associatedPlugin'])"
        [dropdownOptions]="properties.availablePlugins"
        dropdownName="Associated Plugin"
        [filterEnabled]="true"
        [paginatorEnabled]="true"
        [clearable]="false"
        [shouldCleanName]="false"
        [allowMultipleSelections]="false"
        (selectedValueChanged)="onPluginChanged()"
      >
      </shared-improved-select>

      <shared-improved-select
        #select
        class="plugin-type-select"
        [control]="$any(formGroup.controls['associatedPluginType'])"
        [dropdownOptions]="availablePluginTypes"
        dropdownName="Plugin Type"
        [filterEnabled]="true"
        [paginatorEnabled]="true"
        [clearable]="false"
        [shouldCleanName]="false"
        [allowMultipleSelections]="false"
      >
      </shared-improved-select>

      <div class="button-container">
        <button mat-raised-button color="accent" (click)="properties.onEditCancelled()" type="button">Cancel</button>
        <button
          mat-raised-button
          color="accent"
          [disabled]="!formGroup.valid"
          [matTooltip]="formGroup.valid ? '' : 'Please validate all file metadata!'"
          matTooltipPosition="below"
          (click)="submit()"
          type="submit"
        >
          Save
        </button>
      </div>
    </form>
  </div>
</shared-dialog-wrapper>
