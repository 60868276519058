import { Plugin as ChartJSPlugin } from "chart.js";
import { PluginBase, PluginBaseExternalOpts, PluginBaseInternalOpts } from "./plugin.base";

/** This plugin allows us to use selection zones on charts for specific callbacks */
export namespace SelectPlugin {
  /** External supported options */
  export class ExternalOptions extends PluginBaseExternalOpts {
    /** This function will be called when a drag is completed with the range values */
    dragCompleteCallback?: { (leftVal: Date | undefined, rightVal: Date | undefined): void };

    /** A callback that will be executed with temporary values as the drag select progresses */
    dragProgressCallback?: { (leftVal: Date, currentRightVal: Date | undefined): void };

    /** This function will be called when a drag is beginning */
    dragStartCallback?: { (leftVal: Date): void };

    /** The color to fill the selection indicator */
    fillColor?: string;

    /** The color to apply to the selection indicator */
    borderColor?: string;

    /** If we don't want to outline the rectangle and only apply lines to the start/stop of the rect */
    strokeStartStopOnly?: boolean;

    /** If the outline of the rect should be dashed or not */
    strokeDashed?: boolean;

    /** The minimum range of the current selection if you want to render a pre selected range. */
    minCurrentSelect?: Date;

    /** The maximum range of the current selection if you want to render a pre selected range. */
    maxCurrentSelect?: Date;
  }

  /** Options for internal use of this plugin only */
  export class InternalOptions extends PluginBaseInternalOpts {
    mouseIsDown?: boolean;
    isDragging?: boolean;
    start?: { x: number; y: number };
    end?: { x: number; y: number };
  }

  export class Plugin extends PluginBase<ExternalOptions, InternalOptions> implements ChartJSPlugin<any> {
    constructor(pluginCanvas: HTMLCanvasElement) {
      super(pluginCanvas, "select", ExternalOptions, InternalOptions);
    }

    /**
     * Returns the current start/stop values for the selection at that given time. These values
     *  will always be in order and will attempt to convert to dates if they can.
     */
    getStartStopValues() {
      const chart = this.chart!;
      const internalOpts = this.internalOpts;
      let startX: Date | number | undefined = internalOpts.start
        ? chart.scales.x.getValueForPixel(internalOpts.start.x) || 0
        : undefined;
      let endX: Date | number | undefined = internalOpts.end
        ? chart.scales.x.getValueForPixel(internalOpts.end.x) || 0
        : undefined;

      // Keep them always in order
      if (startX && endX)
        if (startX > endX) {
          const temp = startX;
          startX = endX;
          endX = temp;
        }
      // Convert to dates
      if (startX) startX = new Date(startX);
      if (endX) endX = new Date(endX);
      return { startX: startX as Date | undefined, endX: endX as Date | undefined };
    }

    /**
     * Clears the current selection zone
     */
    clearSelection() {
      const pluginOpts = this.externalOpts;
      const internalOpts = this.internalOpts;
      internalOpts.end = undefined;
      internalOpts.start = undefined;
      // Call callback to trigger reset
      if (pluginOpts.dragCompleteCallback) pluginOpts.dragCompleteCallback(undefined, undefined);
      // Re render to immediately remove the selection zone
      this.render();
    }

    /**
     * A centralized function to have handling when we complete drawing the rect to display.
     */
    drawingComplete() {
      const pluginOpts = this.externalOpts;
      const internalOpts = this.internalOpts;
      internalOpts.isDragging = false;
      internalOpts.end = { x: internalOpts.xPos!, y: this.chart!.height };
      this.handleEnds(internalOpts.end);
      if (internalOpts.start == null || internalOpts.end == null) return;
      // If end equals start, reset drag status
      if (internalOpts.end.x === internalOpts.start.x) this.clearSelection();
      // Call the defined callback with data
      else if (pluginOpts.dragCompleteCallback) {
        const currValues = this.getStartStopValues();
        pluginOpts.dragCompleteCallback(currValues.startX, currValues.endX);
      }
    }

    override mouseDown(e: MouseEvent) {
      super.mouseDown(e);
      const chart = this.chart!;
      const pluginOpts = this.externalOpts;
      const internalOpts = this.internalOpts;
      // Ignore clicks if we are "drawing" a bookmark from another plugin
      if (chart.config.options.plugins.bookmark?.currentDrawingBookmark != null) return;
      // Only grab left clicks
      if (pluginOpts.enabled && e.button === 0) {
        // Forcibly disable tooltip to not block the select if enabled
        if (chart.config.options.plugins.tooltip) {
          chart.config.options.plugins.tooltip.enabled = false;
          if (chart.tooltip) chart.tooltip.opacity = 0;
          this.render();
        }
        internalOpts.mouseIsDown = true;
        internalOpts.start = { x: this.internalOpts.xPos!, y: 0 };
        if (pluginOpts.dragStartCallback) pluginOpts.dragStartCallback(this.getStartStopValues().startX!);
      }
    }

    /**
     * Handles the given internal option position and makes sure it ends inside the chart and can't draw past the edges
     */
    handleEnds(position: { x: number; y: number }) {
      if (position) {
        position.x = this.drawingHelper.enforceChartArea("left", position.x);
        position.x = this.drawingHelper.enforceChartArea("right", position.x);
      }
    }

    override mouseMove(e: MouseEvent) {
      super.mouseMove(e);
      const pluginOpts = this.externalOpts;
      const internalOpts = this.internalOpts;
      if (!internalOpts.mouseIsDown || !pluginOpts.enabled) return;
      internalOpts.isDragging = true;
      internalOpts.end = { x: this.internalOpts.xPos!, y: 0 };
      this.handleEnds(internalOpts.end);
      if (internalOpts.end) internalOpts.end.y = this.chart!.height;
      if (pluginOpts.dragProgressCallback) {
        const currValues = this.getStartStopValues();
        pluginOpts.dragProgressCallback(currValues.startX!, currValues.endX);
      }
    }

    override mouseUp(e: MouseEvent) {
      super.mouseUp(e);
      if (this.shouldExecuteFunctionality()) {
        const internalOpts = this.internalOpts;
        internalOpts.mouseIsDown = false;
        // If we were not dragging, clear the selection
        if (!internalOpts.isDragging) this.clearSelection();
        else {
          internalOpts.isDragging = false;
          this.drawingComplete();
        }
      }
    }

    /**
     * This function handles drawing our rectangle on the chart to display our selection indicator
     */
    drawRect() {
      const pluginOpts = this.externalOpts;
      const internalOpts = this.internalOpts;
      const { start, end } = internalOpts;
      if (pluginOpts?.enabled && start != null && end != null)
        return this.drawingHelper.drawRect(start.x, end.x, {
          strokeEnabled: true,
          color: pluginOpts.fillColor,
          strokeColor: pluginOpts.borderColor,
          strokeStartStopOnly: pluginOpts.strokeStartStopOnly,
          strokeDash: pluginOpts.strokeDashed,
        });
    }

    override mouseLeave(e: MouseEvent) {
      super.mouseLeave(e);
      const internalOpts = this.internalOpts;
      // Complete drag
      if (internalOpts.isDragging) this.mouseUp(e);
      internalOpts.isDragging = false;
      internalOpts.mouseIsDown = false;
    }

    /**
     * Given the chart for options, updates the internal start/end to match the options given min/max
     */
    setStartEndViaOptions() {
      const chart = this.chart!;
      const pluginOpts = this.externalOpts;
      const internalOpts = this.internalOpts;
      if (chart.scales.x) {
        internalOpts.start =
          pluginOpts.minCurrentSelect == null
            ? undefined
            : { x: chart.scales.x.getPixelForValue(pluginOpts.minCurrentSelect.getTime()), y: chart.height };
        internalOpts.end =
          pluginOpts.maxCurrentSelect == null
            ? undefined
            : { x: chart.scales.x.getPixelForValue(pluginOpts.maxCurrentSelect.getTime()), y: chart.height };
        this.drawRect();
      }
    }

    override chartReady(): void {
      super.chartReady();
      if (this.shouldExecuteFunctionality()) {
        this.setStartEndViaOptions();
        this.chart!.canvas.style.cursor = "crosshair";
      }
    }

    override onPluginUpdate(): void {
      if (this.shouldExecuteFunctionality()) this.setStartEndViaOptions();
    }

    override render(): void {
      if (this.shouldExecuteFunctionality()) {
        this.drawRect();
        // Fix cursor issues related to bookmarks
        if (this.chart!.bookmark?.currentHoveredBookmark == null) this.chart!.canvas.style.cursor = "crosshair";
        else this.chart!.canvas.style.cursor = "pointer";
      }
    }
  }
}
