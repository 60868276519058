export interface TextDimensions {
  width: number;
  height: number;
}

export function measureTextDimensions(context: CanvasRenderingContext2D, label?: string): TextDimensions {
  const dimensions = context.measureText(label ?? "W");
  return {
    width: dimensions.width,
    height: dimensions.actualBoundingBoxAscent + dimensions.actualBoundingBoxDescent,
  };
}
