import { Type } from "class-transformer";
import { TDMSBase } from "../../base";

/** The available weights for metrics */
export type MetricWeight = "high" | "medium" | "low";
/** Group name for the overarching base layer metrics that are combined into constructs */
export const METRIC_GROUP_NAME = "Metrics";

/** A class that provides the cells to the advanced view for each breakdown of content */
export class SessionSumAdvancedMetricBreakdown extends TDMSBase {
  /**
   * The value of this metric at this point in time for the advanced view.
   *
   * This value uses the scale as determined by the plugin in the backend.
   */
  value: number;

  /** Time into session that this metric was calculated for */
  @Type(() => Date)
  time: Date;

  constructor(value: number, time: Date) {
    super();
    this.value = value;
    this.time = time;
  }
}

/** The base capability for session summary metrics shared between groupings and actual metrics. */
class SessionSummaryMetricBase extends TDMSBase {
  /** How to display this current "metric" */
  displayName!: string;
  /** The advanced view breakdown of content to display in a table like format. This is the averages over time for this metric. */
  @Type(() => SessionSumAdvancedMetricBreakdown)
  breakdown!: SessionSumAdvancedMetricBreakdown[];
}

/** This "metric" is used to take multiple metrics and combine them into one overarching score. */
export class SessionSummaryCombinationMetric extends SessionSummaryMetricBase {
  /** Configures the weight config to display for the help menu */
  weightConfig!: {
    /** Backend plugin name */
    metric: string;
    /** The display mapping of the backend metric name */
    displayName: string;
    /** Decimal percentage */
    weightPercentage: number;
    weight: MetricWeight;
  }[];
}

/** A class that provides an individual session summary metric per grouping to display in the advanced view */
export class SessionSummaryMetric extends SessionSummaryMetricBase {
  metricName!: string;
  /** These scores track the average score for each individual so we can determine how to display this content. */
  individualScores!: { [roleName: string]: number };
  /** A constant value that if the score of an individual is lower than that of the `lowAverageValue`, this will be given to them as a recommendation. */
  individualFeedback!: string[];
  /** Team feedback is the overarching feedback that should be mentioned to the entire team and is displayed in the event this metrics overall score < low value score. */
  teamFeedback!: string[];
  /** This provides information so we know how to specify pros/cons of this specific metric. It also tells what array of content to render based on the displayMode. Also used to display pros/cons per individual. */
  overallResultInfo!: {
    /** Pros to list for the overall session in the event that the score is > the feedback low value. */
    pros: string[];
    /** Cons to list for the overall session in the event that the score is < the feedback low value. */
    cons: string[];
    // The overall average score
    score: number;
  };
  /** The help information for how this content was turned from it's metric into actual range display */
  helpContent!: {
    /** The text to display of how this was calculated. Each index in the array will be a separate line. */
    text: string[];
    /** Adds help output that this metric will provide feedback via team or individual feedback based on these values */
    feedbackCutoff: {
      /** Should be a value between {@link SESSION_SUMMARY_MIN} and {@link SESSION_SUMMARY_MAX}. Any average value less than this, is considered a "low" score and will be prompted on the feedback display.  */
      lowAverageValue: number;
      /** 0 - 100 range percentage that `lowAverageValue` is based on */
      lowAveragePercentage: number;
    };
  };

  /** Returns the pros of this metric taking into account the overall score */
  get prosOverallScoreBased() {
    if (this.overallResultInfo.score < this.helpContent.feedbackCutoff.lowAverageValue) return [];
    else return this.overallResultInfo.pros;
  }

  /** Returns the cons of this metric taking into account the overall score */
  get consOverallScoreBased() {
    if (this.overallResultInfo.score < this.helpContent.feedbackCutoff.lowAverageValue)
      return this.overallResultInfo.cons;
    else return [];
  }

  /** Returns the team feedback if the score t */
  get teamFeedbackScoreBased() {
    if (this.overallResultInfo.score < this.helpContent.feedbackCutoff.lowAverageValue) return this.teamFeedback;
    else return [];
  }

  private getScoreForRole(roleName: string) {
    return this.individualScores == null ? undefined : this.individualScores[roleName];
  }

  /** Given a role name, returns the feedback for that role based on scores */
  getFeedbackForRole(roleName: string) {
    const score = this.getScoreForRole(roleName);
    if (score == null) return [];
    if (score < this.helpContent.feedbackCutoff.lowAverageValue) return this.individualFeedback;
    else return [];
  }

  /** Returns the pros for the given role considering it's core */
  getProsForRole(roleName: string) {
    const score = this.getScoreForRole(roleName);
    if (score == null) return [];
    if (score < this.helpContent.feedbackCutoff.lowAverageValue) return [];
    else return this.overallResultInfo.pros;
  }

  /** Returns the cons for the given role considering it's core */
  getConsForRole(roleName: string) {
    const score = this.getScoreForRole(roleName);
    if (score == null) return [];
    if (score < this.helpContent.feedbackCutoff.lowAverageValue) return this.overallResultInfo.cons;
    else return [];
  }
}

/** Advanced data separation by group */
export class SessionSummaryAdvancedGroupSeparation extends TDMSBase {
  groupName: string;

  @Type(() => SessionSummaryMetric)
  metrics?: SessionSummaryMetric[];

  /**
   * These metrics combine actual metrics produced by TDMS into groupings of scores. These tend to provide
   *  less overarching info or specifics like a standard metric would.
   */
  @Type(() => SessionSummaryCombinationMetric)
  combinationMetrics: SessionSummaryCombinationMetric[];

  /** The **second** based time separation that should be displayed at the bottom of the table  */
  timeSeparation: number[];

  constructor(
    groupName: string,
    metrics: SessionSummaryMetric[],
    combinationMetrics: SessionSummaryCombinationMetric[],
    timeSeparation: number[]
  ) {
    super();
    this.groupName = groupName;
    this.metrics = metrics;
    this.combinationMetrics = combinationMetrics;
    this.timeSeparation = timeSeparation;
  }
}

/** Information that relates directly to the session score and related breakdown */
export class SessionScore extends TDMSBase {
  /** The overall score to apply to this session */
  score: number;

  constructor(score: number) {
    super();
    this.score = score;
  }
}

/** A class used to define the session summary data for a session */
export class SessionSummary extends TDMSBase {
  sessionId: number;
  /** Returns if this overview is curved */
  isCurved: boolean = false;

  /** Session score breakdown */
  @Type(() => SessionScore)
  sessionScore: SessionScore;

  /** The advanced view breakdown for the researchers */
  @Type(() => SessionSummaryAdvancedGroupSeparation)
  advancedBreakdown: SessionSummaryAdvancedGroupSeparation[];

  constructor(
    sessionId: number,
    sessionScore: SessionScore,
    advancedBreakdown: SessionSummaryAdvancedGroupSeparation[]
  ) {
    super();
    this.sessionId = sessionId;
    this.sessionScore = sessionScore;
    this.advancedBreakdown = advancedBreakdown;
  }
}
