<div class="communication-container">
  <!-- Display the waveform -->
  <audio-track #audioTrack class="audio-track"></audio-track>

  <!-- Display our table with the transcription capabilities -->
  <data-store-uploader-progress
    [shouldDisplayStatus]="transcriptions.length === 0 && !audioService.failureToGenerate"
    [status]="refreshStatus"
    class="communication-container"
  >
    <shared-editable-table
      class="transcription-table"
      tableName="Communication"
      [data]="transcriptions"
      [defaultSortHeader]="'start'"
      [displayedColumns]="displayedColumns"
      [clickCallback]="seekTo"
      defaultHeaderSortDirection="asc"
      [activeRows]="activeRows"
      [editConfigEnable]="'audio.allowEditing'"
      (onEditStarted)="batchEdit()"
      (onChangesCancelled)="cancelChanges()"
      (onChangesSaved)="saveChanges()"
      saveTooltip="Save transcription changes"
      cancelTooltip="Cancel transcription changes"
      [hasChanged]="hasChanged"
      [editsValid]="inputsValid"
    >
      <cell-editor [columns]="['prettySpeaker']">
        <ng-template #editor let-value="value" let-element="element" let-column="column">
          <shared-improved-select
            #select
            class="speaker-select"
            [selectedValue]="value"
            (selectedValueChanged)="speakerUpdate(element, $event)"
            [dropdownOptions]="uniqueSpeakers"
            appearance="standard"
            [filterEnabled]="false"
            [paginatorEnabled]="false"
            [clearable]="false"
            [shouldCleanName]="false"
            [allowMultipleSelections]="false"
            prefixOptionIcon="add"
            prefixOptionTooltip="Opens a dialog to add a new speaker identity"
            prefixOptionTooltipPosition="right"
            prefixOptionText="Speaker Identity"
            (prefixOptionCallback)="openNewSpeakerIdentity(element, select)"
          >
          </shared-improved-select>
        </ng-template>
      </cell-editor>

      <!-- CLC Checkmark -->
      <cell-display [columns]="['isCLC']">
        <ng-template #display let-value="value" let-element="element" let-column="column">
          <ng-container *ngIf="value">
            <mat-icon matTooltip="This utterance was identified as a Closed Loop Communication">check</mat-icon>
          </ng-container>
        </ng-template>
      </cell-display>

      <cell-editor [columns]="['text']">
        <ng-template #editor let-value="value" let-element="element" let-column="column">
          <cell-text-editor
            [column]="column"
            [value]="value"
            (onValueSubmitted)="saveChanges()"
            (onValueChanged)="transcriptionUpdate(element, column.name, $any($event))"
            fieldClass="transcription-content-form-field"
          >
          </cell-text-editor>
        </ng-template>
      </cell-editor>

      <cell-editor [columns]="['start', 'end']">
        <ng-template #editor let-value="value" let-element="element" let-column="column">
          <cell-text-editor
            [column]="column"
            [value]="value"
            (onValueSubmitted)="saveChanges()"
            [isValid]="inputValid(element, column.name)"
            (onValueChanged)="transcriptionTimeUpdate(element, column.name, $event)"
          >
          </cell-text-editor>
        </ng-template>
      </cell-editor>

      <!-- Custom end buttons -->
      <ng-template #extraHeaderButtons>
        <!-- Reset transcription -->
        <button
          mat-raised-button
          color="accent"
          [disabled]="isEditing"
          (click)="refreshTranscriptions()"
          matTooltip="Deletes the current transcription and requests a new one to be generated"
          matTooltipPosition="below"
          [configDisable]="'audio.allowRefresh'"
          configDisabledStateMessage="Refresh is disabled"
        >
          <mat-icon>restart_alt</mat-icon> Refresh Transcription
        </button>
      </ng-template>
    </shared-editable-table>
  </data-store-uploader-progress>
</div>
