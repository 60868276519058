import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatTooltipModule } from "@angular/material/tooltip";
import { StoreModule } from "@ngrx/store";
import { AngularMaterialModule } from "@tdms/frontend/modules/material/material.module";
import { MetricsModule } from "@tdms/frontend/modules/metrics/metrics.module";
import { SharedModule } from "@tdms/frontend/modules/shared/shared.module";
import { DataStoreModule } from "../data-store/data-store.module";
import { AudioTrackComponent } from "./components/audio-track/audio-track.component";
import { AudioDashboardComponent } from "./components/dashboard/audio.dashboard.component";
import { SpeakerIdentityComponent } from "./components/speaker-identity/speaker-identity.component";
import { AudioGuard } from "./guard/audio.guard";
import AudioMetricService from "./services/audio.metric.service";
import AudioService from "./services/audio.service";
import { transcriptionReducer } from "./store/transcription.reducer";

const COMPONENTS = [AudioDashboardComponent, AudioTrackComponent, SpeakerIdentityComponent];

/**
 * A Module that provides audio based displays and capabilities
 */
@NgModule({
  declarations: COMPONENTS,
  imports: [
    CommonModule,
    SharedModule,
    AngularMaterialModule,
    MatTooltipModule,
    StoreModule.forFeature("transcription", transcriptionReducer),
    FormsModule,
    MetricsModule,
    DataStoreModule,
  ],
  providers: [AudioService, AudioMetricService, AudioGuard],
  exports: COMPONENTS,
})
export class AudioModule {}
