import { createEntityAdapter, EntityAdapter } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import { BookmarkType } from "@tdms/common";
import { BookmarkTypeState } from "./bookmark.state";
import { BookmarkTypeActions } from "./bookmark.type.action";

/**
 * NGRX entity bookmarkTypeAdapter
 */
export const bookmarkTypeAdapter: EntityAdapter<BookmarkType> = createEntityAdapter<BookmarkType>({
  selectId: (type) => type.id,
});

/**
 * Reducer for speaking data NGRX Store
 */
export const bookmarkTypeReducer = createReducer(
  bookmarkTypeAdapter.getInitialState({} as BookmarkTypeState),
  on(BookmarkTypeActions.add, (state, data) =>
    // Add
    bookmarkTypeAdapter.addOne(data, state)
  ),
  on(BookmarkTypeActions.addMany, (state, data) =>
    // Add Many
    bookmarkTypeAdapter.addMany(data.bookmarkTypes, state)
  ),
  on(BookmarkTypeActions.delete, (state, data) =>
    // Delete
    bookmarkTypeAdapter.removeOne(data.id, state)
  ),
  on(BookmarkTypeActions.emptyBookmarkTypes, (state, _data) =>
    // Delete all bookmark types
    bookmarkTypeAdapter.removeAll(state)
  ),
  on(BookmarkTypeActions.update, (state, data) =>
    // Update
    bookmarkTypeAdapter.updateOne({ id: data.id, changes: data }, state)
  )
);
