import { Injectable } from "@angular/core";
import { PluginTopics, TDMSWebSocketMessage } from "@tdms/common";
import { WebSocketService } from "@tdms/frontend/modules/communication/services/websocket.service";
import { Service } from "@tdms/frontend/modules/shared/services/base.service";

/**
 * Centralized business logic for interacting with different
 */
@Injectable({ providedIn: "root" })
export default class PluginService extends Service {
  constructor(private wsService: WebSocketService) {
    super();
  }

  /**
   * Ask the backend for a list of the available plugin names that exist.
   */
  async lookupAvailablePlugins(): Promise<string[]> {
    const response = await this.wsService.sendAndReceive<string[]>(new TDMSWebSocketMessage(PluginTopics.getAll));
    return response.payload;
  }
}
