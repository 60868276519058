import { AfterViewInit, Component, Input, OnInit, ViewChild } from "@angular/core";
import { ControlContainer, ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from "@angular/forms";
import { NgxColorsTriggerDirective } from "ngx-colors";

/**
 * This element provides generic color picking capabilities utilizing ngx-colors as the base package. We implement
 *  our own input display but use the ngx-colors for the actual color selection popup.
 *
 * You can use this color picker like:
 * ```
 * <shared-color-picker formControlName="color"></shared-color-picker>
 * ```
 *
 * We highly recommend using Angular Forms for using this component.
 *
 * @link https://www.npmjs.com/package/ngx-colors
 */
@Component({
  selector: "shared-color-picker[formControlName]",
  templateUrl: "./color-picker.component.html",
  styleUrls: ["./color-picker.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ColorPickerComponent,
      multi: true,
    },
  ],
})
export class ColorPickerComponent implements OnInit, AfterViewInit, ControlValueAccessor {
  /** Reference to the NGX-COLORS directive for manual usage */
  @ViewChild(NgxColorsTriggerDirective) directive: NgxColorsTriggerDirective | undefined;
  /** Name of the form control utilized */
  @Input() formControlName!: string;
  /** Instance of our form control */
  @Input() formControl: FormControl | undefined;

  /** Default color options to display */
  colorOptions: string[] = [
    "#F08080", // LightCoral
    "#008000", // Green
    "#4B0082", // Indigo
    "#FFD700", // Gold
    "#00FFFF", // Cyan or Aqua
    "#A52A2A", // Brown
    "#FFA500", // Orange
    "#800080", // Purple
    "#D2B48C", // Tan
    "#0000FF", // Blue
  ];

  constructor(private controlContainer: ControlContainer) {}

  writeValue(_value: any): void {}

  registerOnChange(_fn: any): void {}

  registerOnTouched(_fn: any): void {}

  ngOnInit(): void {
    // Initialize the form control
    this.formControl = this.controlContainer.control?.get(this.formControlName) as FormControl;
  }

  ngAfterViewInit(): void {
    // Push the current color as an option to make sure it exists.
    if (!this.colorOptions.includes(this.color)) this.colorOptions.push(this.color);
    // Set current color into directive
    this.directive?.setColor(this.color);
  }

  /** Returns the color that is currently selected */
  get color() {
    return this.formControl?.value ?? this.colorOptions[0];
  }

  /** Called when the input is clicked of the color picker */
  click(event: MouseEvent) {
    if (!this.formControl?.disabled) {
      event.stopPropagation();
      this.directive?.open();
    }
  }

  /** Called when the color is updated within the color picker */
  onChange(color: string) {
    if (color !== this.color) this.formControl?.setValue(color);
  }
}
