<shared-dialog-wrapper header="Data Store Uploader" class="upload-dialog">
  <data-store-uploader-progress
    [status]="status"
    [shouldDisplayStatus]="shouldDisplayStatus"
    class="bulk-file-upload-progress"
    [filesUploading]="filesUploading"
  >
    <div class="bulk-upload-column">
      <h3 class="header">Select multiple files to upload</h3>

      <div *ngIf="filesErroredDuringUpload && sessionFiles.length !== 0" class="mat-h3 error-text-container">
        <p class="text-padding">
          Some of your files have errors during uploading! Hover over each highlighted session file to see the error.
        </p>
      </div>

      <data-store-file-uploader
        [allowMultiple]="true"
        class="session-uploader"
        [currentFiles]="files"
        [uploadTypes]="uploadTypes"
        (fileUpdate)="updateFiles($event)"
        [shouldDisplaySessionCreationActor]="false"
        [isBulkUpload]="true"
        [displayType]="'session-creation-only'"
      >
      </data-store-file-uploader>

      <div class="bulk-upload-column">
        <data-store-file-tree
          class="session-uploader"
          noFilesMessage="Uploaded files will show up here"
          [files]="files"
          [deleteCallback]="fileRemove.bind(this)"
          (deleteFile)="fileRemove($any($event))"
          (uploadToNode)="uploadToSession($any($event))"
          (editNodeName)="editSessionName($any($event))"
          [sessionFiles]="sessionFiles"
          (errorFiles)="(errorMessages)"
          [isBulkUpload]="true"
        ></data-store-file-tree>
      </div>
      <div class="bulk-upload-column">
        <div class="submit-container">
          <button [disabled]="!files.length" mat-flat-button color="accent" (click)="uploadAllSessions()">
            Upload Files
          </button>
        </div>
      </div>
    </div>
  </data-store-uploader-progress>
</shared-dialog-wrapper>
