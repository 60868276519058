import { TDMSBase } from "../../base";
import { ServerStatus } from "../../server.status";
import { UploadOption } from "./upload.option";

/**
 * This request contains the necessary information to upload a file to the TDMS data store.
 *
 * This will be utilized via the HTTP endpoint. Any missing data will result in this request
 *  being ignored.
 *
 * It is anticipated that this request will be sent as `request` form data with a file of `file` next to it.
 */
export class UploadRequest extends TDMSBase {
  /**
   * The type of data to associate to it.
   *
   * You technically only need to include the name of the type
   *  for validation but you can choose to pass the whole object if you wish.
   */
  type!: UploadOption;

  /**
   * The database ID of the session this data should be correlated to.
   *
   * Undefined means this file will be used for a session upload, if supported by the
   *  destination `UploadOption`.
   */
  sessionId: number | undefined;

  /**
   * This boolean tracks if this upload request is a session creation file that was uploaded after
   *  the session was already created "from scratch". This will allow us to still add metrics to sessions
   *  after the fact.
   */
  isDelayedSessionCreation = false;

  /**
   * If uploading a file to create a session name, you can specify that name here.
   *
   * If multiple sessions are created by the request the index will be placed at the end.
   */
  sessionName?: string;

  /**
   * This special upload type allows us to process this upload request as a non-standard upload request and instead use a special format.
   *
   *  Aggregate: Defines if this request is being used to parse this singular given file as
   *    aggregate data in numerous files of individual csv/excel rows.
   *  Bulk: Tells the backend if the user is bulk uploading files. If so we will save the role mapping for later.
   *
   *  Any other option that is not undefined should be related to the name of a plugin receiving that capability. In the backend
   *    you can implement this functionality by providing an upload option that implements a `specialTypeCallback`.
   */
  specialUploadType: "Aggregate" | "bulk" | "Propaq JSON" | undefined;

  /** The unique identifier that relates to this upload request. */
  requestId!: string;
}

/**
 * The response message that will be returned in the HTTP request for the status of the upload
 */
export class UploadResponse extends ServerStatus {
  /**
   * The Id of uploaded sessions
   */
  sessionIds?: number[];

  /**
   * How long, in ms, it took to process this upload request
   */
  timeElapsed!: number;
}
