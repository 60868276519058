import { TDMSBase } from "../base";

/** The various sub types of tags so that we can separate tags stored in the database */
export enum TagType {
  /** Tags related to sessions */
  Session = 1,
}

/** This class defines a tag that can be used in this application */
export class Tag extends TDMSBase {
  static readonly MAX_NAME_LENGTH = 24;
  /** Primary key for this tag in the database */
  id!: number;
  /** The type of this tag */
  type!: TagType;
  /** The name of this tag to display */
  name!: string;
  /**
   * The color to render for this tag. Should be a hex code.
   * @default #635fd1 Which is the purple accent of the frontend
   */
  color: string = "#635fd1";

  /** Validates the current fields of this tag and throws an error if anything is out of the ordinary */
  validate() {
    // Restrict to 12 characters
    if (this.name.length > Tag.MAX_NAME_LENGTH) throw new Error("Tag name too long");
  }

  /**
   * Given an array of tags and a new tag, checks if it is unique to that array. Uniqueness is determine by color,
   *  name, and type. All 3 must be the same for it to not be unique.
   *
   * @returns The matching tag that is not unique.
   */
  static checkUnique<T extends Tag>(array: Array<T>, newElement: T) {
    return array.find((x) => x.name === newElement.name && x.type === newElement.type && x.color === newElement.color);
  }
}
