import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { BarChartData, Session } from "@tdms/common";
import { LegendDisplay } from "@tdms/frontend/modules/charts/shared/legend/models/legend.display";
import { LegendDisplayObject } from "@tdms/frontend/modules/charts/shared/legend/models/legend.display.object";
import { ColorThemeService } from "@tdms/frontend/modules/material/services/themes.service";
import { MetricState } from "@tdms/frontend/modules/metrics/store/metric.state";
import { selectSessionFromId } from "@tdms/frontend/modules/session/store/session.selector";
import { Service } from "@tdms/frontend/modules/shared/services/base.service";
import { BehaviorSubject, map, mergeMap, Observable } from "rxjs";

/** A service that provides generic legend capability */
@Injectable({ providedIn: "root" })
export class LegendService extends Service {
  constructor(private store: Store<MetricState>, private themeService: ColorThemeService) {
    super();
  }

  /** Given a legend subject, creates an observable of a color map. Does this by tricking the color theme observable into thinking our legend data is bar chart data. */
  observeColors(legend: BehaviorSubject<LegendDisplay[]>) {
    return legend.pipe(
      map((x) =>
        this.themeService.observeColorsFor(
          x.flatMap((z) => z.options.flatMap((v) => BarChartData.fromPlain({ name: v.name })))
        )
      ),
      mergeMap((colorsObservable) => colorsObservable)
    );
  }

  /** Given a legend display, converts it into a behavior subject */
  toBehavior(legend: LegendDisplay[]) {
    return new BehaviorSubject(legend);
  }

  /** Given an observable legend, converts it into a behavior subject */
  observableToBehavior<T extends LegendDisplay[]>(legend: Observable<T>, initialValue: T) {
    const sub = new BehaviorSubject(initialValue);
    legend.subscribe(sub);
    return sub;
  }

  /**
   * Given an array of sessions, returns a legend that should support legends
   */
  getSessionBasedLegend(sessions: Session[]) {
    return [
      new LegendDisplay({
        options: sessions.map(
          (session, index) => new LegendDisplayObject({ displayed: true, name: session.getOrderedName(index) })
        ),
      }),
    ];
  }

  /**
   * Creates a legend that supports role based elements for a **singular** session.
   * @param sessions The sessions to map the data for based on role.
   */
  getRoleBasedLegend(session?: Session) {
    if (!session) return [];
    return [
      new LegendDisplay({
        options: session.roles
          ?.sort((a, b) => a.sortOrder - b.sortOrder)
          .map((x) => new LegendDisplayObject({ displayed: x.displayed, name: x.name })),
      }),
    ];
  }

  /**
   * Creates a legend observable based on the ngrx store so it can get updates as the session updates
   * @param session The sessions to map the data for based on role.
   */
  getRoleBasedLegendWithStore(session: Session) {
    return this.store.select(selectSessionFromId(session.id)).pipe(
      map((x) => {
        if (x) return this.getRoleBasedLegend(Session.fromPlain(x));
        return [];
      })
    );
  }

  /**
   * Creates a legend that supports role based elements for **multiple** sessions.
   * @param sessions The sessions to map the data for based on role.
   */
  getRoleBasedLegendFromSessions(sessions: Session[]) {
    return sessions.map(
      (session, index) =>
        new LegendDisplay({
          groupName: session.getOrderedName(index),
          options: session.roles.map(
            (z) =>
              new LegendDisplayObject({
                displayed: true,
                name: z.name,
                originalNameMapping: `${session.getOrderedName(index)} - ${z.name}`,
              })
          ),
        })
    );
  }
}
