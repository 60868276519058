export namespace AudioSettings {
  export const PLUGIN_NAME = "Audio";

  /** Helper enum for audio setting names */
  export enum Names {
    transcriptionDisplayed = "transcription_displayed",
  }

  /** Helper enum for audio setting titles */
  export enum HelpfulTitles {
    transcriptionDisplayed = "Transcription Displayed on Dashboard",
  }

  /** Helper enum for audio setting descriptions */
  export enum HelpfulDescriptions {
    transcriptionDisplayed = "Controls if the dashboard should render the transcription playback.",
  }
}
