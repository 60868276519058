import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { Session, UploadOption, UploadRequest } from "@tdms/common";
import { BulkFileUploadComponent } from "@tdms/frontend/modules/data-store/components/uploader/bulk-file-upload/bulk-file-upload.component";
import {
  SpecialUploaderComponent,
  SpecialUploaderDialogProperties,
} from "@tdms/frontend/modules/data-store/components/uploader/special-uploader/special-uploader.component";
import { DataStoreState } from "@tdms/frontend/modules/data-store/models/data.store.state";
import { selectDataStoreUploadOptions } from "@tdms/frontend/modules/data-store/models/store/data.store.selector";
import { SessionInfoComponent } from "@tdms/frontend/modules/session/components/info/info.component";
import { selectCurrentSession } from "@tdms/frontend/modules/session/store/session.selector";
import { ConfigService } from "@tdms/frontend/modules/settings/services/config.service";
import { DialogWrapperComponent } from "@tdms/frontend/modules/shared/components";
import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";
import { FileUploaderComponent } from "../file-uploader/file-uploader.component";

/**
 * A component that handles upload capabilities for the data store
 */
@Component({
  selector: "data-store-uploader",
  templateUrl: "./uploader.component.html",
  styleUrls: ["./uploader.component.scss"],
})
export class UploaderComponent extends SubscribingComponent {
  /**
   * The currently selected session
   */
  currentSession: Session | undefined;

  /**
   * What we should be displaying right now
   */
  displayMode: "initial" | "createNewSession" | "uploadFilesToCurrent" | "bulkFileUpload" = "initial";

  /**
   * An array of upload options meant for only creating new sessions from
   */
  uploadOptionsForCreation: UploadOption[] = [];

  /**
   * An array of upload options meant only for uploading to currently selected sessions
   */
  uploadOptionsForCurrent: UploadOption[] = [];

  /** Special upload callback types */
  specialUploadTypes: UploadOption[] = [];

  constructor(private store: Store<DataStoreState>, private dialog: MatDialog, public configService: ConfigService) {
    super();
    this.store.select(selectCurrentSession).subscribe((x) => (this.currentSession = x));
  }

  ngOnInit(): void {
    this.addSubscription(
      this.store.select(selectDataStoreUploadOptions).subscribe((options) => {
        this.uploadOptionsForCreation = FileUploaderComponent.getUploadTypesForOptions(
          options,
          "session-creation-only"
        );
        this.uploadOptionsForCurrent = FileUploaderComponent.getUploadTypesForOptions(
          options,
          "exclude-session-creation"
        );
        this.specialUploadTypes = options.filter((x) => x.usedOnlyForParsing);
      })
    );
  }

  /**
   * Sets up our modal to create a new session and upload files with it
   */
  onCreateNewSession() {
    this.displayMode = "createNewSession";
  }

  /** Returns if this special upload option is available or not */
  specialUploadOptionAvailable(type: UploadRequest["specialUploadType"]) {
    return this.specialUploadTypes.find((x) => x.name === type);
  }

  /** Opens a dialog for creating a special type upload request */
  openSpecialUploader(type: UploadRequest["specialUploadType"]) {
    this.dialog.closeAll();
    this.dialog.open(SpecialUploaderComponent, {
      data: { type } as SpecialUploaderDialogProperties,
      ...DialogWrapperComponent.getDefaultOptions(),
    });
  }

  /**
   * Sets up our modal to upload files to this session
   */
  onUploadFilesToCurrentSession() {
    this.displayMode = "uploadFilesToCurrent";
  }

  /** Callback to fire when we wish to create a new session from scratch */
  onCreateScratchSession() {
    // Close the current open one
    this.dialog.closeAll();
    // Open the session info modal
    this.dialog.open(SessionInfoComponent, DialogWrapperComponent.getDefaultOptions());
  }

  /**
   * Sets up our modal to upload files in bulk.
   */
  onBulkFileUpload() {
    this.dialog.closeAll();
    this.dialog.open(BulkFileUploadComponent, DialogWrapperComponent.getDefaultOptions());
  }
}
