import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import {
  ChartConfiguration,
  GaugeChartData,
  Session,
  SessionSummary,
  SessionSummaryRequest,
  SessionSummaryTopics,
  TDMSWebSocketMessage,
  Utility,
  VideoConfig,
} from "@tdms/common";
import AudioService from "@tdms/frontend/modules/audio/services/audio.service";
import { selectAllTranscriptionsFromState } from "@tdms/frontend/modules/audio/store/transcription.selector";
import { WebSocketService } from "@tdms/frontend/modules/communication/services/websocket.service";
import { selectFilesForSession } from "@tdms/frontend/modules/data-store/models/store/data.store.selector";
import { ColorThemeService } from "@tdms/frontend/modules/material/services/themes.service";
import {
  MetricCardDataStore,
  MetricServiceDataStore,
} from "@tdms/frontend/modules/metrics/components/metric-card/models/metric.configuration";
import { MetricGridDataStore } from "@tdms/frontend/modules/metrics/components/metric-grid/models/metric-grid.configuration";
import { SessionSummaryState } from "@tdms/frontend/modules/session-summary/store/summary.state";
import { selectCurrentSession } from "@tdms/frontend/modules/session/store/session.selector";
import { ConfigService } from "@tdms/frontend/modules/settings/services/config.service";
import { Service } from "@tdms/frontend/modules/shared/services/base.service";
import { intervalToDuration } from "date-fns";
import { combineLatest, firstValueFrom, map, of } from "rxjs";
import { SessionSummaryActions } from "../store/summary.adapter";
import { selectSessionSummaryById, selectSessionSummaryForCurrentSession } from "../store/summary.selector";

/**
 * Service for session summary handling
 */
@Injectable({ providedIn: "root" })
export default class SessionSummaryService extends Service {
  /**
   * This chart configuration is used for the session score gauge chart
   *  to display roughly how well a session went.
   */
  static readonly SCORE_PLUGIN_CONFIG = new ChartConfiguration({
    type: "gauge",
    metricName: "session-score",
    title: "Session Score*",
    getBySessionIdQueue: "session-score",
    position: 0,
    exportAllowed: false,
    shouldBeUsedForTimeline: false,
    sizing: "half",
    footer: "* Overall scores are still being refined and may be inaccurate",
  });

  constructor(
    private store: Store<SessionSummaryState>,
    private configService: ConfigService,
    private wsService: WebSocketService,
    private themeService: ColorThemeService,
    private audioService: AudioService
  ) {
    super();
  }

  override async onSessionChanged(session?: Session): Promise<void> {
    this.loadSessionSummaryData(session);
  }

  override async onBackendDisconnected(): Promise<void> {
    this.store.dispatch(SessionSummaryActions.empty({}));
  }

  override async onSessionDataRefresh(session: Session) {
    this.loadSessionSummaryData(session);
  }

  private async loadSessionSummaryData(session: Session | undefined) {
    if (this.configService.configData && this.configService.configData.sessionSummary.enabled)
      if (session) await this.getSessionSummaryForSession(session);
  }

  /**
   * Given a session, requests from the backend the session summary content and adds
   *    it to the data store.
   */
  async getSessionSummaryForSession(session: Session) {
    const message = new TDMSWebSocketMessage(
      SessionSummaryTopics.getBySessionId,
      session.id,
      SessionSummaryRequest.fromPlain({ sessionId: session.id })
    );
    const response = await this.wsService.sendAndReceive<SessionSummary>(message);
    const newSummary = SessionSummary.fromPlain(response.payload);
    const sessionSummary = await firstValueFrom(this.store.select(selectSessionSummaryById(session.id)));
    if (sessionSummary == null) this.store.dispatch(SessionSummaryActions.add(newSummary));
    else this.store.dispatch(SessionSummaryActions.update(newSummary));
  }

  /**
   * Returns the metric data for our session summary graph for later display
   */
  async getSessionScoreMetric(config: ChartConfiguration = SessionSummaryService.SCORE_PLUGIN_CONFIG.clone()) {
    const session = await firstValueFrom(this.store.select(selectCurrentSession));
    const data = await firstValueFrom(this.store.select(selectSessionSummaryForCurrentSession));
    if (data?.isCurved) config.footer = config.footer + " *";
    const renderData = GaugeChartData.fromPlain({ name: "Session Score", value: data?.sessionScore?.score });
    const colorMap = this.themeService.observeColorsFor(renderData);
    return new MetricCardDataStore(
      new MetricServiceDataStore(renderData, [], data == null || session?.isProcessing),
      config,
      colorMap,
      of(true),
      of(true)
    );
  }

  /**
   * Returns the metric grid data store object for metrics that should be displayed on the session summary page
   *  for graphing capabilities
   */
  async getMetricGridDataStore() {
    return new MetricGridDataStore(
      [of(await this.getSessionScoreMetric())],
      undefined,
      undefined,
      of(new Date()),
      of(new Date()),
      of([])
    );
  }

  /**
   * Returns an observable that contains multiple subscriptions to return information about the
   *  current session for things like duration and start date and available data types.
   */
  getSessionMetadataObservable() {
    return combineLatest([
      this.store.select(selectCurrentSession),
      this.store.select(selectAllTranscriptionsFromState),
      this.audioService.audioBlob,
      this.store.select(selectFilesForSession),
    ]).pipe(
      map((data) => {
        const session = data[0];
        const transcript = data[1];
        const audio = data[2];
        const files = data[3];
        const duration = intervalToDuration({ start: 0, end: (session?.duration ?? 0) * 1000 });
        const durationString = `${Utility.padNumber(duration.hours ?? 0)}:${Utility.padNumber(
          duration.minutes ?? 0
        )}:${Utility.padNumber(duration.seconds ?? 0)}`;
        return {
          session,
          duration: durationString,
          startDate: Utility.getDateAsZulu(session?.startDate),
          hasTranscript: transcript.length !== 0,
          hasAudio: audio?.file,
          hasVideos: files.find((x) => x.pluginType === VideoConfig.VIDEO_DATA_STORE_TYPE),
        };
      })
    );
  }
}
