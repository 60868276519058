<shared-dialog-wrapper
  header="Data Store Uploader"
  class="upload-dialog"
  [ngClass]="{ initial: displayMode === 'initial' }"
>
  <div class="upload-dialog-container">
    <!-- Initial display -->
    <div *ngIf="displayMode === 'initial'">
      <div class="header">Please select an upload option below</div>
      <div class="img-container">
        <shared-img-wrapper src="../../../assets/upload/uploadOptimized.png" class="upload-img"></shared-img-wrapper>
      </div>
      <div class="buttons">
        <!-- Session creation. Main button is the uploader -->
        <shared-split-button
          color="accent"
          [mainButtonClick]="onCreateNewSession.bind(this)"
          [disabled]="uploadOptionsForCreation.length === 0"
          [mainButtonTooltip]="uploadOptionsForCreation.length === 0 ? 'No available upload options' : ''"
          mainButtonText="Create session from files"
          [configDisable]="'dataStore.uploadEnabled'"
          configDisabledStateMessage="Uploading files is disabled"
        >
          <!-- Create a new session from scratch without any files -->
          <button
            mat-menu-item
            class="uploader-menu-button"
            color="accent"
            matTooltip="Allows you to create a session with your own default information."
            matTooltipPosition="right"
            (click)="onCreateScratchSession()"
            [configDisable]="'session.allowSessionCreationFromScratch'"
            configDisabledStateMessage="Creating sessions from scratch is disabled"
          >
            <mat-icon>edit</mat-icon>From scratch
          </button>
          <!-- Aggregate splitter -->
          <button
            mat-menu-item
            class="from-scratch-menu-button"
            color="accent"
            matTooltip="Allows you to split an aggregate file into individual files."
            matTooltipPosition="right"
            (click)="openSpecialUploader('Aggregate')"
            [configDisable]="'dataStore.uploadEnabled'"
            configDisabledStateMessage="Uploading files is disabled"
          >
            <mat-icon>people</mat-icon>Aggregate
          </button>
          <!-- Propaq Splitter -->
          <button
            *ngVar="specialUploadOptionAvailable('Propaq JSON') as available"
            mat-menu-item
            color="accent"
            [matTooltip]="
              available
                ? 'Using this uploader will allow you to process a propaq json file into a more human readable format'
                : 'Uploading Propaq data is disabled'
            "
            matTooltipPosition="right"
            (click)="openSpecialUploader('Propaq JSON')"
            [disabled]="!available"
            [configDisable]="'dataStore.uploadEnabled'"
            configDisabledStateMessage="Uploading files is disabled"
          >
            <mat-icon>monitor_heart</mat-icon>Propaq JSON
          </button>
          <!-- Bulk Upload -->
          <button
            mat-menu-item
            class="uploader-menu-button"
            color="accent"
            matTooltip="Allows you to bulk upload session files and associate files with each session."
            matTooltipPosition="right"
            (click)="onBulkFileUpload()"
            [configDisable]="'dataStore.uploadEnabled'"
            configDisabledStateMessage="Cannot upload sessions in bulk to the data store at this time."
          >
            <mat-icon>upload_file</mat-icon>Bulk Upload
          </button>
        </shared-split-button>

        <!-- Upload new files to this session -->
        <button
          mat-flat-button
          [disabled]="currentSession == null || uploadOptionsForCurrent.length === 0"
          [matTooltip]="
            currentSession == null
              ? 'No session selected to upload to'
              : uploadOptionsForCurrent.length === 0
              ? 'No available upload options'
              : ''
          "
          color="accent"
          (click)="onUploadFilesToCurrentSession()"
          [configDisable]="'dataStore.uploadEnabled'"
          configDisabledStateMessage="Uploading files is disabled"
        >
          Upload files to current session
        </button>
      </div>
    </div>

    <!-- Create new session display -->
    <div *ngIf="displayMode === 'createNewSession'">
      <data-store-session-creation></data-store-session-creation>
    </div>

    <!-- Upload to current session-->
    <div *ngIf="displayMode === 'uploadFilesToCurrent'">
      <data-store-session-extras></data-store-session-extras>
    </div>
  </div>
</shared-dialog-wrapper>
