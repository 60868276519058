import { Directive } from "@angular/core";
import { Axis, CanvasHelperBase } from "@tdms/frontend/modules/metrics/components/timeline/canvas/canvas-helper";
import { TDMSTheme } from "@tdms/frontend/modules/shared/components";
import autoBind from "auto-bind";

@Directive({})
export class AnimatedPlaybackHelper extends CanvasHelperBase {
  private maxTimeOnScreen!: number;

  constructor(canvas: HTMLCanvasElement, context: CanvasRenderingContext2D) {
    super(canvas, context);
    autoBind(this);
  }

  initialize(theme: TDMSTheme, width: number, xAxis: Axis, yAxis: Axis, longestXLabel: string) {
    this.setupCanvasSizing(width, xAxis, yAxis, longestXLabel);
    this.setupCanvasTheme(theme, { alpha: 1 });
  }

  updateMaxTimeOnScreen(max: number) {
    this.maxTimeOnScreen = max;
  }

  drawPlaybackIndicator(timestamp: number, currentPage: number) {
    const elapsed = timestamp - this.maxTimeOnScreen * currentPage;
    const percentage = elapsed / this.maxTimeOnScreen;
    const offset = this.safeWidth * percentage;

    this.context.strokeStyle = this.lineColor;
    this.reset();
    this.drawVerticalLine(offset);
  }
}
