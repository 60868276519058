import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { BookmarkTopics, BookmarkType, TDMSWebSocketMessage, WebSocketCommunication } from "@tdms/common";
import { BookmarkTypeState } from "@tdms/frontend/modules/bookmark/store/bookmark.state";
import { BookmarkTypeActions } from "@tdms/frontend/modules/bookmark/store/bookmark.type.action";
import { WebSocketService } from "../../communication/services/websocket.service";
import { Service } from "../../shared/services/base.service";

/** A service to provide capability specifically for bookmark types */
@Injectable({ providedIn: "root" })
export class BookmarkTypeService extends Service {
  constructor(private store: Store<BookmarkTypeState>, private wsService: WebSocketService) {
    super();
  }

  override async onUserChanged(): Promise<void> {
    const response = await this.wsService.sendAndReceive(new TDMSWebSocketMessage(BookmarkTopics.getAllTypes));
    const bookmarkTypes = BookmarkType.fromPlainArray(response.payload as any);
    this.store.dispatch(BookmarkTypeActions.emptyBookmarkTypes({}));
    this.store.dispatch(BookmarkTypeActions.addMany({ bookmarkTypes: bookmarkTypes }));
  }

  /** Given a new bookmark type, tells the backend to add it to the database. */
  async add(bmk: BookmarkType) {
    return await this.wsService.sendAndReceive<BookmarkType>(
      new TDMSWebSocketMessage(BookmarkTopics.addType, undefined, bmk)
    );
  }

  /** Listens for new bookmark types being added from the backend. */
  @WebSocketCommunication.listen<void, TDMSWebSocketMessage<BookmarkType>>(BookmarkTopics.addType)
  protected async addReceived(data: TDMSWebSocketMessage<BookmarkType>) {
    this.store.dispatch(BookmarkTypeActions.add(BookmarkType.fromPlain(data.payload)));
  }

  /** Given a bookmark type, asks the backend to delete it from the database. */
  async delete(bookmark: BookmarkType) {
    return await this.wsService.sendAndReceive<BookmarkType>(
      new TDMSWebSocketMessage(BookmarkTopics.removeType, undefined, bookmark)
    );
  }

  /** Listens for bookmark type deletion requests to update the store */
  @WebSocketCommunication.listen<void, TDMSWebSocketMessage<BookmarkType>>(BookmarkTopics.removeType)
  protected async deleteReceived(data: TDMSWebSocketMessage<BookmarkType>) {
    this.store.dispatch(BookmarkTypeActions.delete(data.payload));
  }

  /** Given a bookmark type, asks the backend to update it's information. */
  async update(bookmark: BookmarkType) {
    return await this.wsService.sendAndReceive<BookmarkType>(
      new TDMSWebSocketMessage(BookmarkTopics.updateType, undefined, bookmark)
    );
  }

  /** Listens for bookmark type update requests to update the data store */
  @WebSocketCommunication.listen<void, TDMSWebSocketMessage<BookmarkType>>(BookmarkTopics.updateType)
  protected async updateReceived(data: TDMSWebSocketMessage<BookmarkType>) {
    this.store.dispatch(BookmarkTypeActions.update(BookmarkType.fromPlain(data.payload)));
  }
}
