import { Component, EventEmitter, Input, Output } from "@angular/core";
import { GenericTableColumn } from "@tdms/frontend/modules/shared/components/tables/generic-table/generic-table.component";

export type ValueSubmitEvent = {
  column: GenericTableColumn;
  value: any;
};

/**
 * A generalized cell text editor component to be used with @SharedEditableTable.
 * Just displays a text input field and sets up the event listeners as provided.
 */
@Component({
  selector: "cell-text-editor[column][value]",
  templateUrl: "./cell-text-editor.component.html",
  styleUrls: ["./cell-text-editor.component.scss"],
})
export class CellTextEditorComponent {
  @Input() column!: GenericTableColumn;
  @Input() value!: string;
  @Input() tooltipMessage: string | undefined;
  @Input() fieldClass: string | undefined;
  @Input() isValid: boolean = true;

  @Output() onValueChanged: EventEmitter<any> = new EventEmitter();
  @Output() onValueSubmitted: EventEmitter<ValueSubmitEvent> = new EventEmitter();

  onValueChange(event: any) {
    this.onValueChanged.emit(event);
  }

  onValueSubmit() {
    this.onValueSubmitted.emit({ column: this.column, value: this.value });
  }
}
