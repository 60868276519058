import { Directive } from "@angular/core";
import {
  Axis,
  CanvasHelperBase,
  LabelData,
  LabelFunction,
  ObservableSafeSpace,
} from "@tdms/frontend/modules/metrics/components/timeline/canvas";
import { TDMSTheme } from "@tdms/frontend/modules/shared/components";
import autoBind from "auto-bind";

/**
 * A utility class that manipulates a canvas, drawing grid lines and axis labels given some criteria.
 * It also helps autosize a canvas given a width and number of vertical text lines.
 */
@Directive({})
export class GridCanvasHelper extends CanvasHelperBase {
  constructor(canvas: HTMLCanvasElement, context: CanvasRenderingContext2D) {
    super(canvas, context);
    this.safeSpace = new ObservableSafeSpace(0, 0);
    autoBind(this);
  }

  initialize(theme: TDMSTheme, canvasWidth: number, xAxis: Axis, yAxis: Axis, longestXLabel: string) {
    this.reset();
    this.setupCanvasSizing(canvasWidth, xAxis, yAxis, longestXLabel);
    this.setupCanvasTheme(theme, { alpha: 0.45 });
  }

  draw() {
    this.drawYAxis();
    this.drawXAxis();
  }

  /**
   * Helper function to draw an x axis with n grid lines and an optional label getter function.
   * @param numGridLines The number of grid lines to draw on the axis.
   * @param labels A function to get a label to display for a given axis line and offset.
   */
  drawXAxis() {
    this.context.save();
    this.context.strokeStyle = this.lineColor + "75";
    this.drawGrid(this.xAxis, this.computeXOffsetForColumn, this.drawVerticalLine);
    this.context.restore();
    this.drawXAxisLabels();
  }

  /**
   * Helper function to draw the labels for the axis underneath the safe space.
   * Since the x axis labels are redrawn when pages change, we do this separately so that we can clear the
   * label safe space to delete the previous labels first.
   */
  drawXAxisLabels() {
    this.context.clearRect(0, this.safeHeight + 2, this.safeWidth, this.canvas.height);
    const yOffset = this.canvas.height - this.xHeight / 2;

    for (let i = 0; i < this.xAxis.numberOfLabels; i++) {
      const offset = this.computeXOffsetForColumn(i);
      const label = this.xAxis.labels(i, offset);
      if (label != null) this.horizontalCenterTextAt(this.safeSpace.horizontal + offset, yOffset, label);
    }
  }

  /**
   * Helper function to draw a y axis with n grid lines and an optional label getter function.
   * @param numGridLines The number of grid lines to draw on the axis.
   * @param labels A function to get a label to display for a given axis line and offset.
   */
  drawYAxis() {
    return this.drawGrid(
      this.yAxis,
      this.computeYOffsetForRow,
      (offset, _previousOffset) => {
        this.drawHorizontalLine(offset);
      },
      (offset, label) => this.verticalCenterTextAt(2.5, offset, label),
      this.yAxis.labels
    );
  }

  /**
   * Generate an even number of grid lines computed from dimensionMax, drawing lines and labels for each line.
   * @param numGridLines The number of grid lines to draw.
   * @param dimensionMax The max dimension of the axis we are gridding.
   * @param lineDrawer The function that will actually draw the line for each grid offset.
   * @param labelDrawer The function that will actually draw label text for each grid offset.
   * @param labels A function that will return label data for a given index and canvas offset.
   */
  private drawGrid(
    axis: Axis,
    offsetCalculator: (index: number) => number,
    lineDrawer: (offset: number, previousOffset: number) => void,
    labelDrawer?: (offset: number, label: LabelData) => void,
    labels?: LabelFunction
  ) {
    for (let i = 0; i < axis.numberOfLabels; i++) {
      const offset = offsetCalculator(i);
      const previousOffset = i > 0 ? offsetCalculator(i - 1) : 0;
      lineDrawer(offset, previousOffset);

      if (labels) {
        const label = labels(i, offset);
        if (label && labelDrawer) {
          labelDrawer(offset, label);
        }
      }
    }

    lineDrawer(offsetCalculator(axis.numberOfLabels), offsetCalculator(axis.numberOfLabels - 1));
  }

  /**
   * Outline an axis label on the x axis with lines.
   * @param currentOffset The current x axis line offset.
   * @param previousOffset The previous x axis line offset.
   */
  outlineXAxisLabel(currentOffset: number, previousOffset: number) {
    this.drawLine(0, currentOffset, this.safeSpace.horizontal, currentOffset);
    this.drawLine(0, previousOffset, this.safeSpace.horizontal, previousOffset);
    this.drawLine(0, previousOffset, 0, currentOffset);
  }
}
