<!-- Still use the standard audio controller but we'll replace it with our own content -->
<audio #audioController class="audio-controller" controls *ngIf="src" controlsList="nodownload noplaybackrate">
  <source [src]="src" />
</audio>
<!-- Spinner to wait on the audio to be ready for playback before using -->
<shared-div-progress-component
  [ready]="audioController != null && audioController.readyState >= 1"
  [spinnerDiameter]="30"
  class="audio-control-progress"
  [ngClass]="{ small: displayType === 'small', expanded: displayType === 'expanded' }"
>
  <div
    class="audio-control-container"
    [ngClass]="{ small: displayType === 'small', expanded: displayType === 'expanded' }"
  >
    <div class="buttons">
      <!-- Play/Pause -->
      <button
        mat-flat-button
        (click)="playPause()"
        color="accent"
        class="play-pause"
        [ngClass]="{ playing: !isPaused }"
        matTooltip="Play/pause audio playback"
        [matTooltipPosition]="displayType === 'small' ? 'below' : 'right'"
      >
        <mat-icon>{{ isPaused ? "play_arrow" : "pause" }}</mat-icon>
      </button>
      <!-- Reset -->
      <button
        *ngIf="displayType === 'expanded'"
        mat-flat-button
        (click)="resetAudioPlayback()"
        color="accent"
        matTooltip="Reset audio playback to the beginning"
        matTooltipPosition="right"
      >
        <mat-icon>restart_alt</mat-icon>
      </button>
    </div>
    <!-- Slider -->
    <mat-slider
      class="progress-slider"
      color="accent"
      min="0"
      max="100"
      [(ngModel)]="timeDisplay"
      (input)="dragStart($event)"
      (change)="dragStop()"
    >
    </mat-slider>
    <span class="state" *ngIf="displayPlaybackState">{{ playbackState }}</span>
  </div>
</shared-div-progress-component>
