import { Type } from "class-transformer";
import { decorate } from "ts-mixer";
import { TDMSBase } from "../base";
import { Session } from "./session";

/** Class for session creation request and reply from scratch */
export class SessionCreateMessage extends TDMSBase {
  @decorate(Type(() => Session))
  session!: Session;

  successful: boolean = true;

  //Used in backend endpoint to tell the front end that the user clicked out or cancelled the dialog.
  cancellingDialog: boolean | undefined;

  //Message to display to user if an error occurs.
  failureMessage: string | undefined;
}
