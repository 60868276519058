import { Component } from "@angular/core";
import { PopulatedSearchOption, Utility } from "@tdms/common";
import { AccessLogService } from "@tdms/frontend/modules/data-store/services/access-log.service";
import { DownloadService } from "@tdms/frontend/modules/data-store/services/download.service";
import { FileEditService } from "@tdms/frontend/modules/data-store/services/edit.service";
import RecycleBinService from "@tdms/frontend/modules/data-store/services/recycle-bin.service";
import { SearchService } from "@tdms/frontend/modules/data-store/services/search.service";
import { DataColumn, DatalessColumn } from "@tdms/frontend/modules/shared/components";
import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";
import autoBind from "auto-bind";
import { SearchEntry, SearchResult } from "./search.data";

/**
 * This component is the search tab of the data store management screen.
 */
@Component({
  selector: "search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"],
})
export class SearchComponent extends SubscribingComponent {
  displayedColumns = [
    new DatalessColumn("space", ""),
    new DataColumn<SearchEntry, string>("sessionName", "Session Name"),
    new DataColumn<SearchEntry, string>("filename", "Filename"),
    new DataColumn<SearchEntry, Date>("startDate", "Start Date", Utility.getDateAsZulu.bind(this)),
    new DataColumn<SearchEntry, Date>("endDate", "End Date", Utility.getDateAsZulu.bind(this)),
    new DatalessColumn("actions", "Actions"),
  ];

  dataLoading: boolean = true;
  searchResult!: SearchResult;
  recycleBinTimeout: number = 3;

  recycleBinTooltip: string = `Schedule for deletion. Will place the file in the recycle bin and schedule it for permanent deletion in ${this.recycleBinTimeout} days.`;

  /** Tracks whatever columns the table currently has selected in preparation for mass downloads/deletions */
  currentSelectedColumns: SearchEntry[] = [];

  constructor(
    private downloadService: DownloadService,
    private accessLogService: AccessLogService,
    private recycleBinService: RecycleBinService,
    private searchService: SearchService,
    private editService: FileEditService
  ) {
    super();
    autoBind(this);
  }

  ngOnInit() {
    this.addSubscription(
      this.searchService.observeSearchResults().subscribe((result) => {
        this.searchResult = result;
      })
    );

    this.recycleBinService.getRecycleBinTimeout().then((timeout) => {
      this.recycleBinTimeout = timeout;
      this.recycleBinTooltip = `Schedule for deletion. Will place the file in the recycle bin and schedule it for permanent deletion in ${this.recycleBinTimeout} days.`;
    });

    this.dataLoading = this.searchService.querying;
  }

  /**
   * Called when user initiates search from query bar or advanced query builder.
   * Start the search and display the progress bar.
   * @param options The search options provided.
   */
  async onSearchStarted(options: PopulatedSearchOption[]) {
    this.dataLoading = true;
    await this.searchService.startInteractiveQuery(options);
    this.dataLoading = false;
  }

  /**
   * Requests to download the given search entries.
   */
  async downloadFile(entries: SearchEntry[]) {
    const accessReason = await this.accessLogService.askAccessReason(entries.map((x) => x.file));
    if (accessReason == null) return;
    // Download each file individually
    for (let entry of entries) await this.downloadService.saveDataStoreFile(entry.file, accessReason);
  }

  /**
   * Requests to delete the given entries. These are scheduled for deletion and are not permanent.
   */
  async deleteFile(entries: SearchEntry[]) {
    await this.recycleBinService.deleteFiles(
      entries.map((x) => x.file),
      false
    );
    await this.searchService.startInteractiveQuery(); /// Rerun the query when a file is deleted to update the table.
  }

  /**
   * Pops a file edit dialog and requests the backend make the user specified changes.
   * @param entry The entry to edit.
   */
  async editFile(entry: SearchEntry) {
    await this.editService.userDataStoreEdit(entry.file);
    await this.searchService.startInteractiveQuery(); /// Rerun the query when a file is edited to update the table.
  }

  /**
   * Called whenever a new selection is fired from the generic table to update our local
   *  cache.
   */
  tableSelectionUpdate(items: SearchEntry[]) {
    this.currentSelectedColumns = items;
  }
}
