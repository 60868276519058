<shared-generic-table
  class="transcription-table"
  [tableName]="tableName"
  [data]="data"
  [displayedColumns]="displayedColumns"
  [filterable]="filterable"
  [addRowCallback]="addRowCallback"
  [clickCallback]="isEditing ? undefined : clickCallback"
  [filterableFields]="filterableFields"
  [defaultSortHeader]="defaultSortHeader"
  [defaultHeaderSortDirection]="defaultHeaderSortDirection"
  [paginatorPageSize]="paginatorPageSize"
  [shouldGuessPaginatorPageSize]="shouldGuessPaginatorPageSize"
  [showFirstLastButtons]="showFirstLastButtons"
  [exportEnabled]="exportEnabled"
  [matRowHeight]="matRowHeight"
  [selectionUpdate]="selectionUpdate"
  [activeRows]="activeRows"
>
  <ng-container *ngIf="isEditing; then editor; else default"></ng-container>
  <ng-template #editor>
    <cell-display *ngFor="let cellEditor of editorOverrideRefs" [columns]="cellEditor.columns">
      <ng-template #display let-value="value" let-column="column" let-element="element">
        <ng-container
          *ngTemplateOutlet="
            cellEditor.editorTemplateRef;
            context: {
              value: value,
              column: column,
              element: element
            }
          "
        >
        </ng-container>
      </ng-template>
    </cell-display>
    <!-- Include the cell display templates to include pass throughs for columns that don't have an editor specified.-->
    <!-- This means any column without an editor specified is not editable! -->
    <ng-container *ngTemplateOutlet="default"></ng-container>
  </ng-template>

  <!-- We aren't editing, so just pass along our display refs to the generic table. -->
  <ng-template #default>
    <cell-display *ngFor="let cellDisplay of cellDisplayRefs" [columns]="cellDisplay.columns">
      <ng-template #display let-value="value" let-column="column" let-element="element">
        <ng-container
          *ngTemplateOutlet="
            cellDisplay.displayTemplateRef;
            context: {
              value: value,
              column: column,
              element: element
            }
          "
        ></ng-container>
      </ng-template>
    </cell-display>
  </ng-template>

  <ng-template #buttonRow>
    <div *ngIf="isEditing; then saveButtons; else editButton"></div>
    <ng-template #saveButtons>
      <button
        color="accent"
        mat-raised-button
        [ngClass]="{ enabled: hasChanged && editsValid }"
        class="save"
        [disabled]="!hasChanged || !editsValid"
        (click)="onSaveClicked()"
        [matTooltip]="saveTooltip ?? 'Save edits'"
        [configDisable]="editConfigEnable"
        [configDisabledStateMessage]="configDisabledMessage"
      >
        <mat-icon>save</mat-icon> Save
      </button>
      <button mat-raised-button color="warn" (click)="onCancelClicked()" [matTooltip]="cancelTooltip ?? 'Cancel edits'">
        <mat-icon>cancel</mat-icon> Cancel
      </button></ng-template
    >
    <ng-template #editButton>
      <button
        mat-raised-button
        color="accent"
        (click)="onEditingStarted()"
        [matTooltip]="editTooltip ?? 'Edit table values'"
        [configDisable]="editConfigEnable"
        [configDisabledStateMessage]="configDisabledMessage"
      >
        <mat-icon>edit</mat-icon> Edit
      </button></ng-template
    >
    <ng-container *ngIf="extraHeaderButtons != null">
      <ng-container *ngTemplateOutlet="extraHeaderButtons"></ng-container>
    </ng-container>
  </ng-template>
</shared-generic-table>
