<mat-form-field color="accent" [class]="fieldClass">
  <input
    matInput
    #tooltip
    [matTooltip]="tooltipMessage ?? ''"
    [ngClass]="{ error: !isValid }"
    (input)="onValueChange($event)"
    (keyup.enter)="onValueSubmit()"
    [value]="value"
  />
</mat-form-field>
