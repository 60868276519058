import { Directive } from "@angular/core";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { ChartConfiguration, CustomTypes } from "@tdms/common";
import { ConfigDirective } from "@tdms/frontend/modules/shared/directive/config.directive";
import { ChartStylingComponent } from "./styling.base";

/** This type defines the supported button structure for button base components to be displayed within the chart base. */
export interface Button {
  /** A unique identifier for this button so it can be easily re referenced. */
  id: string;
  /** The icon to display. This should be a material icon. */
  icon: string;
  /** A tooltip to render when this button is hovered. */
  tooltip?: string;
  /**
   * If this button should even be visible to be clicked.
   *
   * This value must be a function call to determine the boolean. This is because Angular can't appropriately
   *  determine the value during re renders as it references a boolean value for the time it was created and thus
   *  it will never have changed.
   *
   * @default true
   */
  isVisible?: { (): boolean };
  /**
   * If this button should be enabled when visible.
   *
   * For info regarding why this is a function, see {@link Button.isVisible}
   *
   * @default true
   */
  enabled?: { (): boolean };
  /** What to do when this button is clicked. */
  click: Function;
  /** This controls if this button should have some form of accent color applied if this button is actively doing something. Default is false. */
  isActive?: boolean;
  /** Allows you to enforce configuration capabilities are enabled to allow the header button to be clickable. */
  configDirective?: { path: ConfigDirective["configDisable"]; tooltip: ConfigDirective["configDisabledStateMessage"] };
}

/** This defines the supported typing for buttons within the kebab menu */
export interface KebabButton extends Button {
  /** The title to display for this button */
  title: string;
}

/** This type defines a toggle button that the chart buttons could instead display. */
export interface KebabToggle extends KebabButton {
  icon: "toggle";
  /** If we are of the toggle icon type, this controls the boolean value  */
  toggleStatus: boolean;
}

/**
 * This class functions as a base level functionality holder for buttons to be displayed within the chart base component.
 *
 * This directive is split into two separate button controllers:
 *  1. Header Buttons. These are displayed at the top of the charts to provide single clicks to access functionality like bookmark drawing.
 *  2. Kebab buttons. These buttons are nested within a dropdown that will require two clicks at minimum to reach.
 */
@Directive()
export abstract class ButtonBaseComponent extends ChartStylingComponent {
  /** The chart configuration so we know what to display with our data */
  abstract configuration: ChartConfiguration;
  /** Buttons that are intended to be displayed on the end row of the chart, before the kebab menu */
  headerButtons: Button[] = [];
  /** Buttons that should be displayed in the kebab menu */
  kebabButtons: (KebabButton | KebabToggle)[] = [];

  /** Returns if any kebab buttons will be displayed */
  get kebabMenuEnabled() {
    return (
      this.configuration.showKebabButton && this.kebabButtons.filter((x) => x.isVisible?.call(this) ?? true).length > 0
    );
  }

  /** Fires the functionality when a standard button is clicked. */
  standardButtonClicked(option: Button, _event: MouseEvent) {
    option.click.call(this);
  }

  /** The callback that handles what to do when a kebab button is clicked */
  kebabButtonClicked(option: KebabButton | KebabToggle, event: MatSlideToggleChange | MouseEvent) {
    // Enforce that the option is aligned with the current option and allow the callback to change it
    const typedEvent = event as MatSlideToggleChange;
    if (option.icon === "toggle" && typedEvent?.source?.checked != null)
      typedEvent.source.checked = (option as KebabToggle).toggleStatus;
    else (event as MouseEvent).stopPropagation();
    option.click.call(this);
  }

  /**
   * Given some identifying information, updates the button settings for either the kebab menu or the header buttons
   *
   * @param id The ID of the button to update
   * @param buttonArray The location of the button that you would like to update. Wether it be the kebab menu or the header buttons.
   * @param updatedSettings The new settings to update into the button
   * @returns The updated button option or undefined if one was not located
   */
  updateButtonSettings<T extends Button>(
    id: string,
    buttonArray: CustomTypes.PropertyNames<ButtonBaseComponent, Array<T>>,
    updatedSettings: Partial<T>
  ) {
    const buttonArr = this[buttonArray];
    const index = buttonArr.findIndex((x) => x.id === id);
    if (index === -1) return undefined;
    else Object.assign(buttonArr[index], updatedSettings);
    return buttonArr[index];
  }

  /**
   * Similar to {@link updateButtonSettings} but if the id doesn't exist, it will add it to the respective array.
   *
   * @param settings The settings to add.
   * @param buttonArray The array to add the new setting to.
   * @returns The index of where it was added
   */
  updateOrAddButtonSettings<T extends Button>(
    buttonArray: CustomTypes.PropertyNames<ButtonBaseComponent, Array<Button>>,
    settings: T
  ) {
    const buttonArr = this[buttonArray];
    let index = buttonArr.findIndex((x) => x.id === settings.id);
    if (index === -1) index = buttonArr.push(settings as any);
    else Object.assign(buttonArr[index], settings);
    return index;
  }
}
