<ng-container *ngIf="setting != null"
  ><div class="setting-title">{{ helpfulTitle }}</div>
  <div class="setting-description">
    {{ helpfulDescription }}
  </div>
  <mat-slide-toggle
    class="setting-slide-toggle"
    [ngModel]="asBoolean"
    *ngIf="isBoolean"
    (change)="onSettingUpdated($event.checked)"
  >
  </mat-slide-toggle>
  <mat-form-field appearance="fill" *ngIf="isString">
    <input matInput placeholder="Placeholder" [ngModel]="setting.value" onchange="onSettingChanged" />
  </mat-form-field>
  <mat-form-field appearance="fill" *ngIf="isNumeric">
    <input
      matInput
      type="number"
      placeholder="Placeholder"
      [ngModel]="setting.value"
      (onchange)="onSettingUpdated($event)"
    />
  </mat-form-field>
  <mat-form-field appearance="fill" *ngIf="isColorTheme" color="accent">
    <mat-select [value]="setting.value" (valueChange)="onSettingChanged($event)">
      <mat-option *ngFor="let theme of themeService.colorThemes.value" [value]="theme.name" class="{{ theme.name }}">
        {{ theme.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</ng-container>
