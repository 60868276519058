import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { CommonSettingType } from "@tdms/common";
import { ColorThemeService } from "@tdms/frontend/modules/material/services/themes.service";
import { PluginSettingsService } from "@tdms/frontend/modules/settings/services/settings.service";
import { SettingValue } from "@tdms/frontend/modules/settings/store/value/setting.value.model";
import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";
import { Subscription } from "rxjs";

/**
 * The Settings Field component dynamically creates a form field based on a provided Setting.
 * Supported setting types are:
 * - string
 * - number
 * - boolean
 * - color
 */
@Component({
  selector: "setting-field",
  templateUrl: "./setting.field.component.html",
  styleUrls: ["./setting.field.component.scss"],
})
export class SettingFieldComponent extends SubscribingComponent implements OnChanges {
  @Input() settingName!: string;

  /**
   * The plugin this setting field belongs to
   */
  @Input() correspondingPlugin!: string;

  setting?: SettingValue;
  settingSubscription?: Subscription;

  constructor(public settingService: PluginSettingsService, public themeService: ColorThemeService) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.settingName || changes.correspondingPlugin) {
      if (this.settingSubscription != null) this.cleanupSubscriptions([this.settingSubscription]);
      this.settingSubscription = this.settingService
        .observeSetting(this.correspondingPlugin, this.settingName)
        .subscribe(this.onSettingChanged.bind(this));
    }
  }

  onSettingChanged(setting: SettingValue) {
    this.setting = setting;
  }

  onSettingUpdated(value: any) {
    if (this.setting == null) return;
    this.settingService.updateSpecificSetting({ ...this.setting, value: value }, this.correspondingPlugin);
  }

  get isNumeric(): boolean {
    return this.setting?.settingType == CommonSettingType.number;
  }

  get isString(): boolean {
    return this.setting?.settingType == CommonSettingType.string;
  }

  get isBoolean(): boolean {
    return this.setting?.settingType == CommonSettingType.boolean;
  }

  get isColorTheme(): boolean {
    return this.setting?.settingType == CommonSettingType.colorTheme;
  }

  get helpfulTitle() {
    return this.setting?.helpfulTitle;
  }

  get helpfulDescription() {
    return this.setting?.helpfulDescription;
  }

  get asBoolean(): boolean {
    return this.setting != null && this.setting.value == true;
  }
}
