import { Component } from "@angular/core";
import { BarChartData } from "@tdms/common";
import { ChartDataset, ChartType } from "chart.js/auto";
import { ChartBaseComponent } from "../shared/base/base.component";
import { ExtendedChartOptions } from "../shared/plugins/plugin.typing";

/**
 * A generic bar chart component to use across the application
 */
@Component({
  selector: "charts-bar[data][colorLookup][configuration]",
  templateUrl: "../shared/base/base.component.html",
  styleUrls: ["../shared/base/base.component.scss"],
})
export class BarChartComponent<T extends BarChartData[]> extends ChartBaseComponent<T, "bar"> {
  override chartType: ChartType = "bar";

  override getChartLabels(dataSet: BarChartData[]) {
    return this.filterDataset(dataSet).map((x) => x.name);
  }

  override getChartData(dataSet: T): ChartDataset<"bar", number[]>[] {
    const filteredDataSet = this.filterDataset(dataSet);
    const flattenedData = filteredDataSet.flatMap((x) => x.value);
    const colors = filteredDataSet.flatMap((x) => this.getColor(x.name));
    return [
      {
        label: "Time Talking",
        data: flattenedData,
        backgroundColor: colors,
      },
    ];
  }

  override chartOptionOverrides(): ExtendedChartOptions<"bar"> {
    return {
      plugins: {
        customTooltip: {
          snappingInteractionMode: "point",
        },
      },
    };
  }
}
