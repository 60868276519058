<div class="query-segment-container">
  <ng-container class="segment-container">
    <!-- Current term picker -->
    <data-store-query-term-selector
      [currentOption]="searchOption"
      [availableSearchTerms]="availableOptions"
      [onTermSelected]="onTermChanged"
    >
    </data-store-query-term-selector>

    <!-- Equality type comparison selector -->
    <data-store-comparison-selector
      [option]="searchOption"
      [onComparisonChanged]="onComparisonChanged"
      [tooltip]="searchOption.dataType === 'string' ? 'Text fields can only be compared via equality' : ''"
      [isSearching]="isSearching"
    >
    </data-store-comparison-selector>

    <!-- Check if this is an enforced option set -->
    <ng-container *ngIf="searchOption.options != null && searchOption.options.length > 0; else inputSets">
      <shared-improved-select
        class="option-select"
        appearance="outline"
        [dropdownName]="searchOption.fullName"
        [selectedValue]="optionControl.value"
        [dropdownOptions]="searchOption.options"
        (selectedValueChanged)="onDropdownValueChanged($event)"
        [allowMultipleSelections]="false"
      ></shared-improved-select>
    </ng-container>
    <!-- Display the various inputs -->
    <ng-template #inputSets [ngSwitch]="searchOption.dataType">
      <!-- String -->
      <ng-container *ngSwitchCase="'string'">
        <mat-form-field class="query-form-field" appearance="outline" color="accent">
          <mat-label>{{ searchOption.fullName }}</mat-label>
          <input
            matInput
            type="text"
            [formControl]="optionControl"
            (change)="onStringValueChanged()"
            (keydown.enter)="onStringValueChanged(); searchOptionInputSubmit()"
          />
          <div matSuffix class="date-pick-spacer"></div>
          <!-- Help indicator -->
          <button
            mat-icon-button
            matSuffix
            class="string-help-button"
            (click)="$event.stopPropagation(); openStringHelp()"
          >
            <mat-icon>help</mat-icon>
          </button>
        </mat-form-field>
      </ng-container>

      <!-- Date -->
      <ng-container *ngSwitchCase="'date'">
        <mat-form-field appearance="outline" color="accent">
          <mat-label>{{ searchOption.fullName }}</mat-label>
          <input
            matInput
            readonly
            [matDatepicker]="picker"
            (dateChange)="onDateTimeChanged($event)"
            [formControl]="optionControl"
            (click)="picker.open()"
            (keydown.enter)="onDateTimeChanged($event); searchOptionInputSubmit()"
          />
          <mat-hint>YYYY/MM/DD</mat-hint>
          <mat-datepicker-toggle matIconSuffix matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker [disabled]="isSearching"></mat-datepicker>
        </mat-form-field>
      </ng-container>

      <!-- Number -->
      <ng-container *ngSwitchCase="'number'">
        <mat-form-field appearance="outline" color="accent">
          <mat-label>{{ searchOption.fullName }}</mat-label>
          <input
            matInput
            (change)="onNumberValueChanged()"
            [formControl]="optionControl"
            (keydown.enter)="onNumberValueChanged(); searchOptionInputSubmit()"
          />
          <span matSuffix class="number-unit" *ngIf="searchOption.unit">{{ searchOption.unit }}</span>
        </mat-form-field>
      </ng-container>
    </ng-template>

    <!-- Delete button for this segment -->
    <button mat-raised-button aria-label="Clear" (click)="onDeleteClicked()" class="delete-term-button" color="warn">
      <mat-icon>delete</mat-icon>
    </button>
  </ng-container>
</div>
