import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { AudioModule } from "@tdms/frontend/modules/audio/audio.module";
import { DataStoreModule } from "@tdms/frontend/modules/data-store/data-store.module";
import { AngularMaterialModule } from "@tdms/frontend/modules/material/material.module";
import { MetricsModule } from "@tdms/frontend/modules/metrics/metrics.module";
import { SessionComparisonService } from "@tdms/frontend/modules/session/services/session.comparison.service";
import { SessionService } from "@tdms/frontend/modules/session/services/session.service";
import { SharedModule } from "@tdms/frontend/modules/shared/shared.module";
import { TagModule } from "@tdms/frontend/modules/tag/tag.module";
import { SessionInfoComponent } from "./components/info/info.component";
import { SessionComparisonComponent } from "./components/session-comparison/session-comparison.component";
import { SessionSelectorComponent } from "./components/session-selector/session-selector.component";

/** Components provided by the session module */
const COMPONENTS = [SessionSelectorComponent, SessionComparisonComponent, SessionInfoComponent];

/** A module that provides session capabilities */
@NgModule({
  declarations: COMPONENTS,
  imports: [
    CommonModule,
    SharedModule,
    AngularMaterialModule,
    DataStoreModule,
    FormsModule,
    MetricsModule,
    TagModule,
    AudioModule,
  ],
  providers: [SessionService, SessionComparisonService],
  exports: COMPONENTS,
})
export class SessionModule {}
