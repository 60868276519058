import { Directive, ElementRef, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";

/**
 * This directive applies the ability to observe specific elements resizing. This can be done with the following structure:
 *
 * ```html
 * <canvas resizeDetector (onResizedElement)="myFunction()"></canvas>
 * ```
 *
 * Here we can see we apply this directive with the `resizeDetector` selector. Additionally, whe resizes occur, you can see we use
 *  the `onResizedElement` to call another function.
 */
@Directive({
  selector: "[resizeDetector]",
})
export class ResizeDetectorDirective implements OnInit, OnDestroy {
  /** This is the actual observer object that does the heavy lifting */
  private resizeObserver: ResizeObserver;

  /** When the element this directive is attached to resizes, this will be called. */
  @Output("resizeDetector") resizeDetector = new EventEmitter<void>();

  constructor(private elementRef: ElementRef) {
    this.resizeObserver = new ResizeObserver(() => this.resizeDetector.next());
  }

  ngOnInit() {
    // Observe this element reference as the resize changes
    this.resizeObserver.observe(this.elementRef.nativeElement);
  }

  ngOnDestroy() {
    // Cleanup
    this.resizeObserver.disconnect();
  }
}
