import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { Role } from "@tdms/common";
import { selectSessionSummaryForCurrentSession } from "@tdms/frontend/modules/session-summary/store/summary.selector";
import { SessionSummaryState } from "@tdms/frontend/modules/session-summary/store/summary.state";
import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";

/** The props supported for the feedback component */
export interface FeedbackComponentProps {
  /** The name of the role we want to display feedback info for */
  role: Role;
}

/** This component is used as a dialog to display feedback pieces for the given role */
@Component({
  selector: "session-summary-feedback",
  templateUrl: "./feedback.component.html",
  styleUrls: ["./feedback.component.scss"],
})
export class FeedbackComponent extends SubscribingComponent {
  /** Recommendations for the current role */
  recommendations: string[] = [];
  /** Pros specific to the current roles performance */
  pros: string[] = [];
  /** Cons specific to the current roles performance */
  cons: string[] = [];

  constructor(
    private store: Store<SessionSummaryState>,
    @Inject(MAT_DIALOG_DATA) public properties: FeedbackComponentProps
  ) {
    super();
    this.addSubscription();
    // We need to track this in this component instead of via props incase something changes while the user has the dialog open
    this.store.select(selectSessionSummaryForCurrentSession).subscribe(async (summary) => {
      const role = this.properties.role.name;
      if (summary) {
        const relevantMetrics = summary?.advancedBreakdown.flatMap((x) => x.metrics!).filter((x) => x != null);
        // Get feedback for role
        const feedback = relevantMetrics.flatMap((x) => x.getFeedbackForRole(role)).filter((x) => x);
        this.recommendations = feedback;
        // Get pros/cons
        this.pros = relevantMetrics.flatMap((x) => x.getProsForRole(role));
        this.cons = relevantMetrics.flatMap((x) => x.getConsForRole(role));
      }
    });
  }
}
