import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { DataStoreFile, DataStoreTopics, Session, TDMSWebSocketMessage } from "@tdms/common";
import { WebSocketService } from "@tdms/frontend/modules/communication/services/websocket.service";
import {
  FileEditorDialogComponent,
  FileEditorDialogProperties,
} from "@tdms/frontend/modules/data-store/components/shared/file-editor-dialog/file-editor-dialog.component";
import DataStoreService from "@tdms/frontend/modules/data-store/services/data.store.service";
import { selectAllSessionsFromState } from "@tdms/frontend/modules/session/store/session.selector";
import { SessionState } from "@tdms/frontend/modules/session/store/session.state";
import { Service } from "@tdms/frontend/modules/shared/services/base.service";
import { firstValueFrom, map } from "rxjs";

/**
 * A service that handles editing data store files via popup dialog.
 */
@Injectable({ providedIn: "root" })
export class FileEditService extends Service {
  constructor(
    private dialog: MatDialog,
    private dataStoreService: DataStoreService,
    private wsService: WebSocketService,
    private store: Store<SessionState>
  ) {
    super();
  }

  /**
   * Pop the editor dialog with the available data for modification, then ask the backend to make the user requested changes.
   * @param file The file to edit.
   */
  async userDataStoreEdit(file: DataStoreFile) {
    /// Lookup data for the available plugins, types and sessions the user can select from.
    const availablePlugins = await this.dataStoreService.lookupAvailablePlugins();
    const availablePluginTypes = await this.dataStoreService.lookupAvailablePluginTypes();
    const availableSessions = await firstValueFrom(
      this.store.select(selectAllSessionsFromState).pipe(map((x) => x.map((y) => y.name)))
    );

    const editedFile: DataStoreFile | undefined = await new Promise((resolve, _reject) => {
      const ref = this.dialog.open(FileEditorDialogComponent, {
        disableClose: true,
        data: {
          fileData: {
            sessionName: file.session.name,
            /// Remove the extension so user can't edit that.
            filename: file.fileName.replace(file.extension, ""),
            currentPlugin: file.matchingPlugin,
            currentPluginType: file.pluginType,
          },
          availableSessionNames: availableSessions,
          availablePlugins: availablePlugins,
          availablePluginTypes: availablePluginTypes,
          onFileEdited: (inputs) => {
            /// Add the extension back on after user modifies.
            const newFilename = `${inputs.filename}${file.extension}`;

            if (!availablePlugins.includes(inputs.currentPlugin)) {
              /// Reset the current plugin value if it doesn't exist in the available plugins.
              inputs.currentPlugin = file.matchingPlugin;
            }

            const validPluginTypes = availablePluginTypes[inputs.currentPlugin] ?? [];
            if (validPluginTypes.length == 0) {
              inputs.currentPluginType = undefined;
            } else if (inputs.currentPluginType == null) {
              inputs.currentPluginType = validPluginTypes[0];
            } else if (!validPluginTypes.includes(inputs.currentPluginType)) {
              /// Reset the current plugin type if it doesn't exist in the available plugin types.
              inputs.currentPluginType = validPluginTypes[0];
            }

            ref.close();
            resolve(
              DataStoreFile.fromPlain({
                ...file,
                httpPath: `${file.httpPath.replace(file.fileName, "")}/${newFilename}`,
                matchingPlugin: inputs.currentPlugin,
                pluginType: inputs.currentPluginType,
                session: Session.fromPlain({
                  ...file.session,
                  name: inputs.sessionName,
                }),
              })
            );
          },
          onEditCancelled: () => {
            ref.close();
            resolve(undefined);
          },
        } as FileEditorDialogProperties,
      });
    });

    if (editedFile == null) return;
    return this.editDataStoreFile(editedFile);
  }

  /**
   * Request that the backend modify the data store file using the inputs provided.
   * @param editedFile The modified data store file object to update.
   */
  async editDataStoreFile(editedFile: DataStoreFile) {
    return this.wsService.sendAndReceive(
      new TDMSWebSocketMessage(DataStoreTopics.editFileMetadata, editedFile.session.id, editedFile)
    );
  }
}
