import { BaseError, TDMSBase } from "../../base";
import { Transcription, TranscriptionModelOptionsType } from "./transcription";
import { VADParameters } from "./vad";

/**
 * A class used to define the request to the audio microservice for analyzing audio files
 */
export class AudioAnalyzeRequest extends TDMSBase {
  /**
   * This request identifies how to pass content that the microservice should process. This could be thought of as an array of files or a single file. It has two modes:
   *  1. A single string: This means that we should process this single URL as the request and run the config values below
   *  2. An array of urls and identifiers: This means we should process each file given individually based on config values below, recombine the results, and return it.
   */
  file!:
    | string
    | {
        /** The URL to download the file from */
        url: string;
        /** This identifier will be applied as the speaker identity, assuming VAD isn't run to associate transcription content to this value */
        identifier: string;
      }[];

  /**
   * The JWT to authenticate for the download with
   */
  token!: string;

  /**
   * The model to utilize during the request
   */
  transcriptionModel: TranscriptionModelOptionsType = "medium.en";

  /**
   * If we should attempt to process our audio files utilizing CUDA capabilities, if available.
   *
   * Note: CUDA capabilities do not transcribe multiple files at once since the GPU cache's the transcription so the API is
   *  not flexible enough to support this.
   */
  useGPUIfAvailable: boolean = true;

  /** How many speakers are in the file for diarization. Helps tune the accuracy. */
  diarizeSpeakerCount: number = 3;

  /** If we should send this request to the diarization engine */
  diarize: boolean = false;

  /** If we should send this request to the transcription engine */
  transcribe: boolean = false;

  /** If set to true along side `transcribe`, this will allow us to perform an analysis on the results of a transcription for more metadata. */
  analyze: boolean = true;

  /** If we should send this request to the vad engine */
  vad: boolean = false;

  /** If this request should ignore the cache on the microservice and force a regeneration */
  bypassCache: boolean = false;

  /** The paramaters to control the VAD model invocation (default if empty). */
  vadParams: VADParameters = new VADParameters();
}

/** Class that defines a request to refresh transcription data. Same class will be used for returns. */
export class TranscriptionRefresh extends BaseError {
  audioFileId!: number;
}

/** Class that defines a request to edit transcription data. Same class will be used for the response */
export class TranscriptionEdit extends BaseError {
  transcriptions!: Transcription[];
}
