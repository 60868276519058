<shared-dialog-wrapper
  [header]="
    sessionService.regenerationStatus.value != null
      ? 'Session Regenerating'
      : mode === 'edit'
      ? 'Session Editing'
      : 'Session Creation'
  "
>
  <data-store-uploader-progress
    [shouldDisplayStatus]="!sessionProcessing && sessionService.regenerationStatus.value != null"
    [status]="sessionService.regenerationStatus.value"
    class="info-progress-container"
  >
    <shared-div-progress-component
      [ready]="!sessionProcessing"
      [showSpinner]="sessionProcessing"
      [notReadyMessage]="mode === 'edit' ? 'Editing Session...' : 'Creating Session...'"
    >
      <div class="session-info-wrapper">
        <!-- Header info -->
        <h1 class="header">
          {{
            mode === "create"
              ? "Add your new session information below and click create"
              : "Edit the session below and press save"
          }}
        </h1>
        <!-- Session information form -->
        <form class="session-form" [formGroup]="form">
          <!-- Row 1 -->
          <div class="row">
            <!-- Session Name -->
            <mat-form-field appearance="outline" color="accent">
              <mat-label>Session Name</mat-label>
              <input matInput formControlName="name" (keydown.enter)="submit()" />
              <mat-error *ngIf="form.controls.name.invalid">Session name is invalid</mat-error>
            </mat-form-field>
            <!-- Start Date/Time -->
            <shared-date-time-picker
              [control]="$any(form.get('startDate'))"
              textPrefix="Start"
            ></shared-date-time-picker>
          </div>
          <!-- Row 2 -->
          <div class="row">
            <!-- End Date/Time -->
            <shared-date-time-picker [control]="$any(form.get('endDate'))" textPrefix="End"></shared-date-time-picker>
          </div>
          <!-- Row 3 -->
          <div class="row tag-row">
            <h1 class="tag-edit-header">Tags</h1>
            <tag-display
              [tags]="form.controls['tags'].value"
              [showSelection]="canEdit && true"
              [canRemove]="canEdit && true"
              [canCreate]="canEdit && true"
              [canEdit]="canEdit && true"
              [shouldWrap]="true"
              (selectedTagsChanged)="form.controls['tags'].setValue($event)"
            ></tag-display>
          </div>
        </form>
        <!-- Button row -->
        <div class="button-row row">
          <div class="left">
            <button
              *ngIf="mode === 'edit'"
              mat-raised-button
              (click)="selectSession()"
              color="accent"
              [matTooltip]="
                sessionService.currentSession?.equals(session)
                  ? 'This is already the active session'
                  : 'Sets this as the active session'
              "
              class="select-session"
              [disabled]="sessionService.currentSession?.equals(session)"
            >
              Select Session
            </button>
          </div>
          <div class="right">
            <!-- Regenerate capabilities -->
            <shared-div-progress-component
              [ready]="sessionService.currentSession?.isProcessing !== true"
              [spinnerDiameter]="20"
              class="regen-button-spinner"
            >
              <button
                mat-raised-button
                (click)="sessionService.regenerateMetricData()"
                [matTooltip]="
                  creationSessionFile == null
                    ? 'No session creation file associated to this session'
                    : 'Regenerates this sessions metric data based on the original session creation file. Opens a dialog that explains more.'
                "
                color="accent"
                *ngIf="mode === 'edit'"
                [configDisable]="'session.allowRegeneration'"
                configDisabledStateMessage="Regeneration is disabled"
                [disabled]="creationSessionFile == null"
              >
                Regenerate
              </button>
            </shared-div-progress-component>
            <button
              mat-raised-button
              color="accent"
              [matTooltip]="
                !canEditRoleMappings
                  ? 'Role editing is disabled at this time.'
                  : session.isDemo
                  ? 'Demo session roles cannot be edited.'
                  : 'This is used to edit role mappings for a session after the session has been created.'
              "
              [configDisable]="'session.allowRoleMapEditing'"
              [disabled]="!canEditRoleMappings || session.isDemo"
              (click)="openRoleMapping(session)"
            >
              Edit Role Mapping
            </button>
            <!-- Save/create/shenanigans -->
            <button
              mat-raised-button
              (click)="submit()"
              color="accent"
              [disabled]="!form.valid || !editValuesHaveChanged"
              [matTooltip]="
                !form.valid
                  ? 'Please fill in all required inputs'
                  : !editValuesHaveChanged
                  ? 'No values have changed'
                  : ''
              "
              [configDisable]="'session.allowEditing'"
              configDisabledStateMessage="Session editing is disabled"
            >
              {{ mode === "edit" ? "Save" : "Create" }}
            </button>
          </div>
        </div>
      </div>
    </shared-div-progress-component>
  </data-store-uploader-progress>
</shared-dialog-wrapper>
