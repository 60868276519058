import { Type } from "class-transformer";
import "reflect-metadata";
import { decorate } from "ts-mixer";
import { TDMSBase } from "../base";
import { Bookmark } from "../charts/bookmark";
import { Tag } from "../tag/tag";
import { Role } from "./role";

/**
 * This class defines a session containing the training data
 */
export class Session extends TDMSBase {
  /**
   * A unique session identifier
   */
  id: number;

  /**
   * The non unique name of this session
   */
  name: string;

  /**
   * The date of the session start
   */
  @decorate(Type(() => Date))
  startDate: Date;

  /**
   * The date of the session end
   */
  @decorate(Type(() => Date))
  endDate: Date;

  /**
   * The data roles set associated to this session
   */
  @decorate(Type(() => Role))
  roles: Role[];

  /**
   * Bookmarks associated to this session.
   */
  @decorate(Type(() => Bookmark))
  bookmarks: Bookmark[] | undefined;

  /**
   * This boolean tracks if this sessions is still being processed so the endpoints can ignore requests against this session until the
   *  processing is complete.
   */
  isProcessing: boolean = false;

  /** Tags associated to this session*/
  @decorate(Type(() => Tag))
  tags: Tag[] = [];

  /** Tracks if this session is a demo session not associated to real data or not */
  isDemo: boolean = false;

  /**
   * Given an index, converts this session to a format of `Session A`, `Session B`, etc.
   */
  getOrderedName(index: number) {
    return `Session ${(index + 10).toString(36).toUpperCase()}`;
  }

  /** Returns the duration of this session, in seconds, inferred from the start and end times */
  get duration() {
    return (this.endDate.getTime() - this.startDate.getTime()) / 1000;
  }

  constructor(
    id: number | undefined,
    roles: Role[],
    name: string,
    startDate = new Date(),
    endDate = new Date(new Date().getTime() + 300000),
    bookmarks?: Bookmark[]
  ) {
    super();
    this.id = id!;
    this.name = name;
    this.roles = roles;
    this.startDate = startDate;
    this.endDate = endDate;
    this.bookmarks = bookmarks;
  }

  /**
   * Get's currently enabled roles
   */
  get enabledRoles() {
    return this.roles?.filter((x) => x.displayed) || [];
  }

  /** Validates the data of `this` is good and throws an error if it's not */
  validate() {
    // Verify times are good
    if (this.startDate.getTime() === this.endDate.getTime() || this.startDate.getTime() > this.endDate.getTime())
      throw new Error("Invalid session times");
  }
}
