import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { ChartConfiguration, filterNulls, Session, Transcription, WaveformChartData } from "@tdms/common";
import AudioService from "@tdms/frontend/modules/audio/services/audio.service";
import { selectAllTranscriptionsFromState } from "@tdms/frontend/modules/audio/store/transcription.selector";
import { TranscriptionState } from "@tdms/frontend/modules/audio/store/transcription.state";
import { WaveformChartComponent } from "@tdms/frontend/modules/charts/waveform/waveform.component";
import { ColorThemeService } from "@tdms/frontend/modules/material/services/themes.service";
import { MetricServiceDataStore } from "@tdms/frontend/modules/metrics/components/metric-card/models/metric.configuration";
import { MetricActions } from "@tdms/frontend/modules/metrics/store/metric.action";
import { ConfigService } from "@tdms/frontend/modules/settings/services/config.service";
import { Service } from "@tdms/frontend/modules/shared/services/base.service";
import { combineLatest, from, map, mergeMap, Subscription } from "rxjs";

/**
 * Service for generating metrics based on audio content included the playback file and transcriptions.
 */
@Injectable({ providedIn: "root" })
export default class AudioMetricService extends Service {
  /** Configuration for the waveform chart */
  static readonly WAVEFORM_CHART_CONFIG = new ChartConfiguration({
    type: "waveform",
    metricName: "Audio",
    title: "Audio Waveform",
    getBySessionIdQueue: "total-files-by-plugin",
    position: 0,
    exportAllowed: false,
    shouldBeUsedForTimeline: true,
    xAxisFormat: "timeFormatter",
    showXAxis: false,
    displayLegend: false,
    showBookmark: true,
  });

  /** Tracks the current subscription observing data */
  currentBufferSubscription: Subscription | undefined;

  constructor(
    private store: Store<TranscriptionState>,
    private audioService: AudioService,
    private themeService: ColorThemeService,
    private configService: ConfigService
  ) {
    super();
  }

  override async onSessionChanged(session?: Session) {
    if (session && this.configService.configData?.audio.enabled && this.configService.configData.dataStore.enabled) {
      this.currentBufferSubscription?.unsubscribe();
      this.createChartDataPopulator(session);
    }
  }

  override async onBackendDisconnected(): Promise<void> {
    this.currentBufferSubscription?.unsubscribe();
  }

  /**
   * Creates a set of observables and subscriptions to automatically populate the chart data store with
   *   waveform data so it can be later rendered.
   */
  createChartDataPopulator(session: Session, config = AudioMetricService.WAVEFORM_CHART_CONFIG) {
    // Setup default data
    this.store.dispatch(
      MetricActions.updateMetricData({
        sessionId: session.id,
        metricName: config.metricName,
        data: { isCalculating: true },
      })
    );
    this.currentBufferSubscription = this.observeBufferData(session).subscribe((calc) => {
      this.store.dispatch(
        MetricActions.updateMetricData({
          sessionId: session.id,
          metricName: config.metricName,
          data: MetricServiceDataStore.fromPlain({
            isCalculating: false,
            data: calc?.data,
          }),
        })
      );
    });
  }

  /** Returns an observable that is created based on the buffer data and transcripts */
  private observeBufferData(session: Session) {
    return combineLatest([
      this.store.select(selectAllTranscriptionsFromState).pipe(map((z) => Transcription.fromPlainArray(z))),
      this.audioService.audioBlob.pipe(
        filterNulls,
        mergeMap((x) => from(this.getAudioBuffer(x.blob)))
      ),
    ]).pipe(
      map((data) => {
        const transcriptions = data[0];
        const buffer = data[1];
        // Handle buffer data
        if (buffer) return { data: this.getChartDataFromBuffer(buffer, session, transcriptions), buffer };
        else return undefined;
      })
    );
  }

  /** Returns the audio buffer from the current loaded audio element */
  async getAudioBuffer(audioBlob: Blob) {
    try {
      // Manually parse context to deal with uncaught in promise from multi track audio errors
      return await new AudioContext().decodeAudioData(await audioBlob.arrayBuffer());
    } catch (e) {
      this.audioService.loadFailureMessage = "Failed to load audio file buffer";
      this.audioService.extendedFailureMessage = `${(e as Error).message}`;
      return undefined;
    }
  }

  /** Given an audio buffer, determines the chart data and processes it */
  getChartDataFromBuffer(buff: AudioBuffer, session: Session, transcriptions: Transcription[]) {
    const unknownSpeaker = "Unknown";
    const fileDuration = buff.duration;
    const bufferData = WaveformChartComponent.bufferToChartData(buff, session.startDate, fileDuration);
    // Create separated data so we can easily conform each waveform data into their own color sets
    const data = Transcription.getUniqueSpeakers(transcriptions).map((x) => new WaveformChartData(x, []));
    // If no speaker length, means the speakers were not loaded yet. Add a default.
    if (data.length === 0) data.push(new WaveformChartData(unknownSpeaker, []));
    // Iterate over every buffer data and assign to appropriate one and non appropriates
    bufferData.map((z) => {
      // Find any matching speaker
      let speaker = transcriptions.find((x) => x.start < z.seconds && x.end > z.seconds)?.prettySpeaker;
      // Push to matching speaker. There could be a case here that if a matching speaker doesn't exist, this value may not be appropriately tracked.
      if (speaker) {
        const match = z.clone();
        match.color = this.themeService.getColorForString(speaker);
        data.find((x) => x.name === speaker)?.series.push(match);
      } else {
        const match = z.clone();
        match.color = this.themeService.getColorForString(unknownSpeaker);
        data.find((x) => x.name === unknownSpeaker)?.series.push(match);
      }
      // Push to everyone else with a 0 value so the time series are lined up
      z.value = 0; // Set value to 0 so speaker data is unset
      data
        .filter((x) => x.name !== speaker && x.name !== unknownSpeaker)
        .map((v) => {
          const clone = z.clone();
          clone.color = this.themeService.getColorForString(v.name);
          v.series.push(clone);
        });
    });
    return data;
  }
}
