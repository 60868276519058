<div class="overview-container" #overviewContainer [ngClass]="{ 'has-scrollbar': elementHasScrollbar }">
  <!-- First row -->
  <div class="row top">
    <!-- Session details card -->
    <session-summary-card
      header="Session Details"
      [shouldShowSpinner]="sessionMetadata == null"
      [dataAvailable]="sessionMetadata != null"
    >
      <div class="session-details-contents">
        <!-- Render our singular session score metric -->
        <metric-grid [dataStore]="currentMetricDataStore!" class="metric-grid"></metric-grid>
        <!-- Render the session details -->
        <div *ngIf="sessionMetadata" class="details">
          <span class="data">
            <span class="title">Duration:</span>
            <span class="data-element">{{ sessionMetadata.duration }}</span>
          </span>
          <span class="data">
            <span class="title">Date:</span>
            <span class="data-element">{{ sessionMetadata.startDate }}</span>
          </span>
          <span class="data" *ngVar="sessionMetadata.hasVideos as hasData">
            <span class="title">Video:</span>
            <mat-icon class="icon" [ngClass]="{hasData}">{{ hasData ? "check" : "close" }}</mat-icon>
          </span>
          <span class="data" *ngVar="sessionMetadata.hasAudio as hasData">
            <span class="title">Audio:</span>
            <mat-icon class="icon" [ngClass]="{hasData}">{{ hasData ? "check" : "close" }}</mat-icon>
          </span>
          <span class="data" *ngVar="sessionMetadata.hasTranscript as hasData">
            <span class="title">Transcriptions:</span>
            <mat-icon class="icon" [ngClass]="{hasData}">{{ hasData ? "check" : "close" }}</mat-icon>
          </span>
        </div>
      </div>
    </session-summary-card>
    <!-- Session score breakdown card -->
    <session-summary-card
      header="Session Score Breakdown"
      [shouldShowSpinner]="currentSessionSummary == null"
      [dataAvailable]="currentSessionSummary?.sessionScore != null"
    >
      <session-summary-pros-cons [pros]="overallPros" [cons]="overallCons"></session-summary-pros-cons>
    </session-summary-card>
  </div>
  <!-- Second row -->
  <div class="row bottom">
    <!-- Constructive Feedback -->
    <session-summary-card
      header="Constructive Feedback"
      [shouldShowSpinner]="currentSessionSummary == null"
      [dataAvailable]="currentSessionSummary?.sessionScore != null && teamFeedback != null"
    >
      <div class="feedback-container">
        <!-- Render overall team feedback -->
        <div class="team overall-group">
          <div class="header">
            <span></span>
            <span>Team</span>
            <span><mat-icon class="team-help">help</mat-icon></span>
          </div>
          <div class="items">
            <ul>
              <li *ngFor="let item of teamFeedback" class="item">{{ item }}</li>
              <li *ngIf="teamFeedback?.length === 0" class="item">No feedback available</li>
            </ul>
          </div>
        </div>
        <!-- Speakers are rendered separately for wrapping -->
        <div class="speaker-container">
          <!-- Render each set of constructive feedback per speaker -->
          <div class="speakers overall-group" *ngFor="let role of currentSession?.roles" (click)="openRoleDialog(role)">
            <div class="header" [ngStyle]="{ color: themeService.getColorForString(role.name) }">
              <span></span>
              <span>{{ role.name }}</span>
              <span>
                <mat-icon matTooltip="Clicking this box will open additional information" matTooltipPosition="above">
                  help
                </mat-icon>
              </span>
            </div>
            <div class="items" *ngIf="individualFeedback[role.name]">
              <ul>
                <li
                  class="item"
                  *ngFor="let feedback of individualFeedback[role.name]; index as i"
                  [ngClass]="{ hidden: i > 1 }"
                >
                  {{ feedback }}
                </li>
                <li *ngIf="individualFeedback[role.name].length > 2" class="hidden-items item">
                  +{{ individualFeedback[role.name].length - 2 }} (Click to see more)
                </li>
                <li class="item" *ngIf="individualFeedback[role.name].length === 0">No feedback available</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </session-summary-card>
  </div>
</div>
