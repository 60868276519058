import { Component, Input, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { Bookmark, BookmarkTypeEnum } from "@tdms/common";
import AudioMetricService from "@tdms/frontend/modules/audio/services/audio.metric.service";
import AudioService from "@tdms/frontend/modules/audio/services/audio.service";
import { TranscriptionState } from "@tdms/frontend/modules/audio/store/transcription.state";
import { ZoomDomainUpdateEmitter } from "@tdms/frontend/modules/charts/timeline/timeline.component";
import { ColorThemeService } from "@tdms/frontend/modules/material/services/themes.service";
import { MetricGridDataStore } from "@tdms/frontend/modules/metrics/components/metric-grid/models/metric-grid.configuration";
import { MetricGridService } from "@tdms/frontend/modules/metrics/services/metric.grid.service";
import { selectSpecificMetricForCurrentSession } from "@tdms/frontend/modules/metrics/store/metric.selector";
import autoBind from "auto-bind";
import { cloneDeep } from "lodash-es";
import { combineLatest, map, Observable } from "rxjs";

@Component({
  selector: "audio-waveform[playbackBookmarkObservable]",
  templateUrl: "./audio-waveform.component.html",
  styleUrls: ["./audio-waveform.component.scss"],
})
export class AudioWaveformComponent implements OnInit {
  /** Chart data for the waveform */
  metricGrid: MetricGridDataStore | undefined;

  @Input()
  playbackBookmarkObservable!: Observable<Bookmark[]>;

  @Input() zoomDomainUpdater!: ZoomDomainUpdateEmitter;

  @Input() titleOverride?: string;

  @Input() isTimeline: boolean = false;

  constructor(
    private store: Store<TranscriptionState>,
    public audioService: AudioService,
    public themeService: ColorThemeService,
    private metricGridService: MetricGridService
  ) {
    autoBind(this);
  }

  ngOnInit(): void {
    // Populate the metric
    this.createMetricConfigs();
  }

  /** Utilizing the comm frequency plugin, grab's communication step on bookmarks that exist */
  private getSteponBookmarks() {
    return this.store
      .select(selectSpecificMetricForCurrentSession("CommFrequency"))
      .pipe(map((x) => x?.bookmarks.filter((z) => z.bookmarkType.id === BookmarkTypeEnum.StepOn) ?? []));
  }

  /** Creates the metric card information to display charts based on our audio file */
  async createMetricConfigs(showSteponBookmarks = false) {
    let bookmarkObservable: Observable<Bookmark[]> = this.playbackBookmarkObservable;

    // If we want step on bookmarks, try and grab them
    if (showSteponBookmarks) {
      bookmarkObservable = combineLatest([this.getSteponBookmarks(), this.playbackBookmarkObservable]).pipe(
        map((x) => [...x[0], ...x[1]])
      );
    }

    const config = cloneDeep(AudioMetricService.WAVEFORM_CHART_CONFIG);
    if (this.titleOverride != null) {
      config.title = this.titleOverride;
    }

    // Override some options when the waveform is used as a timeline
    if (this.isTimeline) {
      config.xAxisFormat = "timeFormatter";
      config.showXAxisLabel = false;
      config.isSelectedTimeline = true;
    }

    // Create the grid observable
    this.metricGrid = await this.metricGridService.observeMetricDataForSession(
      [config],
      undefined,
      bookmarkObservable,
      false
    );
  }
}
