<div class="waveform-container" #waveformContainer>
  <audio-waveform
    [playbackBookmarkObservable]="playbackBookmarkObservable"
    titleOverride="Timeline"
    [zoomDomainUpdater]="zoomDomainUpdater"
    [isTimeline]="true"
  ></audio-waveform>
</div>
<shared-div-progress-component [ready]="!loadingTranscriptionData" notReadyMessage="Loading transcriptions...">
  <transcription-playback
    [ngStyle]="{ visibility: loadingTranscriptionData ? 'hidden' : 'inherit' }"
    [domainUpdate]="zoomDomainUpdater"
    [theme]="applicationTheme"
    [playbackBookmarkObservable]="playbackBookmarkObservable"
    [safeSpaceObservable]="safeSpace"
    [timeDomain]="timeDomain"
    [transcriptions]="transcriptions"
    class="transcription-playback"
    [currentPlaybackObservable]="currentPlaybackTime"
    [speakers]="speakers"
    [colorLookup]="colorLookup"
  ></transcription-playback>
</shared-div-progress-component>
<div class="bottom-row-container">
  <shared-audio-player
    #audioPlayer
    [src]="audioService.audioBlob.value?.url"
    displayType="small"
    class="audio-player"
    [displayPlaybackState]="true"
  ></shared-audio-player>
  <div class="legend-container">
    <charts-custom-legend
      *ngIf="legend && legend.length > 0"
      [values]="legend!"
      [clickCallback]="customLegendClickCallback!"
      [colorGetter]="getColor.bind(this)"
    ></charts-custom-legend>
  </div>
  <!-- Flex placeholder -->
  <div class="placeholder"></div>
</div>
