<shared-dialog-wrapper [header]="properties.role.name + ' - Results'" class="feedback-dialog">
  <!-- Render pros/cons for the specific role -->
  <session-summary-pros-cons [pros]="pros" [cons]="cons"></session-summary-pros-cons>
  <!-- Render the overall recommendations for this specific role -->
  <div class="recommendations">
    <div class="header">Recommendations</div>
    <ul>
      <li class="recommendation" *ngFor="let recommendation of recommendations">{{ recommendation }}</li>
      <li class="recommendation" *ngIf="recommendations.length === 0">No feedback available</li>
    </ul>
  </div>
</shared-dialog-wrapper>
