<div class="metric-grid-outer" #chartContainer *ngIf="dataStore != null">
  <!-- Render the overarching session timeline -->
  <div class="sticky" [ngClass]="{ 'has-content': displayTimeline }">
    <metric-card
      [dataStore]="timelineCard!"
      [graphTitleStart]="getPrettySessionStart()"
      [graphTitleEnd]="getPrettySessionStop()"
      [zoomDomainUpdater]="zoomDomainUpdater"
      [gridLevelBookmarks]="gridLevelBookmarks"
      [bookmarkIsDrawing]="bookmarkIsDrawing"
      [bookmarkTypes]="bookmarkTypes"
      [bookmarkDrawingStatus]="bookmarkDrawingStatus"
      [onAddBookmarkClicked]="onAddBookmarkClicked"
      [onUpdateBookmarkClicked]="onUpdateBookmarkClicked"
      [isTimeline]="true"
      *ngIf="displayTimeline"
      class="timeline-card"
      [applicationTheme]="currentApplicationTheme"
      [isSessionComparison]="isSessionComparison"
      [totalChartTimeFrame]="[gridStartDate, gridEndDate]"
    ></metric-card>
  </div>

  <!-- Render the main grid of metric cards -->
  <div class="metric-grid-inner" #chartInnerContainer>
    <!-- Render each individual card -->
    <ng-container *ngFor="let chart of currentCards; let i = index; trackBy: getElementTracker">
      <metric-card
        *ngIf="chart.lastEnabledStatus"
        [dataStore]="chart"
        [getExportPrefix]="getExportPrefix"
        [gridLevelBookmarks]="gridLevelBookmarks"
        [bookmarkIsDrawing]="bookmarkIsDrawing"
        [bookmarkTypes]="bookmarkTypes"
        [bookmarkDrawingStatus]="bookmarkDrawingStatus"
        [onAddBookmarkClicked]="onAddBookmarkClicked"
        [onUpdateBookmarkClicked]="onUpdateBookmarkClicked"
        [zoomDomainUpdater]="zoomDomainUpdater"
        [graphTitleStart]="getPrettySessionStart()"
        [graphTitleEnd]="getPrettySessionStop()"
        [isTimeline]="false"
        [ngClass]="{
          'has-scrollbar': elementHasScrollbar,
          half: chart!.configuration.sizing === 'half',
          'has-next-half': hasNextHalf(i),
          'no-data': chart.metric?.dataIsNotAvailable
        }"
        [applicationTheme]="currentApplicationTheme"
        [isSessionComparison]="isSessionComparison"
        [totalChartTimeFrame]="[gridStartDate, gridEndDate]"
      >
      </metric-card>
    </ng-container>
  </div>
</div>
