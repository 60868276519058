import { Directive, Input, OnChanges } from "@angular/core";
import { Bookmark, BookmarkType, TimeSeriesChartBase } from "@tdms/common";
import {
  BookmarkDialogComponent,
  BookmarkDialogProperties,
  BookmarkMoveModes,
} from "@tdms/frontend/modules/bookmark/components/dialog/bookmark-dialog.component";
import { ColorMap } from "@tdms/frontend/modules/material/services/themes.service";
import { DialogWrapperComponent } from "@tdms/frontend/modules/shared/components";
import { AngularCustomTypes } from "@tdms/frontend/modules/shared/models/angular.custom.types";
import { ChartOptions } from "chart.js";
import { cloneDeep, merge } from "lodash-es";
import { BehaviorSubject } from "rxjs";
import { LegendDisplay } from "../legend/models/legend.display";
import { BookmarkDrawOptions } from "../plugins/bookmark";
import { BookmarkLegendObject } from "../plugins/bookmark.legend";
import { LabelObject } from "../plugins/drawing.helper";
import { ExtendedChartOptions } from "../plugins/plugin.typing";
import { TimelineChartBaseComponent } from "../timeline-base/timeline.base.component";

/**
 * A class to add additional information to bookmark displays
 */
export type BookmarkDisplay = {
  /** A title to display for this section of bookmarks */
  headerTitle?: string;
  bookmarks?: Bookmark[];
};

/**
 * A base layer component that allows bookmark functionality into time series charts that can display them.
 */
@Directive({ selector: "charts-bookmark-base[colorLookup][configuration][data]" })
export abstract class BookmarkChartBaseComponent<
    ChartDataType extends TimeSeriesChartBase<any>,
    InternalChartType extends "line" | "bar"
  >
  extends TimelineChartBaseComponent<ChartDataType, InternalChartType>
  implements OnChanges
{
  override ngOnInit(): void {
    super.ngOnInit();
    // Add bookmark creation button
    this.headerButtons.unshift(
      // Cancel active drawing
      {
        id: "cancel-bookmark-button",
        icon: "cancel",
        tooltip: "Cancel Bookmark Drawing",
        isVisible: () => this.currentDrawingBookmark != null,
        click: this.bookmarkCancelDrawing,
        isActive: true,
      },
      {
        id: "add-bookmark-button",
        icon: "add",
        tooltip: this.bookmarkTooltip,
        isVisible: () =>
          this.currentDrawingBookmark == null &&
          this.configuration?.showBookmark &&
          !this.dataIsNotAvailable &&
          (!this.isSessionComparison ||
            (this.isSessionComparison &&
              this.configuration?.comparison != null &&
              this.configuration?.comparison.enabled &&
              this.configuration?.comparison.showBookmarkAdd)) &&
          this.bookmarkAdd != null,
        enabled: () => this.bookmarksDisplayed && !this.bookmarkAddDisabled,
        click: this.openBookmarkDialog,
        configDirective: { path: ["bookmark.allowCreation"], tooltip: "Bookmark creation is disabled" },
      }
    );
  }

  override ngOnChanges(changes: AngularCustomTypes.BaseChangeTracker<BookmarkChartBaseComponent<any, any>>): void {
    super.ngOnChanges(changes);
    if (changes.bookmarksDisplayed) {
      this.updateButtonSettings("add-bookmark-button", "headerButtons", {
        tooltip: this.bookmarkTooltip,
      });
      this.updateChartOptions();
      this.currentChart?.update("none");
    }
  }

  /**
   * Bookmarks to render on this chart
   */
  @Input() bookmarks: Bookmark[] | undefined;

  /**
   * A function to call after a bookmark has been completed drawing and requested to be added.
   */
  @Input() bookmarkAdd: { (bookmark: Bookmark): void } | undefined;

  /**
   * After a bookmark has been moved or updated, this function will be called with that bookmark
   */
  @Input() bookmarkUpdate: { (bookmark: Bookmark): void } | undefined;

  /**
   * An override to disable the bookmark add button
   */
  @Input() bookmarkAddDisabled = false;

  /**
   * Whether bookmarks should be displayed for this chart or not.
   */
  @Input() bookmarksDisplayed = false;

  @Input() bookmarkTooltip: string = "Bookmark Creation";

  /**
   * Tracks if we are actively drawing a bookmark on this chart or not, by keeping a reference
   *  to the bookmark we wish to draw.
   */
  currentDrawingBookmark: Bookmark | undefined;

  /**
   * The type of bookmark drawing occurring for `currentDrawingBookmark`
   */
  bookmarkDrawMode: BookmarkDrawOptions | undefined = undefined;

  /**
   * An event emitter to help inform charts if a bookmark is being drawn on another chart so they can't
   *  draw multiple at a time
   */
  @Input() bookmarkDrawingStatus: BehaviorSubject<boolean> | undefined;

  /**
   * Tracks whatever bookmark is currently being hovered on
   */
  hoveredBookmark: Bookmark | undefined;

  /**
   * An array of bookmark types so we can generate bookmarks on the fly additionally if we want to.
   */
  @Input() bookmarkTypes: BookmarkType[] = [];

  /**
   * Given a bookmark, returns it's expected color
   */
  static getBookmarkColor(bookmark: Bookmark, colors: ColorMap | undefined) {
    // Return color if associated role mapping exists
    if (colors) {
      let matchingColor = colors.find((mapping) => mapping.name === bookmark.associatedRoleName);
      if (bookmark.autoAdjustColor && matchingColor) return matchingColor.value;
    }
    // Else return standard
    return bookmark.bookmarkType.color;
  }

  /**
   * Base level functionality to request the chart to start drawing a bookmark
   */
  bookmarkDrawStart(bookmark: Bookmark) {
    if (this.bookmarksDisplayed) {
      this.currentDrawingBookmark = bookmark;
      this.bookmarkDrawingStatus?.next(true);
      this.updateChartOptions();
      this.currentChart!.update("none");
    }
  }

  /**
   * Given a bookmark, this function starts drawing it on the current chart. You'll
   *  want to use this for new bookmarks.
   */
  startDrawingNewBookmark(partialBookmark: Bookmark, isRange: boolean) {
    if (this.bookmarksDisplayed) {
      this.bookmarkDrawMode = isRange ? "draw-new-range" : "draw-new";
      this.bookmarkDrawStart(partialBookmark);
    }
  }

  /**
   * Given an existing bookmark, start's moving it's positioning.
   */
  startMovingExistingBookmark(bookmark: Bookmark, rangeMode: BookmarkMoveModes) {
    if (this.bookmarksDisplayed) {
      // Set our drawing mode and start the draw with the vertical line plugin
      if (rangeMode === "moveAll") {
        this.bookmarkDrawMode = "move-range";
        bookmark.startTime = undefined!;
        bookmark.endTime = undefined;
      } else if (rangeMode === "moveStart") this.bookmarkDrawMode = "move-start-range";
      else if (rangeMode === "moveEnd") this.bookmarkDrawMode = "move-end-range";
      else this.bookmarkDrawMode = "move-singular";
      this.bookmarkDrawStart(bookmark);
    }
  }

  /**
   * This function is called whenever a bookmark drawing has a status update. This could be either a complete draw finish
   *  from a singular bookmark or a singular value for a range that has been added
   */
  bookmarkDrawProgress(val: number, currentBookmark = this.currentDrawingBookmark, drawMode = this.bookmarkDrawMode) {
    /** If we are adding a new bookmark, no matter the mode */
    const isAdd = drawMode === "draw-new" || drawMode === "draw-new-range";
    /** If this is range value manipulation */
    // prettier-ignore
    const isRange = drawMode === "draw-new-range" || drawMode === "move-start-range" || drawMode === "move-end-range" || drawMode === "move-range"
    /** If this is the start value being edited */
    const isStartVal = currentBookmark?.startTime == null || !isRange || drawMode === "move-start-range";
    if (currentBookmark) {
      const valDate = new Date(val);

      // Update date val
      if (isStartVal) {
        currentBookmark.startTime = valDate;
        // If this is a range, return as we will need the end value
        if (isRange && currentBookmark.endTime == null) return;
      } else currentBookmark.endTime = valDate;

      // Invert drawing if they are backwards
      if (currentBookmark.endTime)
        if (currentBookmark.startTime.getTime() > currentBookmark.endTime.getTime()) {
          const temp = currentBookmark.startTime;
          currentBookmark.startTime = currentBookmark.endTime;
          currentBookmark.endTime = temp;
        }

      // Execute callback
      if (isAdd) this.bookmarkAdd?.call(this, currentBookmark);
      else this.bookmarkUpdate?.call(this, currentBookmark);
      // Wrap it up
      this.bookmarkCancelDrawing();
    }
  }

  /**
   * This function handles canceling the drawing if called and a drawing is occurring
   */
  bookmarkCancelDrawing() {
    if (this.currentDrawingBookmark) {
      this.currentDrawingBookmark = undefined;
      this.bookmarkDrawMode = undefined;
      this.bookmarkDrawingStatus?.next(false);
      if (this.currentChart) {
        this.updateChartOptions();
        this.currentChart.update("none");
      }
    }
  }

  /**
   * Opens a dialog menu to display the bookmark creation modal
   */
  openBookmarkDialog(existingBookmark?: Bookmark) {
    if (!this.currentDrawingBookmark)
      this.dialog.open(BookmarkDialogComponent, {
        data: {
          newBookmark: this.startDrawingNewBookmark.bind(this),
          bookmarkMoveStart: this.startMovingExistingBookmark.bind(this),
          existingBookmark: existingBookmark,
          editable: !this.isSessionComparison,
          deletable: !this.isSessionComparison,
        } as BookmarkDialogProperties,
        ...DialogWrapperComponent.getDefaultOptions(),
      });
  }

  override shouldChartUpdate(
    changes: AngularCustomTypes.BaseChangeTracker<BookmarkChartBaseComponent<any, any>>,
    checkDomain = true
  ) {
    let shouldUpdate = super.shouldChartUpdate(changes, checkDomain);
    if (!this.isCalculating) {
      // Check for bookmark changes
      if (changes.bookmarks) {
        this.updateChartOptions();
        if (shouldUpdate !== "chart" && shouldUpdate !== "plugins") shouldUpdate = "plugins";
      }
    }
    return shouldUpdate;
  }

  /**
   * Returns bookmarks with adjusted colors to match role names and filters out any
   *  directly related to roles that are not currently enabled
   */
  private getFilteredBookmarks(bookmarks?: Bookmark[]) {
    const flatLegend = LegendDisplay.flattenOptions(this.customLegendValues);
    return bookmarks
      ?.filter((bookmark) => {
        // Don't include the current drawing bookmark
        if (bookmark.equals(this.currentDrawingBookmark)) return false;
        else if (bookmark.associatedRoleName) {
          // If we have no legend, just return true
          if (flatLegend.length === 0) return true;
          // Validate that this option is enabled if it exists. If no match exists, also return true
          const matchingLegendValue = flatLegend.find((x) => x.name === bookmark.associatedRoleName);
          if (matchingLegendValue) return matchingLegendValue.displayed;
          else return true;
        } else return true;
      })
      .map((bookmark) => {
        // Fix an issue from store giving non mutable objects
        bookmark = cloneDeep(bookmark);
        // Setup colors
        bookmark.bookmarkType.color = BookmarkChartBaseComponent.getBookmarkColor(bookmark, this.colorLookup);
        return bookmark;
      });
  }

  /**
   * Given some bookmarks, determines if horizontal labels should be rendered
   */
  protected shouldRenderHorizontalLabels(currentBookmarks?: Bookmark[]) {
    const horizontalBookmarkCount =
      currentBookmarks?.filter((x) => x.bookmarkType.renderStyle === "horizontal").length || 0;
    return horizontalBookmarkCount <= 1;
  }

  /**
   * Returns the bookmarks to be rendered.
   */
  protected getBookmarks(): BookmarkDisplay[] {
    /// Don't send any bookmarks up if they are disabled for this chart.
    if (!this.bookmarksDisplayed)
      return [
        {
          bookmarks: this.bookmarks?.filter((x) => x.bookmarkType.shouldAlwaysDisplay && x.bookmarkType.shouldDisplay),
        },
      ];
    return [{ bookmarks: this.bookmarks }];
  }

  /**
   * Based on the sets of bookmarks, returns the sets and subsections to render
   */
  protected getLegendBookmarks(filteredBookmarkSets: BookmarkDisplay[]) {
    return filteredBookmarkSets.flatMap((x) => {
      // Bookmarks to render
      const matchingBookmarks = x.bookmarks?.filter((z) => z.bookmarkType.shouldRenderOnLegend && z.value != null);
      return BookmarkLegendObject.fromPlain({
        header: x.headerTitle,
        legendElements: matchingBookmarks?.map(
          (x) =>
            ({
              text: `${x.associatedRoleName || ""} ${x.bookmarkType.name}: ${x.value}`,
              color: x.bookmarkType.color,
            } as LabelObject)
        ),
      });
    });
  }

  override chartOptionOverrides(coreOptions: ChartOptions<InternalChartType>): ExtendedChartOptions<InternalChartType> {
    const superOpts = super.chartOptionOverrides(coreOptions);
    const preFilteredBookmarks = this.getBookmarks();
    const filteredBookmarkSets = preFilteredBookmarks.map((x) => {
      x.bookmarks = this.getFilteredBookmarks(x.bookmarks);
      return x;
    });
    const totalBookmarks = cloneDeep(
      filteredBookmarkSets.filter((x) => x.bookmarks != null).flatMap((x) => x.bookmarks) as Bookmark[]
    );
    // If horizontal labels should be rendered inline
    const shouldRenderHorizontalLabels = this.shouldRenderHorizontalLabels(totalBookmarks);
    const newOpts = {
      plugins: {
        customTooltip: {
          hideTooltipBody: false,
          styling: {
            color: this.currentDrawingBookmark
              ? BookmarkChartBaseComponent.getBookmarkColor(this.currentDrawingBookmark, this.colorLookup)
              : undefined,
          },
        },
        bookmark: {
          enabled: !this.isCalculating,
          bookmarks: totalBookmarks,
          shouldRenderHorizontalLabels: shouldRenderHorizontalLabels,
          onClick: (type, bookmarkOrPos) => {
            if (type === "clickBookmark") this.openBookmarkDialog(bookmarkOrPos as Bookmark);
            else if (type === "clickSpace" && this.currentDrawingBookmark)
              this.bookmarkDrawProgress(bookmarkOrPos as number);
          },
          applyHoverStyling: this.currentDrawingBookmark == null,
          domain: this.xDomainRange,
          drawMode: this.bookmarkDrawMode,
          currentDrawingBookmark: this.currentDrawingBookmark,
        },
        bookmarkLegend: {
          // Provide an overarching legend for the horizontal bookmarks
          enabled: !shouldRenderHorizontalLabels && this.configuration.supportsSelectionZone,
          data:
            this.configuration.supportsSelectionZone && shouldRenderHorizontalLabels
              ? undefined
              : this.getLegendBookmarks(filteredBookmarkSets),
        },
      },
    } as ExtendedChartOptions<"line">;
    return merge(superOpts, newOpts);
  }
}
