import { isArray, isDate, isObject } from "lodash";
import { TDMSBase } from "./base";
import { CustomTypes } from "./custom.types";

/**
 * Generic utility functions wide a lot of use across the application
 */
export class Utility {
  /**
   * Regex that supports determining if a string matches our expected ISO_8601 date format
   */
  static readonly ISO_8601_REGEX = /\b[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}(.[0-9]{3})?Z\b/;

  /**
   * Given an object and a list of properties to check, makes sure none of those values are invalid (null | undefined).
   * 	If any are, an error will be thrown.
   * @param obj The object to check against
   * @param strongValidateStrings If this is true, we will also check if a string is empty ("") and consider that invalid if so.
   * @param strongValidateIDs If this is true, we will also check if a property including "ID" is 0 and consider that invalid if so.
   * @param propertiesToValidate The list of properties that we should validate
   */
  static validateDataExistence<T extends TDMSBase | Object, K extends keyof NonNullable<T>>(
    obj: T,
    propertiesToValidate: K[],
    strongValidateStrings: boolean = true,
    strongValidateIDs: boolean = true
    // Assert that the object and any of the keys given by `propertiesToValidate` will not be undefined when they leave this function.
    //	This is because an error  will be thrown if one is. This is used as type guarding.
  ): asserts obj is NonNullable<T> & { [z in K]: NonNullable<NonNullable<T>[z]> } {
    for (let property of propertiesToValidate as any as CustomTypes.PropertyNames<T, any>[]) {
      const objectProperty = obj[property];
      if (
        objectProperty == null ||
        (strongValidateStrings && typeof objectProperty === "string" && !objectProperty.trim()) ||
        (strongValidateIDs &&
          property.toLowerCase().includes("id") &&
          typeof objectProperty === "number" &&
          objectProperty === 0)
      )
        // If anything turns out to be invalid, throw an error so it doesn't continue
        throw new Error(
          `The property '${property}' is a required value. This value must be set and cannot be undefined or null.`
        );
    }
  }

  /**
   * Given a file name, returns it's extension
   */
  static getFileExtensionFromString(fileName: string) {
    return /(?:\.([^.]+))?$/.exec(fileName)?.map((x) => x)[0] || undefined;
  }

  /**
   * Given a file path, removes the path to just return the file name. Handles any separators.
   */
  static getFileName(filePath: string) {
    // Handles any path separators
    return filePath.split("\\").pop()!.split("/").pop() as string;
  }

  /**
   * Given a min and a max, generates a random **int** between those two.
   * ***Inclusive***
   */
  static randomIntFromInterval(min: number, max: number) {
    return Math.round(Utility.randomFromInterval(min, max));
  }

  /**
   * Given a min and a max, generates a random number between those two.
   * ***Inclusive***
   */
  static randomFromInterval(min: number, max: number) {
    return Math.random() * (max - min) + min;
  }

  /**
   * Given an array, returns a random value from that array
   */
  static randomValueFromArray<T>(arr: T[] | readonly T[]) {
    return arr[Math.floor(Math.random() * arr.length)];
  }

  /**
   * Given an array, shuffles it and returns the shuffled version
   */
  static shuffle<T>(array: T[]) {
    let currentIndex = array.length,
      randomIndex;
    // While there remain elements to shuffle.
    while (currentIndex != 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }
    return array;
  }

  /**
   * Given a date, converts it to Zulu format
   */
  static getDateAsZulu(date: Date | string | number | undefined) {
    if (date == null) return "";
    if (typeof date === "number" || (typeof date === "string" && Utility.ISO_8601_REGEX.test(date)))
      date = new Date(date);
    let returnVal = (date as Date).toISOString().replace("T", " ");
    // Don't include milliseconds
    returnVal = returnVal.substring(0, returnVal.length - 5) + "Z";
    return returnVal;
  }

  /**
   * Given an Enum set and a value to find, determines the matching enum
   */
  static getEnumByValue<T extends string, Z extends { [key: string]: T }>(enumSet: Z, valueToFind: T) {
    return Object.keys(enumSet).find((key) => enumSet[key] === valueToFind) as T;
  }

  /**
   * Given a hex string, converts it into an expected RGB value
   */
  static hexToRgb(hex: string) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }

  /**
   * Given the RGB values, returns the matching hex code
   */
  static rgbToHex(r: number, g: number, b: number) {
    const componentToHex = (c: number) => {
      const hex = c.toString(16);
      return hex.length == 1 ? "0" + hex : hex;
    };
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
  }

  /**
   * Given some details, chunks an array into smaller pieces
   * @param chunkSize The chunk size of each array
   * @param arr The array to chunk
   */
  static *chunkArray<T extends Array<any>>(chunkSize: number, arr: T): Generator<T, void, unknown> {
    for (let i = 0; i < arr.length; i += chunkSize) {
      yield arr.slice(i, i + chunkSize) as any;
    }
  }

  /**
   * Given some data in a certain range (minVal, maxVal), scales it to the new target range by normalization (newMinVal, newMaxVal)
   */
  static normalize(data: number, minVal: number, maxVal: number, newMinVal: number, newMaxVal: number) {
    const normalizedData = (data - minVal) / (maxVal - minVal);
    const scaledData = normalizedData * (newMaxVal - newMinVal) + newMinVal;
    return scaledData;
  }

  /**
   * Given a value, dynamically scales it based on the given range and target values.
   *  For example if I give a range of 90 to 1500 with target values from 10 to 1 respectively, a value of 90 will give 10.
   * @param val The value we want to scale to our range
   * @param sourceVal The input value range mapping. In the above example, it would be 90 and 1500.
   * @param targetVal The output value range mapping based on the source values. In the above example it would be 10 to 1.
   */
  static dynamicScaledValue(val: number, sourceVal: [number, number], targetVal: [number, number]): number {
    const [startTime, endTime] = sourceVal;
    const [startValue, endValue] = targetVal;
    val = Math.min(Math.max(val, startTime), endTime);
    const normalizedTime = (val - startTime) / (endTime - startTime);
    const scaledValue = startValue + normalizedTime * (endValue - startValue);
    return scaledValue;
  }

  /**
   * Given an array of numbers, averages the content
   * @param data The data to average
   * @param toFixed If we should convert the final result to a fixed decimal length
   */
  static average(data: number[], toFixed?: number) {
    let avg = data.reduce((a, b) => a + b, 0) / data.length;
    if (toFixed != null) avg = parseFloat(avg.toFixed(toFixed));
    return avg;
  }

  /**
   * Given a target object and some sources, updates the target object with the given source data. This will update the original
   *  object directly. Undefined and null values will be written to the target object.
   * @param target The object to update
   * @param sources The sources of objects to update with
   * @returns The updated target object
   */
  static mergeDeep<T extends Object>(target: T, ...sources: any[]): T {
    if (!sources.length) return target;
    const source = sources.shift();
    if (isObject(target) && isObject(source)) {
      for (const key in source) {
        const sourceKey = (source as any)[key];
        const targetKey = (target as any)[key];
        if (isDate(sourceKey)) Object.assign(target, { [key]: sourceKey });
        else if (isArray(sourceKey)) Object.assign(target, { [key]: sourceKey });
        else if (isObject(sourceKey)) {
          if (!targetKey) Object.assign(target, { [key]: sourceKey });
          this.mergeDeep(targetKey, sourceKey);
        } else Object.assign(target, { [key]: sourceKey });
      }
    }
    return this.mergeDeep(target, ...sources);
  }

  /**
   * Executes a delay that can be used to stop promise execution until the amount of time given
   * @param delay The amount of milliseconds to wait before resolving this promise
   */
  static async delay(delay: number) {
    return await new Promise((f) => setTimeout(f, delay));
  }

  /**
   * Given an array of values, gets unique values for said array and returns them
   */
  static getUniqueValues<T>(values: Array<T>) {
    return [...new Set(values)];
  }

  /** Given the single or double digit number, pads it to include leading zeroes as needed */
  static padNumber(num: number) {
    if (num < 10) return `0${num}`;
    else return num.toString();
  }
}
