import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { Store } from "@ngrx/store";
import { AudioSettings, Bookmark, BookmarkType, LineChartData, Transcription } from "@tdms/common";
import AudioService from "@tdms/frontend/modules/audio/services/audio.service";
import { selectAllTranscriptionsFromState } from "@tdms/frontend/modules/audio/store/transcription.selector";
import { TranscriptionState } from "@tdms/frontend/modules/audio/store/transcription.state";
import { CustomLegendClickCallback } from "@tdms/frontend/modules/charts/shared/legend/legend.component";
import { LegendDisplay } from "@tdms/frontend/modules/charts/shared/legend/models/legend.display";
import { ChartJSDrawingHelper } from "@tdms/frontend/modules/charts/shared/plugins/drawing.helper";
import { ZoomDomainUpdateEmitter } from "@tdms/frontend/modules/charts/timeline/timeline.component";
import { ColorMap, ColorThemeService } from "@tdms/frontend/modules/material/services/themes.service";
import { SafeSpace, TimeDomain } from "@tdms/frontend/modules/metrics/components/timeline/canvas";
import { selectCurrentSession } from "@tdms/frontend/modules/session/store/session.selector";
import { PluginSettingsService } from "@tdms/frontend/modules/settings/services/settings.service";
import { AudioPlayerComponent, TDMSTheme } from "@tdms/frontend/modules/shared/components";
import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";
import { BehaviorSubject, combineLatest, startWith, Subject } from "rxjs";

@Component({
  selector: "timeline[zoomDomainUpdater][applicationTheme]",
  templateUrl: "./timeline.component.html",
  styleUrls: ["./timeline.component.scss"],
})
export class TimelineComponent extends SubscribingComponent implements OnInit {
  @Input() zoomDomainUpdater!: ZoomDomainUpdateEmitter;
  @Input() applicationTheme!: TDMSTheme;
  @Input() graphTitleStart: string | undefined;
  @Input() graphTitleEnd: string | undefined;

  /**
   * A callback to be executed when a value in the legend is clicked
   */
  @Input() customLegendClickCallback: CustomLegendClickCallback | undefined;

  @Input() legend: LegendDisplay[] | undefined;

  @ViewChild("waveformContainer")
  waveformContainer!: ElementRef<HTMLDivElement>;

  @ViewChild("audioPlayer") audioPlayer: AudioPlayerComponent | undefined;

  playbackBookmarkObservable!: Subject<Bookmark[]>;
  currentPlaybackTime = new BehaviorSubject(0);
  safeSpace: Subject<SafeSpace> = new BehaviorSubject({ horizontal: 0, vertical: 0 });

  timeDomain: TimeDomain | undefined;
  loadingTranscriptionData: boolean = true;
  transcriptions: Transcription[][] | undefined;
  speakers: string[] | undefined;
  colorLookup: ColorMap | undefined;

  /** If the transcription display should be shown. Based off the audio plugin settings. */
  shouldDisplayTranscription: boolean = true;
  /** The last safe space calculation for the transcription display */
  lastSafeSpace: SafeSpace | undefined;

  playbackBookmarkType = new BookmarkType(
    0,
    "Playback",
    ChartJSDrawingHelper.defaultColor,
    "vertical",
    false,
    true,
    undefined,
    false,
    true
  );

  constructor(
    private store: Store<TranscriptionState>,
    public audioService: AudioService,
    private themeService: ColorThemeService,
    private settingService: PluginSettingsService
  ) {
    super();
    this.playbackBookmarkObservable = new BehaviorSubject([] as Bookmark[]);
  }

  ngOnInit() {
    this.addSubscription(
      combineLatest([
        this.store.select(selectCurrentSession),
        this.store.select(selectAllTranscriptionsFromState).pipe(startWith(undefined)),
      ]).subscribe((data) => {
        const session = data[0];
        const transcriptions = data[1];

        if (session == null) return;

        this.addSubscription(
          this.themeService
            .observeColorsFor(
              session.roles.map((speaker) => {
                return LineChartData.fromPlain({
                  name: speaker.name,
                });
              })
            )
            .subscribe((colorMap) => {
              this.colorLookup = colorMap;
            })
        );

        if (transcriptions == null) return;

        this.timeDomain = { start: session.startDate, end: session.endDate };

        const parsed = Transcription.fromPlainArray(transcriptions);
        this.transcriptions = this.separateTranscriptionsBySpeaker(parsed);

        this.loadingTranscriptionData = false;

        const playbackBookmark = new Bookmark(
          0,
          false,
          "Current Playback Time",
          this.playbackBookmarkType,
          session.startDate
        );
        this.playbackBookmarkObservable.next([playbackBookmark]);
      })
    );
  }

  ngAfterViewInit() {
    this.addSubscription(
      this.audioPlayer?.currentPlayerTime.subscribe(async (time) => this.currentPlaybackTime.next(time))
    );

    this.addSubscription(
      this.safeSpace.subscribe((space) => {
        this.lastSafeSpace = space;
        this.paddingToChart();
      })
    );

    this.addSubscription(
      this.settingService
        .observeSetting(AudioSettings.PLUGIN_NAME, AudioSettings.Names.transcriptionDisplayed)
        .subscribe((x) => {
          this.shouldDisplayTranscription = x.value;
          this.paddingToChart();
        })
    );
  }

  /** Applies padding to offset the charts to line up with the transcription table */
  paddingToChart(space = this.lastSafeSpace) {
    const padding = !this.shouldDisplayTranscription || space == null ? `0.25rem` : `${space.horizontal + 10}px`;
    this.waveformContainer.nativeElement.style["paddingLeft"] = padding;
    // Adjust the waveforms title as well to help keep it centered based off that padding
    const waveformTitle = document.querySelector(".waveform-container .chart-title-row .title");
    if (waveformTitle) (waveformTitle as HTMLElement).style.setProperty("padding-right", padding);
  }

  separateTranscriptionsBySpeaker(transcriptions: Transcription[]): Transcription[][] {
    const speakerMap: { [speaker: string]: Transcription[] } = {};

    for (const transcription of transcriptions) {
      if (transcription.speaker == null) continue;
      if (speakerMap[transcription.speaker] == null) {
        speakerMap[transcription.speaker] = [transcription];
      } else {
        speakerMap[transcription.speaker].push(transcription);
      }
    }
    this.speakers = Object.keys(speakerMap).sort();
    return this.speakers.map((speaker) => speakerMap[speaker]);
  }

  getColor(value: string): string | undefined {
    return this.colorLookup?.find((mapping) => mapping.name == value)?.value;
  }
}
