<shared-div-progress-component [ready]="!shouldShowSpinner" class="summary-card">
  <mat-card class="inner-card">
    <!-- Header -->
    <mat-card-header class="card-header">
      <mat-card-title class="header">{{ header }}</mat-card-title>
    </mat-card-header>
    <!-- Actual content -->
    <mat-card-content class="content">
      <!-- No data message -->
      <div class="no-data" *ngIf="!dataAvailable">
        <h1>No Data Available</h1>
      </div>
      <ng-content class="content" *ngIf="dataAvailable"></ng-content>
    </mat-card-content>
  </mat-card>
</shared-div-progress-component>
