import { Directive } from "@angular/core";
import { measureTextDimensions } from "@tdms/frontend/modules/metrics/components/timeline/canvas";
import { TDMSTheme } from "@tdms/frontend/modules/shared/components";
import { Subject } from "rxjs";

export interface LabelData {
  color?: string;
  text: string;
}
export type LabelFunction = (labelNumber: number, offset: number) => LabelData | undefined;

export interface Axis {
  numberOfLabels: number;
  labels: LabelFunction;
}

export interface SafeSpace {
  horizontal: number;
  vertical: number;
}

export class ObservableSafeSpace {
  protected _safeSpace: SafeSpace;
  observer: Subject<SafeSpace> | undefined;

  constructor(horizontal: number, vertical: number) {
    this._safeSpace = {
      horizontal: horizontal,
      vertical: vertical,
    };
  }

  get horizontal() {
    return this._safeSpace.horizontal;
  }

  get vertical() {
    return this._safeSpace.vertical;
  }

  set horizontal(space: number) {
    this._safeSpace.horizontal = space;
    this.observer?.next(this._safeSpace);
  }

  set vertical(space: number) {
    this._safeSpace.vertical = space;
    this.observer?.next(this._safeSpace);
  }
}

@Directive({})
export abstract class CanvasHelperBase {
  readonly canvas: HTMLCanvasElement;
  readonly context: CanvasRenderingContext2D;
  safeSpace: ObservableSafeSpace = new ObservableSafeSpace(0, 0);
  lineColor: string = "black";
  defaultFontColor: string = "black";
  yAxis!: Axis;
  xAxis!: Axis;
  xHeight!: number;
  lineHeight!: number;

  constructor(canvas: HTMLCanvasElement, context: CanvasRenderingContext2D) {
    this.canvas = canvas;
    this.context = context;
  }

  /**
   * Recompute the canvas size and safe space for labels/axis based on the current screen dimensions.
   */
  setupCanvasSizing(width: number, xAxis: Axis, yAxis: Axis, longestXLabel: string) {
    this.xAxis = xAxis;
    this.yAxis = yAxis;
    this.autosizeCanvas(width);
    this.addHorizontalLabelSpace(longestXLabel);
    this.addHorizontalSpace(5);
    this.addVerticalLabelSpace();
  }

  setupCanvasTheme(theme: TDMSTheme, options?: { alpha?: number; lightColor?: string; darkColor?: string }) {
    this.context.lineWidth = 1;
    let white = options?.darkColor ?? "#FFFFFF";
    let black = options?.lightColor ?? "#000000";

    if (options?.alpha != null) {
      let alphaHex = Math.round(options.alpha * 255).toString(16);

      if (alphaHex.length == 1) {
        alphaHex = "0" + alphaHex;
      }
      white = `#FFFFFF${alphaHex}`;
      black = `#252525${alphaHex}`;
    }

    this.lineColor = theme == "dark" ? white : black;
    this.defaultFontColor = theme == "dark" ? "white" : "black";
    this.context.strokeStyle = this.lineColor;
  }

  /**
   * Helper function to automatically size this canvas to a given width, targeting a fixed number of lines of vertical text.
   * This is primarily used by the transcription playback component to size to fix n different speakers with transcriptions on a session.
   */
  autosizeCanvas(width: number) {
    this.safeSpace.vertical = 0;
    this.safeSpace.horizontal = 0;
    const ratio = window.devicePixelRatio;
    // this.canvas.style.width = "100%";
    this.canvas.width = width;
    /// For some reason, the text measuring doesn't work if we don't give the canvas an innate height.
    /// So this setter is just a placeholder for us to compute the real height of the canvas.
    this.canvas.height = 100 * ratio;
    this.context.scale(ratio, ratio);
    const dimensions = measureTextDimensions(this.context);

    this.lineHeight = (dimensions.height + 5) * 2 * ratio;
    const totalHeight = this.yAxis.numberOfLabels * this.lineHeight + this.safeSpace.vertical;
    this.canvas.height = totalHeight;
    this.xHeight = measureTextDimensions(this.context).height;
  }

  observeSafeSpace(observer: Subject<SafeSpace>) {
    this.safeSpace.observer = observer;
  }

  /**
   * Draw a vertical line at a given x value.
   * @param x The x value.
   */
  drawVerticalLine(x: number) {
    this.drawLine(x + this.safeSpace.horizontal, 0, x + this.safeSpace.horizontal, this.safeHeight);
  }

  /**
   * Draw a horizontal line at a given y value.
   * @param y The y value.
   */
  drawHorizontalLine(y: number) {
    this.drawLine(this.safeSpace.horizontal, y, this.safeWidth, y);
  }

  /**
   * Draw a line between two points.
   * @param x1
   * @param y1
   * @param x2
   * @param y2
   */
  drawLine(x1: number, y1: number, x2: number, y2: number) {
    if (x1 > this.canvas.width) x1 = this.safeWidth;
    if (x2 > this.canvas.width) x2 = this.safeWidth;
    if (y1 > this.safeHeight) y1 = this.safeHeight;
    if (y2 > this.safeHeight) y2 = this.safeHeight;

    this.context.beginPath();
    this.context.moveTo(x1, y1);
    this.context.lineTo(x2, y2);
    this.context.stroke();
    this.context.closePath();
  }

  /**
   * Draw a label centered vertically around a y coordinate and left aligned with an x coordinate.
   * @param x The x coordinate.
   * @param y The y coordinate.
   * @param label The label data to render.
   */
  verticalCenterTextAt(x: number, y: number, label: LabelData) {
    /// The text y value is the bottom coord of the text.
    /// Because of this, we need to first bump it down by dimensions.height so the top is now at the y value.
    /// Then, we add another half of the text height in order to center it vertically.
    const yTop = y + this.lineHeight / 2 + this.xHeight / 2;
    // const yTop = y + Math.round((this.xHeight * 3) / 2) + 2.5 * window.devicePixelRatio;
    this.drawTextAt(x, yTop, label);
  }

  horizontalCenterTextAt(x: number, y: number, label: LabelData) {
    const dimensions = measureTextDimensions(this.context, label.text);
    const xLeft = x - dimensions.width / 2;
    this.drawTextAt(xLeft, y, label);
  }

  /**
   * Draw a label centered around an origin horizontally and vertically.
   * @param x The x center.
   * @param y The y center.
   * @param label The label data to render.
   */
  centerTextAt(x: number, y: number, label: LabelData) {
    const dimensions = measureTextDimensions(this.context, label.text);
    const xLeft = x - dimensions.width / 2;
    this.verticalCenterTextAt(xLeft, y, label);
  }

  /**
   * Draw text at the given x and y position on the canvas.
   * @param x
   * @param y
   * @param text
   */
  drawTextAt(x: number, y: number, label: LabelData) {
    this.context.fillStyle = label.color ?? this.defaultFontColor;
    this.context.fillText(label.text, x, y);
  }

  computeYOffsetForRow(row: number) {
    const offsetPerRow = this.safeHeight / this.yAxis.numberOfLabels;
    return offsetPerRow * row;
  }

  computeXOffsetForColumn(column: number) {
    const offsetPerColumn = this.safeWidth / this.xAxis.numberOfLabels;
    return offsetPerColumn * column;
  }

  /**
   * Add a fixed amount of horizontal safe space to the canvas, preventing grid drawing from passing that space.
   * @param space The amount of horizontal safe space.
   */
  addHorizontalSpace(space: number) {
    this.safeSpace.horizontal += space;
  }

  /**
   * Add a fixed amount of vertical safe space to the canvas, preventing grid drawing from passing that space.
   * @param space The amount of vertical safe space.
   */
  addVerticalSpace(space: number) {
    this.safeSpace.vertical += space;
  }

  /**
   * Compute the amount of horizontal safe space required to ensure that the grid doesn't overlap the text.
   * @param longestLabel The longest string label that will appear on the horizontal axis.
   */
  addHorizontalLabelSpace(longestLabel: string) {
    const dimensions = measureTextDimensions(this.context, longestLabel);
    this.safeSpace.horizontal += dimensions.width;
  }

  /**
   * Compute tje amount of vertical safe space required to ensure that the grid doesn't overlap the text.
   * Since vertical space doesn't change based on the label, we just use a simple test string to compute the height required.
   * Thus, no parameters should be required here.
   */
  addVerticalLabelSpace() {
    const dimensions = measureTextDimensions(this.context);
    this.safeSpace.vertical += (dimensions.height * 3) / 2 + 5;
  }

  /**
   * Clean out the canvas drawing context by drawing a clear rectangle over the whole area.
   */
  reset() {
    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
  }

  get safeWidth(): number {
    return this.canvas.width;
  }

  get safeHeight(): number {
    return this.canvas.height - this.safeSpace.vertical;
  }
}
