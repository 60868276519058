/**
 * Core message topics to be used across the websocket for important events
 */
export enum CoreTopics {
  initialConnectionStatus = "server.status.initial",
  serverStatus = "server.status",
  getConfig = "config.get",
}

export enum PluginTopics {
  getAll = "plugins.get.all",
}

/**
 * Bookmark specific topics
 */
export enum BookmarkTopics {
  getAllTypes = "bookmark.types.get.all",
  addType = "bookmark.types.add",
  updateType = "bookmark.types.update",
  removeType = "bookmark.types.remove",
  addToSession = "bookmark.add.session",
  delete = "bookmark.delete",
  update = "bookmark.update",
}

/**
 * Topics related directly to users
 */
export enum UserTopics {
  login = "user.login.password",
  loginJWT = "user.login.jwt",
  logout = "user.logout",
  register = "user.register",
  /** Sends out whenever a new user is added to the database */
  userRegistered = "user.registered",
  changePassword = "user.settings.password",
  changeSetting = "user.settings.update",
  getSettingDescriptions = "user.settings.descriptions",
  getSettings = "user.settings.get",
  getColorThemes = "user.settings.colors.themes",
  lookupUserData = "user.data.lookup",
  /** For looking up all users in the app */
  getAll = "user.get.all",
  unlockAccount = "user.unlock",
  lockAccount = "user.lock",
  /** Used to track all updates of a user. This includes lock/unlock */
  userUpdate = "user.update",
}

export enum RoleTopics {
  /** Used to create roles manually to sessions */
  createScratch = "role.create.scratch",
}

/**
 * Topics related specifically to session communication
 */
export enum SessionTopics {
  getAll = "session.get.all",
  /** A topic used to track whenever a new set of sessions are created. This will just be an array of sessions */
  new = "session.new",
  /** This topic tracks whenever a user asks to create a new session from scratch */
  newFromScratch = "session.new.scratch",
  update = "session.update",
  /** Session update request from the frontend. This is separate from {@link update} so we can track success/failure messages with it. */
  updateRequest = "session.update.req",
  delete = "session.delete",
  /** Used to tell clients they should refresh the data from this session because a large change to the session has occurred */
  dataRefresh = "session.data.refresh",
  /** Request to regenerate metrics of a session based on it's original session creation file */
  regenerateMetrics = "session.metrics.regenerate",
  /** Progress updates related to the session metric regeneration */
  regenerateMetricsProgress = "session.metrics.regenerate.progress",
}

/** Tag related websocket topics */
export enum TagTopics {
  getAll = "tag.get.all",
  add = "tag.add",
  update = "tag.update",
  delete = "tag.delete",
}

/**
 * Topics related to data store information
 */
export enum DataStoreTopics {
  /**
   * Upload types supported by the data store
   */
  getTypes = "data.store.types",
  getAccessLog = "data.store.access.log.get",
  getDeletion = "data.store.delete.get",
  // upload = "data.store.upload", // Controlled by HTTP POST
  // download = "data.store.download", // Controlled by HTTP GET
  getMetrics = "data.store.metrics",
  delete = "data.store.delete",
  restore = "data.store.delete.restore",
  getFilesForSession = "data.store.get.files.session",
  // This is a backend broadcast message sent out whenever a data store file is added, moves or is deleted.
  update = "data.store.update",
  search = "data.store.search",
  getSearchOptions = "data.store.search.options",
  /** A queue to request role mapping from the frontend */
  getRoleMapping = "audio.role.mapping",
  /** A queue to request role mapping so user can edit current mappings */
  requestRoleMapping = "audio.role.update",
  /** Get roles associated with a session */
  getRoles = "audio.role.get.roles",
  /** Used to get additional metadata during uploads */
  getMetadata = "data.store.metadata",
  /**A queue to request aggregate file splitting */
  splitAggregateFile = "split.aggregate.file",
  /** Lets client know that based on their original parse request, we've got a file to download. */
  downloadSpecialParse = "download.special.parsed",
  /** Used to track status updates as they occur from the backend during uploading of data files. */
  uploadStatus = "data.store.upload.status",
  /** Request an update to the metadata of an existing data store file */
  editFileMetadata = "data.store.metadata.edit",
  getDataStorePlugins = "data.store.plugins.all",
  getPluginTypes = "data.store.plugins.types.all",
}

/**
 * Topics related to audio files and processing of those files
 */
export enum AudioTopics {
  /** The response queue for audio analysis results */
  analyzeResults = "audio.analyze.result",
  /** Request queue for getting analysis results based on a singular data store file */
  getForFile = "audio.analyze.file",
  /** Request queue to refresh the analysis of a file */
  refreshAnalysis = "audio.analyze.refresh",
  /** A queue that informs of the refresh status as they occur */
  refreshAnalysisStatus = "audio.analyze.refresh.status",
  /** Request to get clc from audio file */
  identifyClosedLoopCommunication = "audio.analyze.clc",
  /** Request to manually change transcriptions */
  manuallyChangeTranscriptions = "audio.change.transcriptions",
}

/** Topics related to the session summary plugin */
export enum SessionSummaryTopics {
  getBySessionId = "session.summary.get.sessionid",
}

/**
 * Topics set for metric requests
 */
export module MetricTopics {
  export const getConfig = "config.get.metrics";

  /**
   * Total speaking time metrics
   */
  export enum TotalSpeakingTime {
    getBySessionId = "metric.total.speaking.get.bySessionId",
  }

  /**
   * Team adaptation metrics
   */
  export enum Adaptation {
    getBySessionId = "metric.adapt.get.bySessionId",
  }

  /**
   * Leadership and knowledge metrics
   */
  export enum Leadership {
    getBySessionId = "metric.lead.get.bySessionId",
  }

  /**
   * Leadership AMI and knowledge metrics
   */
  export enum LeadershipAMI {
    getBySessionId = "metric.lead.ami.get.bySessionId",
  }

  /**
   * Communication frequency metrics
   */
  export enum CommFreq {
    getBySessionId = "metric.comm.get.bySessionId",
  }

  /**
   * Closed-Loop Communication Metrics
   */

  export enum ClosedLoopCommunication {
    getBySessionId = "metric.clc.get.bySessionId",
  }

  /**
   * Communication step-on metrics
   */
  export enum CommStepOn {
    getBySessionId = "metric.comm.stepon.get.bySessionId",
  }

  /** Classification metrics */
  export enum Classification {
    getBySessionId = "metric.classification.get.bySessionId",
  }
}

/**
 * These are topics that don't require any authentication
 */
export const AuthenticationFreeTopics: string[] = [CoreTopics.getConfig, UserTopics.login, UserTopics.loginJWT];
