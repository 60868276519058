<shared-dialog-wrapper [header]="properties.type === 'create' ? 'Bookmark Type Creation' : 'Bookmark Type Edit'">
  <shared-div-progress-component [ready]="!submitted">
    <div class="info-wrapper">
      <!-- Header info -->
      <h1 class="header">
        {{
          properties.type === "create"
            ? "Add your new bookmark type information below and click create"
            : "Edit the bookmark type below and press save"
        }}
      </h1>
      <form class="info-form" [formGroup]="form">
        <!-- Row 1 -->
        <div class="row">
          <!-- Name -->
          <mat-form-field appearance="outline" color="accent">
            <mat-label>Name</mat-label>
            <input matInput formControlName="name" [maxLength]="maxNameLength" (keydown.enter)="submit()" />
            <mat-error *ngIf="form.controls.name.invalid">Name is invalid</mat-error>
          </mat-form-field>
        </div>
        <!-- Row 2 -->
        <div class="row">
          <!-- Color -->
          <shared-color-picker formControlName="color"></shared-color-picker>
        </div>
      </form>
      <!-- Button row -->
      <div class="button-row row">
        <!-- Delete -->
        <button
          *ngIf="properties.type === 'edit'"
          mat-raised-button
          (click)="delete()"
          color="warn"
          matTooltip="Permanently delete's this bookmark type"
          [configDisable]="'bookmark.allowTypeDeleting'"
          configDisabledStateMessage="Deletion is disabled"
        >
          Delete
        </button>
        <!-- Submit -->
        <button
          mat-raised-button
          (click)="submit()"
          color="accent"
          [disabled]="!form.valid || !editValuesHaveChanged"
          [matTooltip]="
            !form.valid ? 'Please fill in all required inputs' : !editValuesHaveChanged ? 'No values have changed' : ''
          "
          [configDisable]="properties.type === 'edit' ? 'bookmark.allowTypeEditing' : 'bookmark.allowTypeCreation'"
          [configDisabledStateMessage]="properties.type === 'edit' ? 'Editing is disabled' : 'Creation is disabled'"
        >
          {{ properties.type === "edit" ? "Save" : "Create" }}
        </button>
      </div>
    </div>
  </shared-div-progress-component>
</shared-dialog-wrapper>
