<div class="audio-track-container">
  <!-- Error display in the event we can't load the audio track -->
  <div class="error-display" *ngIf="audioService.loadFailureMessage">
    <span class="text">{{ audioService.loadFailureMessage }}</span>
    <div class="background"></div>
    <mat-icon
      *ngIf="audioService.extendedFailureMessage"
      class="icon"
      [matTooltip]="audioService.extendedFailureMessage"
      >info</mat-icon
    >
  </div>

  <!-- Actual audio player -->
  <shared-audio-player
    #audioPlayer
    [src]="audioService.audioBlob.value?.url"
    displayType="expanded"
    class="audio-player"
  ></shared-audio-player>

  <!-- Waveform metrics -->
  <audio-waveform class="audio-waveform" [playbackBookmarkObservable]="playbackBookmarkObservable"></audio-waveform>
</div>
