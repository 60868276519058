import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { Session, SessionSummary } from "@tdms/common";
import { selectSessionSummaryForCurrentSession } from "@tdms/frontend/modules/session-summary/store/summary.selector";
import { SessionSummaryState } from "@tdms/frontend/modules/session-summary/store/summary.state";
import { selectCurrentSession } from "@tdms/frontend/modules/session/store/session.selector";
import { ConfigService } from "@tdms/frontend/modules/settings/services/config.service";
import { DialogWrapperComponent } from "@tdms/frontend/modules/shared/components";
import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";
import { SessionSummaryAdvancedHelpProperties, SessionSummaryHelpDialogComponent } from "./help/help-dialog.component";

@Component({
  selector: "app-advanced",
  templateUrl: "./advanced.component.html",
  styleUrls: ["./advanced.component.scss"],
})
export class AdvancedComponent extends SubscribingComponent {
  /** The current session summary data for the current session */
  currentSessionSummary: SessionSummary | undefined;
  currentSession: Session | undefined;

  constructor(
    private store: Store<SessionSummaryState>,
    private matDialog: MatDialog,
    private configService: ConfigService
  ) {
    super();
    this.addSubscription(
      this.store.select(selectCurrentSession).subscribe((session) => (this.currentSession = session))
    );
    this.addSubscription(
      this.store.select(selectSessionSummaryForCurrentSession).subscribe(async (summary) => {
        // Track the session summary data
        this.currentSessionSummary = summary;
      })
    );
  }

  get gaugeRange() {
    return this.configService.configData!.sessionSummary.gaugeRange;
  }

  ngOnInit(): void {}

  /** Opens the help dialog to display calculation information */
  openHelpDialog() {
    if (this.currentSessionSummary)
      this.matDialog.open(SessionSummaryHelpDialogComponent, {
        data: { breakdown: this.currentSessionSummary.advancedBreakdown } as SessionSummaryAdvancedHelpProperties,
        ...DialogWrapperComponent.getDefaultOptions(),
      });
  }

  /** Returns if this current session summary has any help content */
  get hasHelpDialogContent() {
    return this.currentSessionSummary?.advancedBreakdown.find((x) => x.metrics?.find((z) => z.helpContent));
  }
}
