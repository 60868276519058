import { createReducer, on } from "@ngrx/store";
import { SettingCollectionActions } from "./collection.action";
import { SettingCollection } from "./collection.model";

export const initialState: ReadonlyArray<SettingCollection> = [];

export const settingCollectionReducer = createReducer(
  initialState,
  on(SettingCollectionActions.addCollections, (state, data) => {
    return [
      /// Include all existing collections, minus those that are in the newly provided list.
      ...state.filter(
        (collection) => data.collections.findIndex((newCollection) => newCollection.name === collection.name) >= 0
      ),
      ...data.collections,
    ];
  }),
  on(SettingCollectionActions.clear, (_state, _data) => {
    return [];
  })
);
