import { DataStoreFile, TDMSBase, UploadOption } from "@tdms/common";
import { Type } from "class-transformer";

/**
 * A type to help clarify when we upload a file, what kind of information we will need
 */
export class TrackedFile extends TDMSBase {
  file!: File;
  @Type(() => UploadOption)
  fileType!: UploadOption;
  removable: boolean = true;
  /**
   * If this file should be counted as a new upload. Used to track the current session uploader
   *  for when it displays files that already exist.
   */
  countsAsNew = true;

  /** If this file created the session it's associated to */
  isDelayedSessionCreation = false;

  /**
   * A frontend tracker to track this file as the session creation. This is kept separate from {@link isDelayedSessionCreation} because that variable
   *  will be directly used when uploading to inform scratch sessions of delayed creation. This variable is only used to display during session creation.
   */
  isSessionCreation = false;

  uploadError: boolean = false;

  errorMessage?: string;

  get fileDisplayName() {
    return DataStoreFile.getPreferredFileName(this.file.name);
  }
}
