import { TDMSBase } from "../../base";

/**
 * A configuration class to allow the frontend to know some configuration values that the backend
 *  has set for the audio plugin
 */
export class AudioConfig extends TDMSBase {
  /** The upload option name for speech so we can use this same value later */
  static readonly SPEECH_UPLOAD_OPTION_NAME = "Speech";
  /** The upload option name for mixed down single track speech */
  static readonly SPEECH_MIXED_UPLOAD_OPTION_NAME = `Speech-Mixed`;
  /** The upload option name for files that were multiple single track files combined */
  static readonly TRACK_COMBINED_UPLOAD_OPTION_NAME = "speech-combined-file";

  enabled: boolean = false;
  /** If we should be allowed to refresh transcriptions */
  allowRefresh: boolean = true;
  /** If we should allow transcriptions to be edited */
  allowEditing: boolean = false;
}
