import { DialogRef } from "@angular/cdk/dialog";
import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import { UploadOption, UploadRequest } from "@tdms/common";
import { TrackedFile } from "@tdms/frontend/modules/data-store/components/uploader/file-tree/models/tracked.file";
import { UploaderProgressComponent } from "@tdms/frontend/modules/data-store/components/uploader/uploader-progress/uploader-progress.component";
import { DataStoreState } from "@tdms/frontend/modules/data-store/models/data.store.state";
import { selectDataStoreUploadOptions } from "@tdms/frontend/modules/data-store/models/store/data.store.selector";

import { UploadService } from "@tdms/frontend/modules/data-store/services/upload.service";
import { Configuration } from "@tdms/frontend/modules/shared/models/config";
import { UserService } from "@tdms/frontend/modules/user/services/user.service";

/** Fields to help give the special uploader more info */
export interface SpecialUploaderDialogProperties {
  type: UploadRequest["specialUploadType"];
}

/**
 * This component is intended to be a generic uploader that allows you to upload a single file and process it to some other data
 *  format that will then respond with a download of your new content.
 *
 * For more information on how to implement your own capabilities, please use the {@link UploadRequest.specialUploadType}
 */
@Component({
  selector: "data-store-special-uploader",
  templateUrl: "./special-uploader.component.html",
  styleUrls: ["./special-uploader.component.scss"],
})
export class SpecialUploaderComponent extends UploaderProgressComponent {
  /** Track file currently uploaded */
  currentFile: TrackedFile | undefined;

  /** The matching upload type for this specialized upload */
  uploadType: UploadOption | undefined;

  constructor(
    private store: Store<DataStoreState>,
    public override uploadService: UploadService,
    public override userService: UserService,
    public override snackBar: MatSnackBar,
    private dialogRef: DialogRef,
    @Inject(MAT_DIALOG_DATA) public properties: SpecialUploaderDialogProperties
  ) {
    super(userService, uploadService, snackBar);
    this.addSubscription(
      this.store
        .select(selectDataStoreUploadOptions)
        .subscribe((x) => (this.uploadType = x.find((z) => z.name === properties.type)))
    );
  }

  async submitCallback() {
    if (!this.currentFile) {
      this.snackBar.open(`No File Selected`, "Close", Configuration.ErrorSnackbarConfig);
      return;
    }
    try {
      // Await the upload of each file before continuing to the next, and update progress
      await this.submitUploadRequest(this.currentFile!, undefined, undefined, this.properties.type);
      this.dialogRef.close();
    } catch {}
  }

  /** Updates the extra files in the tree */
  handleFileUpdate(updatedFile: TrackedFile) {
    // Clone deep to forcibly change the reference
    this.currentFile = updatedFile;
  }

  get submitButtonAvailable() {
    if (this.currentFile) return true;
    else return false;
  }

  get validationMessage() {
    if (this.currentFile) return "";
    else return "Please select a file";
  }
}
