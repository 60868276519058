<shared-dialog-wrapper [header]="properties.type + ' Parser'" class="upload-dialog">
  <data-store-uploader-progress
    [status]="status"
    [shouldDisplayStatus]="shouldDisplayStatus"
    [filesUploading]="filesUploading"
  >
    <div class="upload-dialog-container">
      <h1>Please select a file below to process as the {{ properties.type }} type</h1>
      <div>
        <data-store-file-uploader
          [shouldDisplaySessionCreationActor]="false"
          (singleFileUpdate)="handleFileUpdate($event)"
          [allowMultiple]="false"
          [hideTypeSelection]="true"
          [selectedUploadType]="uploadType"
          [selectedPluginType]="uploadType?.associatedPlugin"
        ></data-store-file-uploader>
        <div class="submit-container">
          <span [matTooltip]="validationMessage || ''">
            <button mat-flat-button color="accent" (click)="submitCallback()" [disabled]="!submitButtonAvailable">
              Parse File
            </button>
          </span>
        </div>
      </div>
    </div>
  </data-store-uploader-progress>
</shared-dialog-wrapper>
