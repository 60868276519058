import { Component, Inject, OnInit } from "@angular/core";
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import autoBind from "auto-bind";

/**
 * We use this interface instead of the actual DataStoreFile so the dialog doesn't
 * have to know any of those specific details, and because we need to emulate changes to the filename
 * without keeping track of the entire filepath.
 * The EditService is in charge of turning this back into an input the backend can understand.
 */
export interface DataStoreEditData {
  sessionName: string;
  filename: string;
  currentPlugin: string;
  currentPluginType: string | undefined;
}

/**
 * All the data the editor dialog needs in order to function.
 */
export interface FileEditorDialogProperties {
  fileData: DataStoreEditData;
  availablePlugins: string[];
  availablePluginTypes: { [key: string]: string[] };
  availableSessionNames: string[];
  onFileEdited: (editedData: DataStoreEditData) => void;
  onEditCancelled: () => void;
}

/**
 * This dialog is used to edit a single data store file exposed by the backend,
 * primarily in conjunction with the Data Store Search tab.
 * As of now, it lets you modify the filename, associated plugin and plugin type, and associated session of the file.
 */
@Component({
  selector: "file-editor-dialog",
  templateUrl: "./file-editor-dialog.component.html",
  styleUrls: ["./file-editor-dialog.component.scss"],
})
export class FileEditorDialogComponent implements OnInit {
  formGroup!: FormGroup;

  availablePluginTypes: string[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public properties: FileEditorDialogProperties) {
    autoBind(this);
  }

  /**
   * Create our form group, prepopulating the values for associated plugin and plugin type and disabling as necessary.
   */
  ngOnInit(): void {
    this.formGroup = new FormGroup({
      associatedPlugin: new FormControl({ value: this.properties.fileData.currentPlugin, disabled: false }, [
        Validators.required,
      ]),
      associatedPluginType: new FormControl(
        {
          value: this.properties.fileData.currentPluginType,
          disabled: this.availablePluginTypes == null || this.availablePluginTypes.length <= 1,
        },
        [
          (control: AbstractControl): ValidationErrors | null => {
            /// We only require a value for this dropdown IF we have available plugin types.
            if (this.availablePluginTypes.length > 0 && control.value == null)
              return { associatedPluginType: control.value };
            return null;
          },
        ]
      ),
      associatedSession: new FormControl({ value: this.properties.fileData.sessionName, disabled: false }, [
        Validators.required,
      ]),
      filename: new FormControl({ value: this.properties.fileData.filename, disabled: false }, [Validators.required]),
    });

    this.availablePluginTypes = this.properties.availablePluginTypes[this.properties.fileData.currentPlugin];
  }

  /**
   * When the associated plugin value is changed,
   * we need to update our available plugin types from our map and change values/disable the type dropdown depending on
   * whether there is more than one (or any) types available to select for the new plugin.
   */
  onPluginChanged() {
    const pluginTypeControl = this.formGroup.get("associatedPluginType");
    this.availablePluginTypes = this.properties.availablePluginTypes[this.formGroup.get("associatedPlugin")?.value];

    if (this.availablePluginTypes == null) this.availablePluginTypes = [];

    if (this.availablePluginTypes.length == 0) {
      pluginTypeControl?.disable();
    } else if (this.availablePluginTypes.length == 1) {
      pluginTypeControl?.disable();
      pluginTypeControl?.setValue(this.availablePluginTypes[0]);
    } else {
      pluginTypeControl?.enable();
      pluginTypeControl?.markAsTouched();
    }

    const currentPluginType = pluginTypeControl?.value;

    if (!this.availablePluginTypes.includes(currentPluginType)) {
      pluginTypeControl?.setValue(null);
    }
  }

  /**
   * Grab the modified data inputs from the form and send back to the edit service for processing.
   */
  submit() {
    if (!this.formGroup.valid) return;

    const newData: DataStoreEditData = {
      filename: this.formGroup.get("filename")!.value,
      currentPlugin: this.formGroup.get("associatedPlugin")!.value,
      currentPluginType: this.formGroup.get("associatedPluginType")!.value,
      sessionName: this.formGroup.get("associatedSession")!.value,
    };

    this.properties.onFileEdited(newData);
  }
}
