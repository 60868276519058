import { SelectionModel } from "@angular/cdk/collections";
import {
  Component,
  ContentChild,
  ContentChildren,
  Directive,
  Input,
  QueryList,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, SortDirection } from "@angular/material/sort";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { CustomTypes } from "@tdms/common";
import {
  DatalessColumn,
  GenericTableColumn,
} from "@tdms/frontend/modules/shared/components/tables/generic-table/generic-table.component";
import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";

@Directive({ selector: "cell-display[columns]" })
export class CellDisplay {
  @Input() columns!: Array<string>;
  @ContentChild("display") displayTemplateRef!: TemplateRef<any> | null;
}

/**
 * This is a dummy base component that all generic tables should override from.
 * It exposes all the shared input elements that these tables use/need, and reduces code duplication/verbosity.
 * It does nothing on its own.
 * We need this since the editable-table does not want to extend from the generic-table directly, since it overrides several of it's input
 * fields and thus does not want them exposed to consumers.
 */
@Component({ template: "" })
export class TableBaseComponent<T extends Object & { isProcessing?: boolean }> extends SubscribingComponent {
  /**
   * The name of this table. Used primarily for exporting and other identification.
   * @default "Generic Table"
   */
  @Input() tableName = "Generic Table";

  /**
   * This is the supported data that should be displayed in this table.
   */
  @Input() data!: T[] | undefined | null;

  /**
   * Our local table data source for more control over the given data
   */
  dataSource = new MatTableDataSource<T>();

  /**
   * The columns to display on our table
   */
  @Input() displayedColumns!: GenericTableColumn[];

  /** Holds the columns that should be rendered based on displayed columns + internal columns */
  renderedColumns: GenericTableColumn[] = [];

  /**
   * If the filter box should appear in this table or not
   */
  @Input() filterable: boolean = true;

  /**
   * If given, will add an add button to the table to add new rows
   */
  @Input() addRowCallback: { (): void } | undefined;

  /**
   * If given, allows the rows to be clicked and fire this callback
   */
  @Input() clickCallback: { (data: T): void } | undefined;

  /**
   * The value in the filter input
   */
  filterValue: string = "";

  /**
   * The field name that we are currently filtering on from filterableFields
   */
  filterOn!: CustomTypes.PropertyNames<T, any>;

  /**
   * Fields that are searchable for the dropdown.
   *
   * Takes displayed columns by default and turns them into this object
   */
  @Input() filterableFields: { value: string; viewValue: string }[] | undefined = undefined;

  /**
   * The paginator element
   */
  @ViewChild("contentTablePaginator") paginator!: MatPaginator;

  /**
   * The table element
   */
  @ViewChild("genericTable") table!: MatTable<any>;

  /**
   * Mat sort for table that allows us to sort by column
   */
  @ViewChild(MatSort) sort!: MatSort;

  /**
   * Reference to the cell template so the developer can override it
   */
  @ContentChildren(CellDisplay) cellDisplayRefs!: QueryList<CellDisplay>;

  /**
   * The sort header to start with
   */
  @Input() defaultSortHeader: CustomTypes.PropertyNames<T, any> | undefined;

  /**
   * The default direction to perform our sorting
   */
  @Input() defaultHeaderSortDirection: SortDirection = "desc";

  /**
   * The paginator page size to use
   * @default 10
   */
  @Input() paginatorPageSize: number = 10;

  /**
   * If we should attempt to guess how many rows we can fit per page to max out the size of
   *  it's current container. Disabling this may result in a larger table than it's container
   * @default true
   */
  @Input() shouldGuessPaginatorPageSize = true;

  /**
   * If the first last buttons should be shown on the paginator, if enabled.
   * @default true
   */
  @Input() showFirstLastButtons = true;

  /**
   * Controls if the export button should be displayed in the table so the user can export this data to
   *  a .csv format.
   * @default true
   */
  @Input() exportEnabled = true;

  /**
   * The height of each mat row used to guess paginator sizing for table. In pixels.
   */
  @Input() matRowHeight = 50;

  /**
   * How wide the table is in pixels
   */
  protected tableWidth: number = 0;

  /**
   * This input allows you to add selection boxes to tables for executing mass functionality against. Passing a function here
   *  will cause the selection boxes to appear.
   */
  @Input() selectionUpdate: { (currSelection: T[]): void } | undefined;

  /** Selection model for use with the selection boxes */
  selection = new SelectionModel<T>(true, []);

  /** The column information for displaying the selection boxes */
  selectionColumn = new DatalessColumn("select", "");

  /** Tracks what rows should be considered "active". This is not a required value */
  @Input() activeRows: T[] | undefined;
}
