import { createActionGroup, props } from "@ngrx/store";
import { SettingCollection } from "./collection.model";

export const SettingCollectionActions = createActionGroup({
  source: "SettingCollections",
  events: {
    "Add Collections": props<{ collections: SettingCollection[] }>(),
    clear: props<any>(),
  },
});
