<!-- The form containing the input for the color popup -->
<mat-form-field
  appearance="outline"
  color="accent"
  class="color-picker-form-field"
  [ngClass]="{ disabled: formControl.disabled }"
  (click)="click($event)"
  *ngIf="formControl"
>
  <mat-label>Color</mat-label>
  <span class="content">
    <!-- Button to allow selecting colors. Also contains the directive for the color picker opener. -->
    <button
      mat-icon-button
      class="color-selection"
      [disabled]="formControl.disabled"
      ngx-colors-trigger
      #colorPicker
      format="hex"
      [hideTextInput]="true"
      [palette]="colorOptions"
      acceptLabel="Save"
      cancelLabel="Cancel"
      colorPickerControls="no-alpha"
      (change)="onChange($event)"
    >
      <mat-icon>palette</mat-icon>
    </button>
    <!-- Input to display the current color string. -->
    <input matInput readonly [formControl]="formControl" (click)="click($event)" />
    <!-- Color indication -->
    <div
      class="color-indicator"
      [ngStyle]="{ backgroundColor: color }"
      matSuffix
      [matTooltip]="color"
      (click)="click($event)"
    ></div>
  </span>
</mat-form-field>
