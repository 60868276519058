import { Type } from "class-transformer";
import { decorate } from "ts-mixer";
import { TDMSBase } from "../../base";
import { CustomTypes } from "../../custom.types";

/** Types that are supported by the metadata requests */
export type UploadMetadataSupportedTypes = string | number | Date;

/** This class defines some specific piece of metadata we are looking for */
export class DataStoreUploadMetadataOption<T extends UploadMetadataSupportedTypes> extends TDMSBase {
  name: string;
  /** help text to provide more context */
  help?: string;
  type: CustomTypes.TypeName<UploadMetadataSupportedTypes> | "dropdown" = "string";
  /** Value that will have the users input option */
  value: T;
  /** Filling this out will allow the option to instead display a dropdown. Only applies to dropdown type. */
  options?: T[];

  constructor(name: string, type: DataStoreUploadMetadataOption<T>["type"], value: T, help?: string, options?: T[]) {
    super();
    this.name = name;
    this.type = type;
    this.value = value;
    this.help = help;
    this.options = options;
  }
}

/** This class defines the group of options to ask for based on the corresponding file*/
export class DataStoreUploadMetadataGroup extends TDMSBase {
  /** The file name that this group corresponds to */
  fileName: string;

  /** The name of the plugin this metadata request came from */
  pluginName: string;

  /** The options we should be asking for */
  @decorate(Type(() => DataStoreUploadMetadataOption))
  options: Array<DataStoreUploadMetadataOption<any>>;

  constructor(fileName: string, pluginName: string, options: Array<DataStoreUploadMetadataOption<any>>) {
    super();
    this.fileName = fileName;
    this.pluginName = pluginName;
    this.options = options;
  }
}

/**
 * This class defines the request/response information required for the metadata information
 */
export class DataStoreUploadMetadata extends TDMSBase {
  @decorate(Type(() => DataStoreUploadMetadataGroup))
  groups!: Array<DataStoreUploadMetadataGroup>;
  /** Tracks if this request was completed and the values inside {@link groups} are updated correctly. */
  complete: boolean = false;
}
