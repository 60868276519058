import { Component } from "@angular/core";
import { TimelineChartBaseComponent } from "@tdms/frontend/modules/charts/shared/timeline-base/timeline.base.component";
import { AngularCustomTypes } from "@tdms/frontend/modules/shared/models/angular.custom.types";
import { ChartOptions } from "chart.js";
import { merge } from "lodash-es";
import { BehaviorSubject, Observable } from "rxjs";
import { LineChartComponent } from "../line/line.component";
import { ExtendedChartOptions } from "../shared/plugins/plugin.typing";

/**
 * The content the zoom domain will output
 */
export type ZoomDomainContent = { domain: [Date, Date] | undefined; source: "timeline" | "external" };

/**
 * A centralized type to help enforce the event emitter capabilities that will be used when
 *  the timeline "zooms" into an area.
 */
export type ZoomDomainUpdateEmitter = BehaviorSubject<ZoomDomainContent>;

/**
 * Observable typing for timeline zoom
 */
export type ZoomDomainObservable = Observable<ZoomDomainContent>;

/**
 * A chart that extends upon the LineChart capability to apply a timeline style format incorporating zoom level capabilities.
 */
@Component({
  selector: "charts-timeline[data][colorLookup][configuration][domainUpdate]",
  templateUrl: "../shared/base/base.component.html",
  styleUrls: ["../shared/base/base.component.scss"],
})
export class TimelineChartComponent extends LineChartComponent {
  override get kebabMenuEnabled() {
    return false;
  }

  override shouldChartUpdate(changes: AngularCustomTypes.BaseChangeTracker<TimelineChartBaseComponent<any, any>>) {
    return super.shouldChartUpdate(changes, false);
  }

  override chartOptionOverrides(coreOptions: ChartOptions<"line">): ExtendedChartOptions<"line"> {
    // Grab super options
    const superOptions = super.chartOptionOverrides(coreOptions);
    // Grab new options
    const newOptions: ExtendedChartOptions<"line"> = {
      plugins: {
        bookmark: {
          shouldRenderHorizontal: false,
        },
        customTooltip: {
          timeDisplay: this.xFormatter.bind(this),
          styling: {
            smallMode: true,
          },
        },
      },
      layout: {
        padding: {
          left: 20,
        },
      },
    };
    return merge(superOptions, newOptions);
  }
}
