import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from "@angular/core";
import { AudioSettings, Bookmark, BookmarkType, Transcription, Utility } from "@tdms/common";
import { ChartJSDrawingHelper } from "@tdms/frontend/modules/charts/shared/plugins/drawing.helper";
import { ZoomDomainContent, ZoomDomainUpdateEmitter } from "@tdms/frontend/modules/charts/timeline/timeline.component";
import { ColorMap } from "@tdms/frontend/modules/material/services/themes.service";
import {
  AnimatedPlaybackHelper,
  Axis,
  GridCanvasHelper,
  SafeSpace,
  TextSyncedCanvasHelper,
  TimeDomain,
} from "@tdms/frontend/modules/metrics/components/timeline/canvas/index";
import { PluginSettingsService } from "@tdms/frontend/modules/settings/services/settings.service";
import { TDMSTheme } from "@tdms/frontend/modules/shared/components";
import { AngularCustomTypes } from "@tdms/frontend/modules/shared/models/angular.custom.types";
import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";
import autoBind from "auto-bind";
import { cloneDeep } from "lodash-es";
import { debounceTime, fromEvent, Subject, Subscription } from "rxjs";

@Component({
  selector: "transcription-playback[theme][transcriptions]",
  templateUrl: "./transcription-playback.component.html",
  styleUrls: ["./transcription-playback.component.scss"],
})
export class TranscriptionPlaybackComponent extends SubscribingComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() domainUpdate?: ZoomDomainUpdateEmitter;
  @Input() theme!: TDMSTheme;
  @Input() playbackBookmarkObservable: Subject<Bookmark[]> | undefined;
  @Input() currentPlaybackObservable!: Subject<number>;
  @Input() safeSpaceObservable: Subject<SafeSpace> | undefined;
  @Input() transcriptions: Transcription[][] | undefined;
  @Input() speakers: string[] | undefined;
  @Input() timeDomain: TimeDomain | undefined;
  @Input() colorLookup: ColorMap | undefined;

  @ViewChild("grid")
  grid!: ElementRef<HTMLCanvasElement>;
  @ViewChild("timeline")
  timeline!: ElementRef<HTMLCanvasElement>;
  @ViewChild("playbackIndicator")
  playbackIndicator!: ElementRef<HTMLCanvasElement>;

  @ViewChild("transcriptSizingContainer")
  container!: ElementRef<HTMLDivElement>;

  context!: CanvasRenderingContext2D;
  gridCanvasHelper!: GridCanvasHelper;
  transcriptionCanvasHelper!: TextSyncedCanvasHelper;
  playbackHelper!: AnimatedPlaybackHelper;

  isPlaying: boolean = false;
  currentPlaybackTime: number = 0;
  /** The last playback time checked by the subscription */
  lastCheckedPlaybackTime: number = 0;

  /** If the transcription display should be shown. Based off the audio plugin settings. */
  shouldDisplayTranscription: boolean = true;

  /** The subscription related to the playback listener */
  playbackSubscription: Subscription | undefined;

  constructor(private settingService: PluginSettingsService) {
    super();
    autoBind(this);
  }

  ngOnInit(): void {
    if (this.domainUpdate) {
      // Grab current domain so we are up to date
      this.setDomainData({ ...this.domainUpdate.getValue(), source: "external" });
      // Listen for domain updates
      this.addSubscription(
        this.domainUpdate.subscribe((data) => {
          this.setDomainData(data);
        })
      );
    }
  }

  ngOnChanges(changes: AngularCustomTypes.BaseChangeTracker<TranscriptionPlaybackComponent>): void {
    if (changes.timeDomain && this.currentPlaybackTime === 0) this.updatePlaybackBookmark(0); // Fixes the playback bookmark not appearing on initial
    if (changes.theme || changes.transcriptions || changes.timeDomain) {
      if (this.canvasesReady()) {
        this.initializeCanvases();
      }
    }
  }

  /**
   * We need to wait for the html view to initialize to grab the canvas helpers.
   */
  ngAfterViewInit(): void {
    this.gridCanvasHelper = new GridCanvasHelper(this.grid.nativeElement, this.grid.nativeElement.getContext("2d")!);

    if (this.safeSpaceObservable) {
      this.gridCanvasHelper.observeSafeSpace(this.safeSpaceObservable);
    }

    this.transcriptionCanvasHelper = new TextSyncedCanvasHelper(
      this.timeline.nativeElement,
      this.timeline.nativeElement.getContext("2d")!
    );
    this.playbackHelper = new AnimatedPlaybackHelper(
      this.playbackIndicator.nativeElement,
      this.playbackIndicator.nativeElement.getContext("2d")!
    );
    /// We need to let the rendering cycle complete before drawing so that all the element sizes are computed properly.
    /// So we setTimeout 0 to schedule this to run on the next event cycle.
    setTimeout(this.initializeCanvases, 0);
    /// Grab window resizes to redraw.
    this.addSubscription(fromEvent(window, "resize").pipe(debounceTime(150)).subscribe(this.initializeCanvases));

    this.addSubscription(
      this.settingService
        .observeSetting(AudioSettings.PLUGIN_NAME, AudioSettings.Names.transcriptionDisplayed)
        .subscribe((x) => {
          this.shouldDisplayTranscription = x.value;
          if (!this.shouldDisplayTranscription) this.resetCanvases();
          else this.initializeCanvases();
        })
    );

    this.addSubscription(
      this.currentPlaybackObservable.subscribe((time) => {
        this.updatePlaybackBookmark(time);
        this.currentPlaybackTime = time;
      })
    );
  }

  /** Wipes and resets the canvases states */
  resetCanvases() {
    this.gridCanvasHelper.reset();
    this.transcriptionCanvasHelper.reset();
    this.playbackHelper.reset();
    this.container.nativeElement.style["minHeight"] = `0px`;
    this.playbackSubscription?.unsubscribe();
  }

  /**
   * Initialize all first-start data for the canvases.
   * This includes setting the canvas width to compute sizes with,
   * as well as x and y axes.
   * We also subscribe events for playback and page turning here.
   */
  initializeCanvases() {
    if (!this.canvasesReady() || !this.shouldDisplayTranscription) return;

    const width = this.container.nativeElement.offsetWidth;
    const xAxis: Axis = {
      numberOfLabels: 10,
      labels: (_, offset) => {
        const time = this.transcriptionCanvasHelper.computeDateForX(offset);
        const label = `${Utility.padNumber(time.getUTCHours())}:${Utility.padNumber(
          time.getUTCMinutes()
        )}:${Utility.padNumber(time.getUTCSeconds())}.${Utility.padNumber(time.getUTCMilliseconds())}`;
        return { text: label };
      },
    };
    const yAxis: Axis = {
      numberOfLabels: this.speakers!.length,
      labels: (index) => {
        let color: string | undefined;

        if (this.colorLookup != null) {
          color = this.colorLookup[index]?.value;
        }
        const speaker = this.speakers![index];
        return { text: speaker, color: color };
      },
    };

    let longestXLabel = "";

    if (this.speakers != null && this.speakers.length > 0) {
      longestXLabel = this.speakers.reduce((a, b) => (a.length > b.length ? a : b));
    }

    this.gridCanvasHelper.initialize(this.theme, width, xAxis, yAxis, longestXLabel);
    this.transcriptionCanvasHelper.initialize(
      this.theme,
      this.transcriptions ?? [],
      this.timeDomain!,
      width,
      xAxis,
      yAxis,
      longestXLabel
    );
    this.playbackHelper.initialize(this.theme, width, xAxis, yAxis, longestXLabel);

    this.playbackHelper.updateMaxTimeOnScreen(this.transcriptionCanvasHelper.maxTimeOnScreen);

    /// Listen to the playback timestamp to animate our playback indicator,
    /// and paginate the transcription view as needed.
    /// We also create the bookmark to add to the playback bookmark observable here.
    if (this.playbackSubscription) this.playbackSubscription.unsubscribe(); // Cleanup old subscriptions
    this.playbackSubscription = this.currentPlaybackObservable.subscribe((time) => {
      const currentPage = Math.floor(this.lastCheckedPlaybackTime / this.transcriptionCanvasHelper.maxTimeOnScreen);
      const nextPage = Math.floor(time / this.transcriptionCanvasHelper.maxTimeOnScreen);

      if (currentPage != nextPage) {
        /// Save on transcription text redraws by only redrawing when the page changes.
        /// Most of the time, the playback indicator is the only thing that needs redrawn.
        this.transcriptionCanvasHelper.seekToPage(nextPage);
      }

      this.playbackHelper.drawPlaybackIndicator(time, nextPage);
      this.lastCheckedPlaybackTime = time;
    });
    this.addSubscription(this.playbackSubscription);

    /// Since we are absolutely positioning the canvases on top of each other, the parent container's height doesn't update to reflect the children anymore.
    /// We fix this by setting the minHeight of the parent container after the canvases have initialized and done their sizing.
    this.container.nativeElement.style["minHeight"] = `${this.grid.nativeElement.height}px`;
    this.redraw();
  }

  updatePlaybackBookmark(timestamp: number) {
    if (this.timeDomain == null) return;
    const type = new BookmarkType(
      0,
      "Playback",
      ChartJSDrawingHelper.defaultColor,
      "vertical",
      false,
      true,
      undefined,
      false,
      true
    );
    const playbackBmk = new Bookmark(0, false, "Current Playback Time", type, this.timeDomain!.start);
    playbackBmk.startTime = new Date(this.timeDomain!.start.getTime() + timestamp * 1000 + 1);
    this.playbackBookmarkObservable?.next([playbackBmk]);
  }

  /**
   * Draw or update the transcript playback canvas with new content.
   */
  redraw() {
    this.gridCanvasHelper.draw();
    this.transcriptionCanvasHelper.draw();
  }

  /**
   * The current **min** selection zone for the selection plugin
   */
  minSelection: Date | undefined = undefined;

  /**
   * The current **max** selection zone for the selection plugin
   */
  maxSelection: Date | undefined = undefined;

  private setDomainData(x: ZoomDomainContent) {
    if (x.domain == null) {
      this.maxSelection = undefined;
      this.minSelection = undefined;
      if (this.canvasesReady()) {
        this.transcriptionCanvasHelper.resetToBeginning();
        this.gridCanvasHelper.drawXAxisLabels();
      }
    } else {
      this.maxSelection = cloneDeep(x.domain[0]);
      this.minSelection = cloneDeep(x.domain[1]);
      this.transcriptionCanvasHelper.seekToDate(this.minSelection);
      this.gridCanvasHelper.drawXAxisLabels();
    }
  }

  private canvasesReady(): boolean {
    return (
      this.grid != null &&
      this.timeline != null &&
      this.timeDomain != null &&
      this.transcriptions != null &&
      this.playbackIndicator != null &&
      this.gridCanvasHelper != null &&
      this.transcriptionCanvasHelper != null &&
      this.playbackHelper != null
    );
  }
}
