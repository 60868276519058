import { Bookmark, Utility } from "@tdms/common";
import {
  ChartJSDrawingHelper,
  DEFAULT_LABEL_PADDING,
  DEFAULT_VERTICAL_LINE_WIDTH,
  LabelObject,
} from "./drawing.helper";
import { PluginBase, PluginBaseExternalOpts, PluginBaseInternalOpts } from "./plugin.base";

/**
 * Types supported by this component for drawing or moving bookmarks
 */
export type BookmarkDrawOptions =
  | "draw-new" // Draw a singular bookmark
  | "draw-new-range" // Draw entire range (start & stop)
  | "move-start-range" // Move only the start of a range
  | "move-end-range" // Move only the end of the range
  | "move-range" // Move the entire range
  | "move-singular"; // Move only a singular

/** This plugin allows rendering bookmarks onto the time series charts */
export namespace BookmarkPlugin {
  /** External supported options */
  export class ExternalOptions extends PluginBaseExternalOpts {
    /** The bookmarks to render */
    bookmarks: Bookmark[] = [];

    /** The current draw mode for the bookmarks, if applicable */
    drawMode?: BookmarkDrawOptions = undefined;

    /** The bookmark that we are currently drawing associated to @see {@link drawMode} */
    currentDrawingBookmark: Bookmark | undefined;

    /** If hover styling should be applied */
    applyHoverStyling: boolean = true;

    /** If horizontal bookmarks should be rendered or not */
    shouldRenderHorizontal: boolean = true;

    /** If the horizontal bookmarks labels should be rendered or not. */
    shouldRenderHorizontalLabels: boolean = this.shouldRenderHorizontal;

    /** A function to fire when a bookmark is clicked */
    onClick?: { (type: "clickBookmark", bookmark: Bookmark): void } & { (type: "clickSpace", xPos: number): void };

    /** Some styling configuration capabilities */
    styling?: Partial<{
      /**
       * The color to override all bookmarks with.
       * @default "Accent color in css"
       */
      color?: string | null;

      /**
       * The color to use for the range rectangle on hover
       * @default "80808066"
       */
      rangeHoverColor?: string | null | undefined;
    }>;

    /** A domain used to restrict the bookmarks rendered to between this domain */
    domain?: [Date, Date] | null | undefined;
  }

  /** Options for internal use of this plugin only */
  export class InternalOptions extends PluginBaseInternalOpts {
    currentHoveredBookmark?: Bookmark;
  }

  export class Plugin extends PluginBase<ExternalOptions, InternalOptions> {
    constructor(pluginCanvas: HTMLCanvasElement) {
      super(pluginCanvas, "bookmark", ExternalOptions, InternalOptions);
    }

    override shouldExecuteFunctionality(chart = this.chart) {
      return super.shouldExecuteFunctionality(chart) && this.chartIsTimeSeries;
    }

    /**
     * Given a bookmark, determines the color to utilize for that bookmark
     */
    private getColorForBookmark(bookmark: Bookmark) {
      const { styling } = this.externalOpts!;
      // Set color
      let colorToUse = styling?.color || bookmark.bookmarkType.color || ChartJSDrawingHelper.defaultColor;
      // Change opacity if needed
      if (
        this.internalOpts.currentHoveredBookmark != null &&
        !this.internalOpts.currentHoveredBookmark.equals(bookmark)
      )
        colorToUse = this.convertColorToOpacity(colorToUse, "reduced");
      return colorToUse;
    }

    /**
     * Given a color and opacity level, determines the color that should be utilized for the given color and will
     *  convert it automatically.
     *
     * **Note: This function assumes colors will be kept as hex values and converted to RGBA when applying opacity levels**
     */
    private convertColorToOpacity(givenColor: string | Function, opacity: "reduced" | "full") {
      if (typeof givenColor === "function") throw new Error("Cannot process colors as functions");
      const isRGBA = givenColor.startsWith("rgba");
      // Handle opacity levels
      if (opacity === "reduced")
        if (isRGBA)
          // We use RGBA values for opacity setting
          return givenColor;
        else {
          // Convert hex to RGBA and return it with reduced opacity
          const color = Utility.hexToRgb(givenColor);
          return `rgba(${color?.r},${color?.g},${color?.b},${0.25})`;
        }
      else {
        if (!isRGBA) return givenColor;
        else {
          // Convert back to normal hex
          const splitRGBA = givenColor
            .replace("rgba", "")
            .replace(/\(|\)/gm, "")
            .split(",")
            .map((x) => parseFloat(x));
          return Utility.rgbToHex(splitRGBA[0], splitRGBA[1], splitRGBA[2]);
        }
      }
    }

    /**
     * Given the opacity level, adjusts the chart opacity level based on the chart type for it's data sets
     * @param opacity
     *  - "reduced": Lowers the opacity of all data
     *  - "full": Sets the opacity of all data back to normal
     */
    private adjustChartDataOpacity(opacity: "reduced" | "full") {
      // We just change the brightness of the canvas
      this.chart?.canvas.style.setProperty("filter", opacity === "reduced" ? "brightness(0.6)" : "brightness(1)");
    }

    /**
     * Given a bookmark sets it as the currently hovered bookmark and adjusts some
     *  chart level styling to show we are hovering on a bookmark.
     */
    setCurrentlyHoveredBookmark(bookmark?: Bookmark) {
      const chart = this.chart!;
      if (this.internalOpts.currentHoveredBookmark !== bookmark) {
        this.internalOpts.currentHoveredBookmark = bookmark;
        // We have a bookmark within range, that is the current hovered one.
        if (bookmark) {
          // Set hover indicator
          chart.canvas.style.cursor = "pointer";
          // Change opacity of datasets only
          this.adjustChartDataOpacity("reduced");
        } else {
          chart.canvas.style.cursor = "default";
          this.adjustChartDataOpacity("full");
        }
      }
    }

    /** Shows the metadata within a tooltip for the given bookmark if available */
    showBookmarkMetadataTooltip(bookmark: Bookmark) {
      const labels: LabelObject[] = [];

      // Render transcriptions if given
      if (bookmark.metadata?.transcription) {
        bookmark.metadata.transcription.forEach((transcript) => {
          const speakerLabel = `${transcript.speaker}: `;
          const text = transcript.text;

          // If text is greater than 50 characters create new line
          const maxLength = 50;
          const regex = new RegExp(`\\b.{1,${maxLength}}\\b`, "g");
          const lines = text.match(regex) || [];

          lines.forEach((line, index) => {
            if (index === 0) {
              // First line with the speaker name
              labels.push(LabelObject.fromPlain({ text: `${speakerLabel}${line}`, color: "#FFFFFF" }));
            } else {
              // Next lines with indentation
              labels.push(LabelObject.fromPlain({ text: "  " + line, color: "#FFFFFF", trim: false }));
            }
          });
        });
      }
      // Draw any labels we found
      const yPos = this.chart!.chartArea.top + this.chart!.chartArea.height / 4;
      this.drawingHelper.drawLabelToPos(yPos, this.internalOpts.xPos!, labels);
    }

    /**
     * Draws the bookmarks set into the chart options
     */
    drawBookmarks() {
      const chart = this.chart!;
      const pluginOpts = this.externalOpts;
      const domain = pluginOpts?.domain;
      // Re order so horizontals are rendered before vertical so they appear behind
      const bookmarksInOrder = pluginOpts.bookmarks
        .filter((x) => x.bookmarkType.renderStyle === "horizontal")
        .concat(pluginOpts.bookmarks.filter((x) => x.bookmarkType.renderStyle === "vertical"));
      // Make sure the current bookmark can be rendered
      if (pluginOpts.currentDrawingBookmark) bookmarksInOrder.push(pluginOpts.currentDrawingBookmark);
      // Determine what bookmarks should be rendered
      const bookmarksToRender = domain
        ? // Handle domain if set
          bookmarksInOrder.filter(
            (x) =>
              x.bookmarkType.shouldDisplay &&
              // If we are horizontal, don't consider domain
              (x.bookmarkType.renderStyle === "horizontal" ||
                // If we are vertical, consider domain.
                (x.bookmarkType.renderStyle === "vertical" &&
                  ((x.startTime >= domain[0] && x.startTime <= domain[1]) ||
                    // Also handle range end
                    (x.endTime != null && x.endTime >= domain[0] && x.endTime <= domain[1]))))
          )
        : // Else handle standard
          bookmarksInOrder.filter((x) => x.bookmarkType.shouldDisplay);
      // Handle the actual rendering
      for (let bookmark of bookmarksToRender) {
        if (bookmark.bookmarkType.renderStyle === "vertical") {
          // Determine if we are "editing" this bookmark
          const isDrawingBookmark =
            pluginOpts.drawMode != null && pluginOpts.currentDrawingBookmark?.id === bookmark.id;
          // Determine positions
          const startTime = isDrawingBookmark
            ? pluginOpts.currentDrawingBookmark?.startTime?.getTime()
            : bookmark.startTime.getTime();
          let startXPos = !startTime ? undefined : chart.scales.x.getPixelForValue(startTime);
          const startInDomain = !domain ? true : bookmark.startTime >= domain[0] && bookmark.startTime <= domain[1];
          const endTime = isDrawingBookmark
            ? pluginOpts.currentDrawingBookmark?.endTime?.getTime()
            : bookmark.endTime?.getTime();
          let endXPos = !endTime ? undefined : chart.scales.x.getPixelForValue(endTime);
          const endInDomain =
            !domain || !bookmark.endTime ? true : bookmark.endTime >= domain[0] && bookmark.endTime <= domain[1];
          const isCurrentHoveredBookmark = this.internalOpts.currentHoveredBookmark?.equals(bookmark);
          // prettier-ignore
          const shouldDrawStart = isDrawingBookmark && (pluginOpts.drawMode === 'move-end-range' || pluginOpts.drawMode === 'draw-new-range' || pluginOpts.drawMode === "move-range")
          // prettier-ignore
          const shouldDrawEnd = isDrawingBookmark && (pluginOpts.drawMode === "move-start-range" || pluginOpts.drawMode === "move-range")
          // Handle start
          if (startXPos && startInDomain && (!isDrawingBookmark || shouldDrawStart))
            this.drawingHelper.drawVerticalLine(startXPos, {
              color: this.getColorForBookmark(bookmark),
              width: DEFAULT_VERTICAL_LINE_WIDTH,
            });
          // Handle end
          if (endXPos && endInDomain && (!isDrawingBookmark || shouldDrawEnd)) {
            this.drawingHelper.drawVerticalLine(endXPos, {
              color: this.getColorForBookmark(bookmark),
              width: DEFAULT_VERTICAL_LINE_WIDTH,
            });
          }
          // Handle range indicator
          if (isCurrentHoveredBookmark || shouldDrawStart || shouldDrawEnd) {
            if (shouldDrawStart) endXPos = this.internalOpts.xPos!;
            else if (shouldDrawEnd) startXPos = this.internalOpts.xPos!;
            if (endXPos && startXPos)
              this.drawingHelper.drawRect(startXPos, endXPos, {
                color: pluginOpts.styling?.rangeHoverColor,
              });
          }
        } else if (
          pluginOpts.shouldRenderHorizontal &&
          bookmark.bookmarkType.renderStyle === "horizontal" &&
          bookmark.value
        ) {
          const yValue = chart.scales.y.getPixelForValue(bookmark.value);
          this.drawingHelper.drawHorizontalLine(yValue, {
            color: this.getColorForBookmark(bookmark),
            start: bookmark.startTime,
            end: bookmark.endTime,
          });
        }
      }
    }

    /**
     * Draws any bookmark labels that should be drawn. We execute this last so they can appear on top
     */
    drawBookmarkLabels() {
      const chart = this.chart!;
      const pluginOpts = this.externalOpts;
      const shouldRenderBookmarks =
        pluginOpts?.shouldRenderHorizontal == null ? true : pluginOpts.shouldRenderHorizontal;
      const shouldRenderLabels =
        pluginOpts?.shouldRenderHorizontalLabels == null
          ? shouldRenderBookmarks
          : pluginOpts.shouldRenderHorizontalLabels;
      if (pluginOpts?.enabled && pluginOpts.bookmarks && shouldRenderBookmarks && shouldRenderLabels) {
        pluginOpts.bookmarks.map((bookmark) => {
          if (bookmark.bookmarkType.renderStyle === "horizontal" && bookmark.value) {
            const yValue = chart.scales.y.getPixelForValue(bookmark.value) - DEFAULT_LABEL_PADDING / 2;
            this.drawingHelper.drawLabelToPos(yValue, "right", [
              LabelObject.fromPlain({
                text: `${bookmark.bookmarkType.name}: ${bookmark.value}`,
                color: this.getColorForBookmark(bookmark),
              }),
            ]);
          }
        });
      }
    }

    override mouseMove(e: MouseEvent): void {
      super.mouseMove(e);
      const chart = this.chart!;
      if (chart.select?.isDragging && chart.select?.mouseIsDown) return; // Don't apply hover indicator while dragging a selection
      if (!this.externalOpts.applyHoverStyling) return; // Handle if we don't want hover styling applied
      if (this.internalOpts.xPos == null) return; // Data check

      // Determine the time position for the current mouse pos
      let closestTimeSeriesVal = chart.scales.x.getValueForPixel(this.internalOpts.xPos);
      // The time val of the bookmark must be within the range considering the bookmark line width
      const timePaddingFromLineWidth =
        closestTimeSeriesVal == null
          ? undefined
          : (chart.scales.x.getValueForPixel(this.internalOpts.xPos + DEFAULT_VERTICAL_LINE_WIDTH) || 0) -
            closestTimeSeriesVal;

      // Grab nearest element
      const closestBookmark =
        closestTimeSeriesVal == null || timePaddingFromLineWidth == null
          ? undefined
          : chart.config.options.plugins.bookmark?.bookmarks
              // Only allow vertical to be clicked and check that they are clickable
              ?.filter((x) => x.bookmarkType.renderStyle === "vertical" && x.bookmarkType.clickable)
              .find((x) => {
                const bookmarkTime = x.startTime.getTime();
                const bookmarkEndTime = x.endTime?.getTime();
                const startTimeInRange =
                  bookmarkTime - timePaddingFromLineWidth <= closestTimeSeriesVal! &&
                  bookmarkTime + timePaddingFromLineWidth >= closestTimeSeriesVal!;
                const endTimeInRange =
                  bookmarkEndTime == null
                    ? false
                    : bookmarkEndTime - timePaddingFromLineWidth <= closestTimeSeriesVal! &&
                      bookmarkEndTime + timePaddingFromLineWidth >= closestTimeSeriesVal!;
                return startTimeInRange || endTimeInRange;
              });
      this.setCurrentlyHoveredBookmark(closestBookmark);
    }

    override mouseDown(e: MouseEvent): void {
      super.mouseDown(e);
      if (this.externalOpts.onClick) {
        if (this.internalOpts.currentHoveredBookmark != null)
          this.externalOpts.onClick("clickBookmark", this.internalOpts.currentHoveredBookmark);
        else if (this.internalOpts.xPos) {
          const value = this.chart!.scales.x.getValueForPixel(this.internalOpts.xPos)!;
          this.externalOpts.onClick("clickSpace", value);
        }
        // Reset selected bookmark
        this.setCurrentlyHoveredBookmark(undefined);
      }
    }

    override mouseLeave(e: MouseEvent): void {
      super.mouseLeave(e);
      // Wipe currently hovered. Be careful on when to update on charts to prevent infinite loops
      this.setCurrentlyHoveredBookmark(undefined);
      this.render();
    }

    override render(): void {
      if (!this.shouldExecuteFunctionality()) return;
      this.drawBookmarks();
      this.drawBookmarkLabels();
      // Render metadata tooltip
      if (this.internalOpts.currentHoveredBookmark)
        this.showBookmarkMetadataTooltip(this.internalOpts.currentHoveredBookmark);
    }
  }
}
