import { Type } from "class-transformer";
import { TDMSBase } from "../../base";
import { Diarization } from "./diarization";
import { Transcription } from "./transcription";
import { VADSpeechRange } from "./vad";

/**
 * The expected response for audio files being analyzed
 */
export class AudioAnalyzeResponse extends TDMSBase {
  /** The actual transcription content, if originally requested */
  @Type(() => Transcription)
  transcription: Transcription[] | undefined;

  @Type(() => Diarization)
  /** The actual diarization content, if originally requested */
  diarization: Diarization[] | undefined;

  @Type(() => VADSpeechRange)
  vad: VADSpeechRange[] | undefined;

  /** If this request resulted in an error */
  isError: boolean = false;

  /** The error if this request had one */
  error: string | undefined;
}
