import { ChartDataset, ChartType, Plugin as ChartJSPlugin } from "chart.js";
import { PluginBase, PluginBaseExternalOpts, PluginBaseInternalOpts } from "./plugin.base";

/** A type to help improve needle support for the charting data set inputs */
export type NeedleChartDataSet<Type extends ChartType> = ChartDataset<Type, number[]> & {
  /** The value of where to place the needle */
  needleValue: number;
};

/** This plugin allows us to apply a needle to gauge charts */
export namespace NeedlePlugin {
  /** External supported options */
  export class ExternalOptions extends PluginBaseExternalOpts {
    /**
     * If the percentage number should be rendered on the needle.
     * @default true
     */
    renderLabel: boolean = true;
  }

  /** Options for internal use of this plugin only */
  export class InternalOptions extends PluginBaseInternalOpts {}

  export class Plugin extends PluginBase<ExternalOptions, InternalOptions> implements ChartJSPlugin<any> {
    constructor(pluginCanvas: HTMLCanvasElement) {
      super(pluginCanvas, "needle", ExternalOptions, InternalOptions);
    }

    /** Given the chart, draws the needle on the chart */
    drawNeedle() {
      const chart = this.chart!;
      if (!chart || !chart.chartArea) return;
      // Should be a number percentage
      let needleValue = (chart.config.data.datasets[0] as NeedleChartDataSet<any>).needleValue as number;
      // Don't draw if we don't have a value yet
      if (needleValue == null) return;
      const dataTotal = (chart.config.data.datasets[0].data as number[]).reduce((a, b) => a + b, 0);
      const angle = Math.PI + (1 / dataTotal) * needleValue * Math.PI;
      const ctx = chart.ctx;
      // Config
      // The minimum dimension so we can keep the needle consistent. Note: This doesn't function well with super skinny windows because the gauge chart does not have a consistent aspect ratio.
      const minDimension = Math.min(chart.chartArea.height, chart.chartArea.width);
      const circleDiameter = minDimension / 8;
      const x = chart.getDatasetMeta(0).data[0].x;
      const y = chart.getDatasetMeta(0).data[0].y;
      const arrowHeight = minDimension * 0.9;
      const arrowBaseSize = circleDiameter / 2;

      ctx.fillStyle = "black";
      // Rotate to the angle required for the arrow point
      ctx.translate(x, y);
      ctx.rotate(angle);
      // Draw the arrow
      ctx.beginPath();
      ctx.moveTo(0, -arrowBaseSize); // Start of point off circle
      ctx.lineTo(arrowHeight, 0); // Height of how far to draw the line
      ctx.lineTo(0, arrowBaseSize); // End of point off circle
      ctx.fill();
      // Reset rotation so circle can be drawn like normal
      ctx.rotate(-angle);
      ctx.translate(-x, -y);

      // Draw the circle
      ctx.beginPath();
      ctx.arc(x, y, circleDiameter, 0, Math.PI * 2, false);
      ctx.fill();

      // Write the percentage text
      if (this.externalOpts.renderLabel) {
        // Styling
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillStyle = "white";
        // Text sizing
        const size = Math.round(minDimension / 12);
        ctx.font = `${size}px Roboto, "Helvetica Neue", monospace`;
        // Text
        const text = `${needleValue}`;
        ctx.fillText(text, x, y);
      }
    }

    override render(): void {
      if (this.shouldExecuteFunctionality()) this.drawNeedle();
    }
  }
}
