import { CustomTypes } from "../../custom.types";

/** This module contains typing information for data store files as they are processed to better determine support. */
export module DataStoreFileTypes {
  /** Audio file types */
  export const Audio = [".wav", ".mp3", ".WAV"] as const;
  /** Video file types */
  export const Video = [".mp4", ".mkv", ".avi"] as const;
  /** Comma separated value types for things in like excel */
  export const CSV = [".csv", ".xlsx"] as const;
  /** Types of json files */
  export const JSON = [".json"] as const;
  /** A fallback file type that just excepts everything */
  export const Generic = ["*"] as const;

  /**
   * A combination of all the supported types so you can better specify what values you care about.
   */
  export type SupportedFileUploadTypes =
    | CustomTypes.Writeable<typeof CSV>
    | CustomTypes.Writeable<typeof Audio>
    | CustomTypes.Writeable<typeof Video>
    | CustomTypes.Writeable<typeof JSON>
    | CustomTypes.Writeable<typeof Generic> extends (infer U)[]
    ? U
    : never;

  /** This type specifies the options to a string type to better and more easily call back to */
  type DataStoreFileTypesKeys = keyof CustomTypes.OmitPropertyType<typeof DataStoreFileTypes, Function>;

  /** Given a supported type set, returns the string array of the supported formats  */
  export function typeToStringArray(type: DataStoreFileTypesKeys) {
    return DataStoreFileTypes[type] as unknown as SupportedFileUploadTypes[];
  }
}
