import { Plugin as ChartJSPlugin } from "chart.js";
import { PluginBase } from "./plugin.base";

/**
 * This plugin allows us to control some rendering capabilities for all plugins in one location.
 *
 * Currently it's just used so only one plugin wipes the plugin canvas instead of each plugin wiping it on it's own
 *  causing issues. It's vital that you load this plugin before any other plugin.
 */
export namespace PluginController {
  /** External supported options */
  export class ExternalOptions {}

  /** Options for internal use of this plugin only */
  export class InternalOptions {}

  export class Plugin extends PluginBase<ExternalOptions, InternalOptions> implements ChartJSPlugin<any> {
    constructor(pluginCanvas: HTMLCanvasElement) {
      super(pluginCanvas, "controller", ExternalOptions, InternalOptions);
    }

    /** Clears the plugin canvas from all drawn elements */
    private clearPluginCanvas() {
      this.pluginCanvasContext.clearRect(0, 0, this.pluginCanvas.width, this.pluginCanvas.height);
    }

    override mouseMove(e: MouseEvent): void {
      super.mouseMove(e);
      this.clearPluginCanvas();
    }

    override mouseLeave(e: MouseEvent): void {
      super.mouseLeave(e);
      this.clearPluginCanvas();
    }

    render(): void {
      this.clearPluginCanvas();
    }
  }
}
