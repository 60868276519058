<data-store-uploader-progress
  [status]="status"
  [shouldDisplayStatus]="shouldDisplayStatus"
  [filesUploading]="filesUploading"
  class="session-creation-progress"
>
  <div class="session-creation-container">
    <div class="session-creation-inner-container">
      <!-- Session file uploader -->
      <h1 class="header">Select a file to create a session from</h1>
      <div class="session-input-row">
        <!-- Session uploader -->
        <data-store-file-uploader
          #sessionCreationUploader
          [displayType]="'session-creation-only'"
          (fileUpdate)="updateExtraFiles('sessionCreationFiles', $event)"
          [shouldDisplaySessionCreationActor]="false"
          class="session-uploader"
        >
          <!-- Session name/prefix -->
          <mat-form-field color="accent" appearance="outline" class="session-name-form-field">
            <mat-label>Session Name/Prefix...</mat-label>
            <input
              matInput
              type="text"
              required
              [(ngModel)]="sessionNameOrPrefix"
              autocomplete="off"
              (keyup.enter)="submitCallback()"
            />
          </mat-form-field>
        </data-store-file-uploader>
      </div>
      <!-- Display the option to add additional files to the session, if we don't have the chance of multiple -->
      <div class="extra-files">
        <h1
          class="header extra-files-limitation"
          *ngIf="sessionCreationUploader.selectedUploadType?.canCreateMultipleSessions; else additionalFilesContainer"
        >
          This Data Source could produce more than one session so additional files must be uploaded individually
        </h1>

        <!-- Container to allow extra files to be uploaded -->
        <ng-template #additionalFilesContainer>
          <data-store-extra-files
            [files]="displayExtraFiles"
            (filesUpdated)="updateExtraFiles('currentExtraFiles', $event)"
            header="Upload additional files to this new session"
            [shouldDisplaySessionCreationActor]="false"
          ></data-store-extra-files>
        </ng-template>
      </div>
    </div>

    <!-- Submit button for session creation -->
    <div class="submit-container">
      <span [matTooltip]="validationMessage || ''">
        <button mat-flat-button color="accent" (click)="submitCallback()" [disabled]="!submitButtonAvailable">
          Create Session
        </button>
      </span>
    </div>
  </div>
</data-store-uploader-progress>
