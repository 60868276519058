import {
  Component,
  ContentChild,
  ContentChildren,
  Directive,
  EventEmitter,
  Input,
  Output,
  QueryList,
  TemplateRef,
} from "@angular/core";
import { TableBaseComponent } from "@tdms/frontend/modules/shared/components/tables/table-base/table-base.component";
import { ConfigDirectiveProperties } from "@tdms/frontend/modules/shared/directive/config.directive";

/**
 * This allows the editable table component to query for all cell-editor elements specified under it's usage,
 * which it iterates through to display all the templates as specified.
 */
@Directive({ selector: "cell-editor[columns]" })
export class CellEditor {
  @Input() columns!: Array<string>;
  @ContentChild("editor") editorTemplateRef!: TemplateRef<any> | null;
}

/**
 * A custom [GenericTableComponent] that makes the table editable following user-defined rules.
 * Users must specify how individual columns are to be edited in-line using the <cell-editor> directive.
 * <cell-display> directives are also supported by consumers to override standard display rules.
 *
 * If a column does not have a matching <cell-editor> it is assumed to not be editable.
 * Any <cell-display> will be used or otherwise fallback to the default generic table cell display.
 * The main motivation for this is to not have a bunch of pass through inputs for event listeners and click callbacks.
 * Instead, consumer classes can make use of the @cell-text-editor component to use some generalized text editing capabilities.
 *
 * The editable table adds it's own button row template with edit, save, cancel buttons.
 * As such, you must use the #extraHeaderButtons template to add additional buttons after edit buttons.
 *
 * Simple example:
 * <shared-editable-table ...>
 *   <cell-editor [columns]="['col1']">
 *     <ng-template #editor let-value="value" let-element="element" let-column="column">
 *       ...
 *     </ng-template>
 *   </cell-editor>
 *
 *  <!-- CLC Checkmark -->
 *   <cell-display [columns]="['col2', 'col3', ...]">
 *     <ng-template #display let-value="value" let-element="element" let-column="column">
 *       ...
 *     </ng-template>
 *   </cell-display>
 *
 *   <cell-editor [columns]="['col4']">
 *     <ng-template #display let-value="value" let-element="element" let-column="column">
 *       ...
 *     </ng-template>
 *   </cell-editor>
 *
 *   <!-- Custom end buttons -->
 *   <ng-template #extraHeaderButtons>
 * </shared-editable-table>
 */
@Component({
  selector: "shared-editable-table[onChangesSaved][onChangesCancelled][data]",
  templateUrl: "./editable-table.component.html",
  styleUrls: ["./editable-table.component.scss"],
})
export class EditableTableComponent<T extends Object & { isProcessing?: boolean }> extends TableBaseComponent<T> {
  /**
   * Whether or not the user is currently editing the table.
   */
  isEditing = false;

  /**
   * A backend configuration property to determine whether or not editing is enabled.
   */
  @Input() editConfigEnable: ConfigDirectiveProperties | ConfigDirectiveProperties[] | undefined;
  /**
   * Message to display if editing is disabled via configs.
   */
  @Input() configDisabledMessage: string = "Editing is disabled";

  /**
   * The tooltip to display on the edit button.
   */
  @Input() editTooltip: string | undefined;

  /**
   * The tooltip to display on the save edits button.
   */
  @Input() saveTooltip: string | undefined;

  /**
   * The tooltip to display on the cancel edits button.
   */
  @Input() cancelTooltip: string | undefined;

  /**
   * Flag whether or not data on the table has changed.
   * Controls save button status along with @editsValid.
   */
  @Input() hasChanged = false;

  /**
   * Whether or not any edits are invalid.
   * Controls save button status along with @hasChanged.
   */
  @Input() editsValid = false;

  /**
   * Called when user edits are saved.
   */
  @Output() onChangesSaved: EventEmitter<void> = new EventEmitter();

  /**
   * Called when user edits are cancelled.
   */
  @Output() onChangesCancelled: EventEmitter<void> = new EventEmitter();

  /**
   * Called when user editing starts.
   */
  @Output() onEditStarted: EventEmitter<void> = new EventEmitter();

  /**
   * Template consumers can specify to add extra buttons to the table header (after editing buttons).
   */
  @ContentChild("extraHeaderButtons") extraHeaderButtons: TemplateRef<any> | undefined;

  /**
   * Signify a cell editor override for any available columns.
   */
  @ContentChildren(CellEditor) editorOverrideRefs!: QueryList<CellEditor>;

  /**
   * Called when user editing starts, update our state variables and call component event signal.
   */
  onEditingStarted() {
    this.isEditing = true;
    this.onEditStarted.emit();
  }

  onSaveClicked() {
    this.isEditing = false;
    this.onChangesSaved.emit();
  }

  onCancelClicked() {
    this.isEditing = false;
    this.onChangesCancelled.emit();
  }
}
